@import '../../../../styles/colors.scss';

.compounds {
  padding: 20px;
  text-align: left;

  .checkbox {
    padding: 0 10px;
  }

  .header {
    display: flex;
  }

  .section-compounds {
    display: flex;
    flex-wrap: wrap;

    .compounds-dropdowns {
      display: flex;
      flex-direction: column !important;
      margin-right: 1.5em;
      margin-bottom: 15px;
      position: relative;

      span {
        color: #636363;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
      }

      .field {
        height: 100%;
      }

      .flipt-label {
        width: 100%;
        min-width: 3.5em;
      }

      .left {
        background-color: transparent;
      }
    }

    .compounds-dropdowns.input {
      span {
        font-size: 0.7em;
      }
    }

    .fields-label {
      font-weight: bold;
      color: $grey-800;
    }
  }

  .filterOptions {
    background-color: #F0F0F0;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0px 0px 15px 0px !important;
    text-align: left;

    >* {
      margin-right: 30px;
    }

    div.stylized {
      display: block;
    }

    div.ui.dropdown {
      border: 1px solid $grey-600;
    }

    input {
      border: 1px solid $grey-600;
      border-radius: .28571429rem;
      width: 90%;
    }

    #CAGFilters {
      margin-bottom: 0;
    }

    .flipt-label {
      width: 100%;
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 5px;
      padding-left: 0px;
    }

    input.primary.searchButton {
      width: 40%;
      margin-left: 40px;
      margin-bottom: 10px;
    }
  }

  .grid-container {
    height: 100% !important;
  }
}

@import '../../../../styles/colors.scss';

.default_copay {
  margin-bottom: 15px;


  .section-programs {
    overflow: auto;
    margin-top: 15px;
    padding: 0px 1px 10px 1px;

    .section-programs-accordion {
      width: 100%;

      .section-program-level {
        .content {
          max-width: 100% !important;
          padding: .5em 0em 0em;
        }

        .section {
          .copay-dropdowns-container {
            margin-bottom: 20px;

            display: flex;
            flex-wrap: wrap;
            padding: 15px;
            border: 1px solid #c0c3c8;
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

            >.copay-dropdowns {
              display: flex;
              flex-direction: column;
              margin-right: 1.5em;
              flex-grow: 1;
              margin-bottom: 15px;

              span {
                color: #636363;
                font-size: 0.9em;
                font-weight: bold;
                margin-bottom: 5px;
              }

              input {
                border: 1px solid rgba(34, 36, 38, 0.15);
                border-radius: 0.28571429rem;
              }

              .field {
                height: 100%;
              }

              .flipt-label {
                width: 100%;
              }
            }
          }

          .grid-container {
            >.addRowButtonContainer {
              margin-top: 15px;
              max-width: 90%;
              display: flex;
              justify-content: center;

              button {
                margin-left: 0;
              }

              >* {
                margin-left: 50%;
              }
            }
          }
        }

      }
    }
  }
}