@import '../../../../../styles/colors.scss';

#claimTransactionOverview {
  padding: 20px;
  text-align: left;

  > .header {
    align-items: baseline;
    display: flex;

    > h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }

    > div {
      display: inline-block;
      padding: 0;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  > .content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;

    .section {
      h2 { 
        background-color: $grey-800;
        color: $white;
        display: inline-block;
        font-size: 16px;
        padding: 5px 20px;
      }

      border-bottom: 2px solid $grey-600;
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .fields {
        width: 33%;

        > div {
          background-color: $grey-400;
          margin-bottom: 10px; 
          padding-left: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }
  }
}