@import '../../../../styles/colors.scss';

#listing_hierachy {
    .content {

        .fliptSeparator {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .umType {
            font-weight: 700;
            margin-bottom: 20px;
        }
        .hierarchyLevel {
            width: 50%;
            margin-bottom: 40px;
            margin-left: 40px;

        }
    }
}