/* Color Name? https://chir.ag/projects/name-that-color */
$black: #000000;
$doveGray: #636363;
$errorWhite: #FFFCEF;
$fliptRed-200: #FC2E2D;
$fliptRed-400: #FB0505;
$fliptRed: #FB0505;
$frenchGray: #C0C3C8;
$gallery: #F0F0F0;
$grey-100: #F9F9F9;
$grey-200: #ECECED;
$grey-300: #DADADB;
$grey-400: #D4D4D5;
$grey-500: #C1C1C3;
$grey-600: #9d9da1;
$grey-800: #666A67;
$silverChalice: #A8A8A8;
$white: #FFFFFF;
$whiteSmoke: #f2f2f2;
$trueBlue: #0000ff;
$smokeBlue: #3662A2;
$blue :#1D4ED8;

/* colors used in new UI components. white labeling */
$v2-black: #000000;
$v2-gray-100: #F2F4F7;
$v2-gray-200: #EAECF0;
$v2-gray-25: #FCFCFD;
$v2-gray-50: hsl(210, 20%, 98%); //#f9fafb
$v2-gray-300: #D0D5DD;
$v2-gray-400: #98A2B3;
$v2-gray-500: #667085;
$v2-gray-600: #475467;
$v2-gray-700: #344054;
$v2-gray-800: #1D2939;
$v2-gray-900: #101828;
$v2-white: #FFFFFF;

$v2-sm-box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);


$v2-primary-font-family: "Inter", "Nunito", "Avenir-Medium";
