@import "../../../../../styles/colors.scss";

#claimTransactionIngredients {
  padding: 20px;
  text-align: left;

  > .header {
    display: flex;
    align-items: baseline;

    > h1 {
      padding-right: 20px;
      border-right: 1px solid $grey-600;
      margin-right: 20px;
    }

    > div {
      display: inline-block;
      padding: 0;

      a {
        display: inline;
        font-size: 13px;
        border-right: 1px solid $fliptRed;
        padding-right: 10px;
        margin-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  > .content {
    margin: 10px;
    overflow: hidden;

    .section {
      h2 {
        color: $white;
        font-size: 16px;
        padding: 5px 20px;
        background-color: $grey-800;
        display: inline-block;
      }

      border-bottom: 2px solid $grey-600;
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .fields-container {
      // outline: 1px solid black;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;

      .fields {
        width: 33%;

        > div {
          background-color: $grey-400;
          margin-bottom: 10px;
          padding-left: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }
    .gridcontent{
      height: 300px;
    }
    .dur-grid {
      margin-top: 20px;
      height: 350px;
    }
  }
}

