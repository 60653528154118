@import '../../../../styles/colors.scss';

#Notes {
  height: 100%;
  width: 100%;
  text-align: left;

  .content {
    height: 300px;
    width: 100%;
    padding: 12px 12px 0px 12px;

    .pa-notes-grid {
      height: 250px;
    }
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    align-items: baseline;
    caret-color: transparent;
  }

  .header-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
  }

  .pa-button-container {
    display: flex;
    padding: 5px;
    align-items: center;
    margin-top: 16px;

    .primary {
      margin-left: 20px;
    }

    .header {
      font-weight: bold;
      font-size: 18px;
      line-height: 16px;
      color: #000000;
      margin-left: 20px;
    }
  }

  .note-container {
    display: flex;
    align-items: start;
    margin-top: 10px;

    .primary {
      margin-left: 20px;
    }

    .header {
      font-weight: bold;
      font-size: 18px;
      line-height: 16px;
      color: #000000;
      margin-left: 20px;
    }

    .flipt-textarea {
      width: 100%;

      textarea {
        width: 100%;
        min-height: 100px;
        resize: none;
      }
    }
  }


  .notes-container {
    display: flex;
    flex-direction: row;

  }

  .notes-icon-container {
    background-color: $grey-200;
    border-radius: 50px;
    padding: 8px;
    height: 40px;
    width: 40px;
    display: grid;
  }

  .notes-devider {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 1px;
    width: 100%;
    background-color: $grey-500;
  }

  .notes-devider-container {
    margin-left: 20px;
    width: 100%;
  }

  .header-container {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .text-container {
    text-align: left;
    font-size: small;
  }

  .date-time-container {
    text-align: left;
    font-size: x-small;
    color: $grey-800;
    margin-bottom: 5px;
  }

}