@import "../../../../../styles/colors.scss";
#newRestrictions {
  padding: 20px;
  text-align: left;
  > .content {
    overflow: auto;
    padding-right: 10px;
    height: 450px;
    max-width: 100%;
    max-height: 550px;
    .actions {
      width: 160px;
      > * {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
      input {
        margin: 15px;
      }
  }
}
