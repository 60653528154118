@import '../../../styles/colors.scss';

#pa-dashboard {
    // display: flex;
    // flex-direction: column;

    padding: 20px;
    text-align: left;

    .header-container {
        display: flex;
        width: 100%;
        align-items: center;

        .header {
            font-family: $v2-primary-font-family;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #101828;
        }

        .pa-buttons-container {
            display: flex;
            margin-left: auto;
            justify-content: center;

            button {
                margin-right: 8px;
            }

            >:last-child {
                margin-right: 0px;
            }
        }
    }

    .tabs-reports-container {
        display: flex;
        height: 64px;
        align-items: center;

        // .tab-bar {
        //     .tab-button {
        //         background: #FFFFFF;
        //         font-family: $v2-primary-font-family;
        //         font-weight: 500;
        //         font-size: 14px;
        //         color: #344054;
        //         border-radius: 0px;
        //         border: 1px solid #D0D5DD;
        //         line-height: 20px;
        //         padding: 10px 16px;
        //     }

        //     :first-child {
        //         border-radius: 0px 1px;
        //     }
        // }

        .reports {
            margin-left: auto;
        }

        .reports-selected {
            background-color: $v2-gray-100;
            color: $v2-gray-800;
        }
    }

}
