@import "../../../../styles/colors.scss";

#PODetails {
  padding: 20px;
  text-align: left;
  // background-color: whitesmoke;

  .header {
    display: flex;
  }

  > .content {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;

    h2 {
      color: $white;
      font-size: 18px;
      padding: 10px 20px;
      background-color: $grey-800;
      display: inline-block;
    }

    .section {
      padding-bottom: 10px;
    }
    .section-contents {
      margin-top: 20px;
      border-bottom: 1px solid $grey-600;
      &:last-of-type {
        border-bottom: none;
      }
    }

    .date-container{
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 20px;

      > div {
        width: 35%;
        margin-right: 50px;
      }
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 20px;


      .margin-right{
        margin-right: 15px;
      }

      > div {
        min-width: 28%;
      }

      .fields {
        width: 33%;

        > div {
          background-color: $grey-400;
          margin-bottom: 10px;
          padding-left: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      margin: 20px;

      .fields {
        margin-right: 40px;
      }
    }
    .overlap-index {
      z-index: 1;
    }
  }
}
