@import '../../../styles/colors.scss';

#memberLookup {
  padding: 20px;
  text-align: left;

  .NotificationBar {
    position: sticky;
    top: 0;
    background-color: #2a52be;
    z-index: 101;
  }

  >.content {
    overflow: auto;
    padding-right: 10px;

    .button-container {
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .primary {
        margin-right: 10px;
        width: 150px;
      }

      .secondary {
        width: 150px;
      }
    }

    .section {
      margin-top: 20px;
      border-bottom: 2px solid $grey-600;

      &:last-of-type {
        border-bottom: none;
      }

      .section-contents {
        margin-bottom: 15px;
        margin-top: 15px;
        max-width: 80%;
        padding: 0px 10px 10px 10px;

        // &:nth-child(2) {
        //   border-bottom: 1px solid $grey-600;
        // }

        div.stylized {
          background-color: $grey-200;
          margin-right: 10px;
          min-width: 30%;

          div[name='locations'] {
            z-index: 11;
          }

          &.address1 {
            width: 40%;

            >div {
              width: 'auto';
            }

            >input {
              width: calc(100% - 100px);
            }
          }
        }
      }

      div[name='domain_name'] {
        z-index: 100;

        div.menu {
          max-height: 175px;
          overflow-y: scroll;
        }
      }

      .section-header {
        align-items: baseline;
        display: flex;
        cursor: pointer;

        h2 {
          background-color: $grey-800;
          color: $white;
          display: inline-block;
          font-size: 18px;
          padding: 10px 20px;
        }

        .vip-header {
          color: $fliptRed;
          font-weight: bolder;
          display: flex;
          align-self: flex-start;
          font-size: larger;
        }

        input[type='button'] {
          padding-left: 30px;
          padding-right: 30px;
        }

        >div,
        h2 {
          >* {
            margin-right: 10px;

          }
        }
      }
    }
    .search-container  {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10px;
      .column {
        justify-content: center;
      }
      .horizontal-fields {
        width: 100%;
        display: flex;

        .emails,
        .phone {
          display: flex;
          align-items: center;
        }

        .dropdown-width {
          min-width: fit-content;
        }

        >div {
          min-width: 36%;

          >label {
            min-width: 30%;
          }

          >div {
            width: 40%;
          }

          >input {
            flex: 1;
          }

        }

      }

      .fields-header {
        display: flex;

        >* {
          margin-right: 20px;
        }

      }

      div.phone[role='listbox'] {
        width: 200px;
      }

      .fields {
        width: 33%;

        &.field-row {
          display: flex;
          width: 100%;
          margin-right: 20px;

          >* {
            margin-right: 10px;
          }
        }

        &.no-bg {
          >* {
            background-color: $white  !important;
          }
        }

        >.no-bg {
          background-color: $white  !important;
        }

        >div {
          background-color: $grey-400;
          margin-bottom: 10px;
          padding-left: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;

          }
        }
      }
    }
    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .horizontal-fields {
        width: 100%;
        display: flex;

        .emails,
        .phone {
          display: flex;
          align-items: center;
        }

        .dropdown-width {
          min-width: fit-content;
        }

        >div {
          min-width: 36%;

          >label {
            min-width: 30%;
          }

          >div {
            width: 40%;
            min-width: 36%;
          }

          >input {
            flex: 1;
          }

        }

      }

      .fields-header {
        display: flex;

        >* {
          margin-right: 20px;
        }

      }

      div.phone[role='listbox'] {
        width: 200px;
      }

      .fields {
        width: 33%;

        &.field-row {
          display: flex;
          width: 100%;
          margin-right: 20px;

          >* {
            margin-right: 10px;
          }
        }

        &.no-bg {
          >* {
            background-color: $white  !important;
          }
        }

        >.no-bg {
          background-color: $white  !important;
        }

        >div {
          background-color: $grey-400;
          margin-bottom: 10px;
          padding-left: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;

          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;

      >* {
        margin-right: 20px;
      }
    }
  }
}