@import '../../../../styles/colors.scss';

#PaReport {
    height: 100%;
    width: 100%;
    text-align: left;

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        align-items: baseline;
        caret-color: transparent;
    }

    .header-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
    }

    .grid {
        height: 300px;
        margin-left: 10px;
        margin-right: 10px;
    }

}