@import '../../../../styles/colors.scss';

#defineBatchScreen {
  padding: 20px;
  text-align: left;

  >.mainSections {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .section {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .section-inputs {
        display: flex;
        flex-direction: row;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
      }

      h2 {
        color: black;
        display: inline-block;
        font-size: 20px;
        margin-top: 10px;
        font-weight: bold;
      }

      .um-objects-button-container {
        display: flex;
        justify-content: center;
      }

    }
  }


}
