@import "../../../styles/colors.scss";

.flipt-wizard-container {
  font-size: 15px;
  font-weight: 600;
  .fliptwizard {
    counter-reset: step;
    display: flex;
  }
  .fliptwizard li {
    list-style: none;
    display: inline-block;
    position: relative;
    text-align: center;
    cursor: pointer;
    width: 100%;
  }
  .fliptwizard li:before {
    content: '•';
    font-size: 15px;
    width: 25px;
    height: 25px;
    line-height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff;
    border-width: medium;
    z-index: 1;
    position: relative;
  }

  .fliptwizard  li.completed:before {
    content: '\2713'
  }
  .fliptwizard li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 12px;
    left: -50%;
    z-index: 0;
  }
  .fliptwizard li:first-child:after {
    content: none;
  }
  .fliptwizard li.active {
    //color: green;
  }
  .fliptwizard li.active:after {
    color: green;
  }
  .fliptwizard li.active:before {
    color: green;
    background-color: #ECFDF3;

  }

}
  