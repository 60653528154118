.Devider{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    p{
        margin-left: 8px;
        margin-right: 8px;
        font-size: 10px;
    }
    .Line{
    margin-top: 4.5px;
    height: 1px;
    width:49%;
    background-color: grey;
    }
   
}