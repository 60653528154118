@import '../../styles/colors.scss';

.ui.table {
  margin-top:20px !important;
  border:0px !important;

  thead {
    > tr {
      > th {
        font-size: .90em;
        font-family: 'Nunito';
        font-weight: 700;
        text-transform: capitalize;
        line-height:1.25em;
        border-left:0px !important;
        border-top:0px !important;
        border-bottom: 1px solid rgba(34,36,38,.15);
        border-collapse: collapse;
        padding-bottom:5px;
        
      }   
      
      > td {
        border-left:0px !important;
        border-top:0px !important;
        border-bottom: 1px solid rgba(34,36,38,.15);
        border-collapse: collapse;
        

      
      }
    }
  }

  tbody {
    > tr {
      > td {
        font-size: .85em;
        border-left:0px !important;
        border-top:0px !important;
        border-bottom: 1px solid rgba(34,36,38,.15);
        border-collapse: collapse;
      }
    }
  }
}

.loading-container {
  border: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: 300px;
  overflow: hidden;
  padding: 0;
  text-align: center;

  > .loading-icon {
    animation: rotation 1s infinite linear;
    width: 50px;
  }
}

.clickable {
  cursor: pointer;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}