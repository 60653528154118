@import '../../styles/colors.scss';

#qualifiers {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 100%;
  font-family: $v2-primary-font-family;
  font-style: normal;
  margin: 1% 0;
  overflow-x: auto;

  .description {
    &-section {
      display: flex;
      align-items: center;
      margin: 0 0 1% 0;
    }

    &-label {
      font-weight: bold;
      font-size: 14px;
      line-height: 32px;
      padding: 8px 16px;
    }

    &-input {
      max-width: 25vw;
    }
  }

  .qualifiers-table {
    border: 1px solid #EAECF0;
    border-radius: 8px;
    border-collapse: unset;
    box-shadow: $v2-sm-box-shadow;
    width: 100%;

    th {
      font-weight: 400;
    }

    td {
      padding: 12px;
    }

    tr {
      border: 1px solid #ccc;
      padding: 4px;
    }


  }

  .ui.table.qualifiers-parent-table {
    // overriding an inherited style set to important :(
    margin-top: 0px !important;
  }

  .ui.table.qualifiers-parent-table.no-bottom-radius {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .criteria-toolbar {
    text-align: right;
  }

  .criteria-toolbar span {
    color: #0000FF;
    cursor: pointer;

  }

  .criteria-value {
    &-dropdown {
      border: #d2d2d2 1px solid;
      border-radius: 6px;
      vertical-align: inherit;
    }

    &-section {
      display: flex;
      justify-content: space-evenly;
      box-sizing: border-box;
      align-items: center;
      width: 100%;
    }

    &-search-dropdown {
      min-width: 262px;
      width: 100%;
    }

    &-search-button {
      background-color: #FF0000;
      height: 40px;
      padding: 0px;
      display: inline-block;
      margin: 5px 0px 0px 5px;
      color: white;
      transition: all .3s ease-in-out;
      width: 90px;

      &:hover {
        animation: animate-color 1s forwards;
      }
    }

    &-search-icon {
      color: inherit;
    }

    &-input {
      border: #d2d2d2 1px solid;
      border-radius: 6px;
      min-width: 262px;
      min-height: 34px;
      padding: 12px 8px;
      text-align: right;
      width: 100%;
    }
  }

  .criteria-value-dropdown .ui.dropdown>.text {
    padding-right: 8px;
  }

  .criteria-footer {
    &-section {
      margin: 10px 0;
      display: flex;
      justify-content: center;
    }

    &-button {
      background-color: #FF0000;
      color: white;
      transition: all .3s ease-in-out;

      &:hover {
        animation: animate-color 1s forwards;
      }
    }

    &-icon {
      color: inherit;
    }
  }

  .criteria-table-data-emtpy {
    text-align: center;
    font-size: 14px;
    color: #999;
    padding: 20px;
  }

  .parent-qualifier {
    &-container {
      margin-bottom: 10%;
      padding: 2%;
    }

    &-border {
      border: .5px solid #d2d2d2;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

  }

  .parent-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
    padding: 10px 5px 10px 0px;
  }

  .child-one {
    // reject PR
    // name to be changed
    grid-column: 2 / 3;
  }

  .child-two {
    // reject PR
    // name to be changed
    grid-column: 3 / -1;
  }

  .parent-controls-container {
    display: flex;
    justify-content: space-between;
  }

  .parent-operator-container {
    width: 100px;
  }

  .parent-operator-dropdown {
    // setting exact width bugs out the dropdown
    min-width: 100px;
    max-width: 100px;
    margin-bottom: 1%;
  }

  input[type="number"].parent-input::-webkit-inner-spin-button,
  input[type="number"].parent-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"].criteria-value-input::-webkit-inner-spin-button,
  input[type="number"].criteria-value-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"].parent-input {
    -moz-appearance: textfield;
  }

  .parent-input {
    border: #d2d2d2 1px solid;
    border-radius: 6px;
    min-width: 162px;
    max-width: 200px;
    min-height: 34px;
    padding: 12px 8px;
    text-align: right;
    width: auto;
  }

}

@keyframes animate-color {
  0% {
    background-color: #FF0000;
  }

  100% {
    background-color: #600000;
  }
}

.qualifier_value {
  display: inline-block;
  min-width: 150px;
  vertical-align: middle;
  width: 100%;
}

// .criteria-wrap {
//   //margin:8% 20% 0 20%;

// }

// .criteria-wrap .criteria-wrap table 

// .criteria-wrap table 

// .criteria-wrap table 

.operator select {
  min-width: 100px;
  height: 40px;
  text-align: center;
}


.criteria_check1 {
  display: grid;
  place-content: center;
  width: 0.85em;
  height: 0.85em;
  background-color: #fff;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

}


.criteria_check1::before {
  width: 1.6em;
  height: 1.5em;
  transform: scale(0);
  content: "";
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em green;
}

.criteria_check1:checked::before {
  transform: scale(1);
}

.qualifier select {
  min-width: 200px;
  height: 40px;
}

.criteria-value.lookup {
  border: #d2d2d2 1px solid;
  border-radius: 6px;
  height: 40px;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.criteria-header {
  background-color: rgb(248, 248, 248);
  height: 32px;
}

.criteria-header-label {
  font-weight: bold !important;
  font-size: 14px;
  line-height: 32px;
  color: rgb(16, 24, 40);
  padding: 8px 16px;

}

.vertical-line {
  border-right: 1px solid #ccc;
  height: 50%;
  left: 50%;
  margin-left: -3px;
}

.lookup_table {
  width: 90%;
  margin: 5%;
  border: 1px solid #ccc;
  background-color: white;
  border-spacing: 0;

}

.lookup_table td {
  border: 1px solid #999;
  margin: 0;
  padding: 0;
}
