@import "../../../../styles/colors.scss";

#approvalModal {

  font-size: medium;

  .inputField {
    padding: 16px;
  }

  justify-content: left !important;

  #pa-modal {
    border: solid 1px;
  }

  .content {
    text-align: center;

    padding: 0px !important;

    h1 {
      font-weight: 700;

    }

    span.line {
      display: block;
      width: 70%;
      height: 2px;
      margin: 20px auto;
      background-color: $grey-200;
    }

    .subheading {
      font-weight: 600;

      span {
        text-decoration: underline;
      }
    }

    span {
      color: $fliptRed;
    }
  }

  input[type='button'] {
    background-color: #fc3131 !important;
    color: white;
    width: 90px !important;
    border-radius: 4px;
  }

  .pa-modal-date-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center; 
    padding: 16px;
  }

  .pa-moda-date-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }

  .pa-moda-date-selector-text {
    width: 70px;
  }

  #pa-modal.date-picker {
    padding-left: 10px;
  }

  .bodyContain {
    padding: 32px;
  }

  .note-box {
    display: flex;
  }

  .note-box div {
    padding: 16px;
    ;
  }

}

.ui.large.modal {
  margin: 0;
}


.userName {
  text-align: left;
  padding: 16px;
  border-bottom: 1px solid #a1a1a1;
}