@import "../../../../../styles/colors.scss";

#eligibilityConfig {
    text-align: left;
    padding: 25px;

    >h2 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 18px;

    }

    >span {
        font-size: 16px;
        font-weight: 600;
        color: $v2-gray-700;
    }

    .radio-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 18px;

        >div {
            font-size: 14px;
            margin-right: 30px;
        }
    }

    .field-outer-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        >div {
            display: flex;
            flex-direction: row;

            .datepicker {
                width: 280px;
            }

            .member-dropdown {
                line-height: 14px;
                width: 280px;
            }

            >div {
                margin-bottom: 16px;

                .description-text {
                    font-size: 14px;
                }

                .v2-flipt-input {
                    line-height: 12px;
                    font-family: 'Nunito';
                    width: 280px;
                }
            }
        }

    }

    .primary {
        margin-bottom: 20px;
    }

}