@import "../../../../styles/colors.scss";

#claimDetails {
  overflow: auto;
  padding: 20px;
  text-align: left;

  > .content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 450px;
    max-width: 85%;
    max-height: 550px;

    .actions {
      > * {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}
