@import '../../../../../styles/colors.scss';

#create-medicare-plan {
  padding: 20px;
  text-align: left;
  margin-top: 10px;
  height: 900px;

  .header {
    position: relative;

    h1 {
      text-align: left;
      display: inline-block;
    }

    .link-router {
      position: absolute;
      right: 0;
      color: $fliptRed;

      span {
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }

  .tabContebt {
    width: 100%;
  }

  .subhead {
    padding: 10px;
    background: #ececed;
    margin-bottom: 20px;
  }

  .prescriber-section {
    display: flex;
  }

  .prescriber-section {
    margin-bottom: 20px;

    display: flex;
    flex-wrap: wrap;
    padding: 15px;

    >.prescriber-input {
      display: flex;
      flex-flow: column;
      margin-right: 1.5em;
      flex-grow: 1;
      margin-bottom: 15px;

      >.check {
        flex-direction: row;
        display: flex;
        flex-shrink: 0;
        white-space: nowrap;
        margin-top: 22px;
      
        span {
          color: #636363;
          font-size: 0.9em;
          font-weight: bold;
          margin-left: 5px;
        }
      }

      span {
        color: #636363;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
      }

      .field {
        height: 100%;
      }
    }
  }

  .prescriber-btn {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto;

    &.button-row {
      padding-bottom: 80px;
    }

    .backButton {
      background-color: $grey-800;
      color: $white;
    }

    .searchButton {
      text-transform: uppercase;
      margin: 0 10px;
    }

    .approvalButton {
      min-width: auto;
    }
  }

  .wrapper {
    padding: 20px;

    .input-container {
      display: flex;
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 3px;
      box-shadow: 0 2px 5px rgba(34, 36, 38, .15);
    }

    h1 {
      font-weight: 600;
      font-size: 18px;
      color: $grey-800;
      margin-bottom: 12px;

      &.review-heading {
        display: inline-block;
      }

      &.lics-header {
        // color: $black;
        margin-top: 30px;
        font-size: 140%;
      }
    }

    .header-container {
      position: relative;

      .searchButton {
        position: absolute;
        top: -30%;
        right: 0;
      }
    }

    .cost-share-grid {
      border: 1px solid #c0c3c8;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      height: 350px;
      width: 800px;
    }

    .addRowButtonContainer {
      margin-top: 15px;
      max-width: 100%;

      >* {
        margin-left: 50%;
        transform: translateX(-50%)
      }
    }

    .lics-grid {
      margin-bottom: 25px;
    }
  }


}