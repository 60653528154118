@import "../../../../../styles/colors.scss";

#createPriorAuthorization {
  padding: 20px;
  text-align: left;

  .breadCrumbContainer {
    display: flex;
    flex-direction: row;

    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;

      span {
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }

  >.content {
    margin: 10px;
    padding-right: 10px;

    .button-header-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
      margin-top: 20px;
    }

    .checkbox-label {
      color: $v2-gray-900;
      font-weight: bold;
      font-size: 18px;
      padding: 0em 3em 0em 1.2em;
    }

    .export-button {
      background-color: #039855;
      border-radius: 6px;
      color: white;
    }

    .section {
      .pa-inputs-container {
        margin-bottom: 20px;

        .pa-intputs-wrap {
          display: flex;
          flex-wrap: wrap;

          >.pa-inputs {
            display: flex;
            flex-flow: column;
            margin-right: 1.5em;
            flex-grow: 1;
            margin-bottom: 15px;

            span {
              color: #636363;
              font-size: 0.9em;
              font-weight: bold;
              margin-bottom: 5px;
            }

            input {
              border: 1px solid rgba(34, 36, 38, 0.15);
              border-radius: 0.28571429rem;
            }

            .create-pa-start-date {
              width: 100%;
              height: 100%;
            }

            .field {
              height: 100%;
            }
          }
        }

        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      }

      .grid-container {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        margin-top: 30px;

        >.add-pa-condition-level {
          margin-top: 15px;
          text-align: right;
        }

        >.pa-condition-levels {
          // outline: 1px solid black;
          overflow: auto;
          max-width: 100%;
          margin-top: 15px;
          padding: 0px 10px 10px 10px;

          .pa-condition-levels-accordion {
            width: 100%;

            .pa-condition-level {
              .pa-condition-level-delete {
                padding: 0px 0px 10px 50px;
              }

              .pa-conditions {
                // outline: 1px solid red;
                overflow: auto;
                height: 350px;
                margin-top: 5px;
                padding: 0px 0px 10px 0px;
              }

              .pa-condition-level-button-container {
                >.pa-condition-level-add-cond-button {
                  // outline: 1px solid blue;
                  display: flex;
                  justify-content: center;
                }
              }

              .pa-condition-level-qualifier {
                // outline: 1px solid red;
                // overflow: auto;
                height: 350px;
                margin-top: 15px;
                padding: 0px 0px 10px 0px;

                >.pa-qualifier-level-add-cond-button {
                  // outline: 1px solid blue;
                  margin-top: 15px;
                  margin-left: 50%;
                }
              }

              .pa-condition-level-drugs {
                // outline: 1px solid red;
                overflow: auto;
                height: 400px;
                margin-top: 50px;
                padding: 0px 10px 10px 0px;
              }
            }
          }
        }
      }
    }

    .buttonContainer {
      >* {
        margin-right: 40px;
      }

      padding: 30px 0px 40px 0px;
    }
  }
}

.saveTierModal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    >*:first-child {
      margin-right: 10px;
    }
  }
}

.copy-object {
  position: absolute;
  top: 17%;
  background-color: white;
  max-width: 70% !important;
  right: 5%;
  box-shadow: 0px 3px 20px !important;
  max-height: 80vh !important;

  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid #c0c3c8;

  >.pa-inputs {
    display: flex;
    flex-flow: column;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;

    span {
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 15px;
      margin-right: 10px;
    }

    input {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      margin-right: 15px;
    }

    .create-pa-start-date {
      width: 100%;
      height: 100%;
    }

    .field {
      height: 100%;
    }
  }

  padding: 15px;
  border: 1px solid #c0c3c8;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

  .buttonContainer {
    >* {
      margin-right: 40px;
    }

    // padding: 1px 0px 1px 0px;
  }
}
