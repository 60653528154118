@import "../../../../styles/colors.scss";

#claimConfig {
    text-align: left;
    padding: 25px;

    h2 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    >span {
        font-size: 16px;
        font-weight: 600;
        color: $v2-gray-700;
    }

    .radio-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 18px;

        >div {
            font-size: 14px;
            margin-right: 30px;
        }
    }

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .grid-container {
        height: 300px;
        margin-top: 20px;
        margin-bottom: 50px;
    }

}