#batch-alert-popup {
    display: flex;
    flex-direction: column;

    >h3 {
        font-weight: bold;
        font-size: 16px;
        margin-left: 8px;
    }

    >span {
        margin-top: 8px;
        font-size: 14px;
        margin-left: 8px;

        >span {
            font-weight: bold;
        }
    }

    >p {
        font-size: 14px;
        font-weight: bold;
        margin-left: 8px;
    }

    .button-class {
        margin-top: 8px;
        align-self: center;
        border-radius: 8px;
        width: 90%;
        background-color: #101828;
        color: white;
    }

    .red-button {
        margin-top: 8px;
        border-radius: 8px;
    }

    .row-container {
        display: flex;
        flex-direction: row;
        margin-left: 8px;
        margin-top: 12px;

        >span {
            margin-left: 5px;
            font-weight: bold;
            font-size: 16px;
        }
    }

}