@import "../../../styles/colors.scss";

//BUTTON
.flipt-button {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  display: inline-flex;
  font-family: $v2-primary-font-family;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
  line-height: 20px;
  padding: 10px 16px;

  &.text {
    background-color: transparent;
    border: 0;
    color: $fliptRed;
    margin: 0;
    padding: 0;
  }

  &.primary {
    background-color: $v2-gray-700;
    color: $v2-white;

    &.primary:hover {
      background-color: $v2-gray-500;
      transition: all 0.5s ease-in-out;
    }

    &[disabled] {
      background-color: rgba(52, 64, 84, 0.30);
      color: $v2-white;
      cursor: not-allowed;
      font-weight: 300;
    }
  }

  // &.invisible {
  //   background-color: $v2-white;
  //   border: none;
  //   outline: none;
  // }

  &.secondary {
    background-color: $v2-white;
    border: 1px solid $v2-gray-300;
    color: $v2-gray-700;
  }

  // &.tertiary {
  //   color: $frenchGray;
  //   font-size: 13px;
  // }

  .flipt-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

}
