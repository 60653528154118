@import '../../../../styles/colors.scss';

#DeductibleDetails {
  padding: 20px;
  text-align: left;

   .header {
    margin: 25px 0px -5px 10px;
  }
  
  .deductible-met {
    background-color: #c0ffc8;
  }

  .item {
    height: 50px;
    justify-content: center;
    margin-right: 0.5em;
    background-color: #e8e8ea;
    border-radius: 5px 5px 0px 0px;
    padding: 3px 10px 7px 10px;
    cursor: pointer;
    font-size: 20px;
  }

  .active-item {
    -webkit-box-shadow: 0px -2px 2px 0px grey;
    background: #fff;
    font-weight: 700;
  }
}

#deductible_medicare {

  padding: 15px;
  box-shadow: 0px 0px 6px 0px;
  margin: 0px 10px;
  border-radius: 0px 5px 5px 5px;

  > .content {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;
    height: 450px;
    > .ag-header-cell-text{
      text-transform: capitalize;
    }
  }
}