@import "../../styles/colors.scss";

#approvals-approval-options {
  // align-self: center;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 20px;

  .v2-flipt-dropdown {
    flex-grow: 0;
    line-height: 20px;

    div.ui.selection.dropdown {
      width: fit-content;
      line-height: 20px;
    }
  }

  .flipt-button {
    line-height: 20px;
  }

  div.ui.steps {
    margin: 0;
    border-radius: 8px;
    border: 1px solid $v2-gray-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background: none;

    .step {
      padding: 10px 16px;
      font-size: 12px;
      line-height: 20px;

      .icon {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }

}
