
@import '../../../../../styles/colors.scss';


#claim-processing-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 300px;
    margin-top: 20px;
    margin-bottom: 50px;

    .generate-member {
        align-self: center;
    }
    .not-found-container {
		display: flex;
		align-self: flex-start;
		width: 100%;
		height: 40px;
		background: $v2-gray-200;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
	}

	.not-found-text {
		color: #E63324;
		font-weight: 500;
		font-size: 14px;
	}
}
