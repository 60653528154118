#audit-mgmt {
    padding: 20px 20px 75px 20px;
    text-align: left;

    .grid-container {
        height: 600px;
        margin-top: 20px;
    }

    .field-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        margin: 18px 18px 18px 0px;
    }
}