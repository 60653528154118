@import '../../../styles/colors.scss';

#adap-spap-dashboard {
    display: flex;
    flex-direction: column;

    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }
    >.main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      padding: 20px;
    }
    .devider {
      height: 1px;
      background-color: #EAECF0;
      align-self: center;
      width: 100%;
      margin: 0px 12px 12px 0px;
  }
    .content {
      height: 460px;
      padding: 20px;
      text-align: left;
    }
    .fields-container {         
      margin-top: 10px;
      margin-left: 20px;
      .fields-client-header {
        width: 31.3%;
        margin-right: 20px;
      } 
      .search-button{
        width: 100%; 
        margin-top: 20px;
      }
      .half-width{
        align-items: inherit;
      }
      .field-label{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        gap:10px;
        text-align: left;
        font-size: 14px;
        font-weight: 700;
      }
      .field-input{
        width: 100%;
        
      }
   
    }

}