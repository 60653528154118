@import "../../../../styles/colors.scss";

#drugLookup {
  text-align: left;

  .section {
    .drug-lookup-inputs-container {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
      border: 1px solid #c0c3c8;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

      >.drug-lookup-inputs {
        display: flex;
        flex-flow: column;
        margin-right: 1.5em;
        flex-grow: 1;
        margin-bottom: 15px;

        span {
          color: #636363;
          font-size: 0.9em;
          font-weight: bold;
          margin-bottom: 5px;
        }

        input {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 0.28571429rem;
        }

        .drug-lookup-search {
          width: 100%;
          height: 100%;
        }
      }
    }

    .searchDrugLookup {
      margin-bottom: 20px;

      input {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 20px;
      }
    }

    .grid-container {
      border: 1px solid #c0c3c8;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      height: 350px;
    }
  }
}
