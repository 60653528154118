@import '../../../styles/colors.scss';

#medispanLookup {
  padding: 20px;
  text-align: left;

  > .content {
    border-top: 2px solid $grey-200;
    margin-top: 10px;
    overflow: auto;
    height: 700px;
    max-width: 95%;
    margin-bottom: 60px;

    .action {
      > * {
        background-color: $gallery;
        margin-right: 1px;
        padding: 5px 10px;
      }
    }
  }

  .header {
    display: flex;
    // padding: 30px;
  }

  .clearButton {
    font-size: 14px;
    width: 80px;
  }

  .subhead {
    // margin: 0px 0px 15px 30px !important;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .subhead > * {
    margin-right: 10px;
    margin-bottom: 1em;
    z-index: 5;
  }

  h2 {
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .section {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .fields {
      width: 33%;

      > div {
        background-color: $grey-400;
        margin-bottom: 10px;
        padding-left: 10px;

        &:nth-child(2n) {
          background-color: $grey-200;
        }
      }
    }
  }
}
#medispanLookupPopup {
  .popup {
    position: absolute;
    top: 22%;
    background-color: white;
    max-width: 60% !important;
    right: 11%;
    box-shadow: 0px 3px 20px !important;
    max-height: 65vh !important;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    border: 1px solid #c0c3c8;
    z-index: 10;
    .popupTable {
      margin-top: -10px;
      overflow: auto;
      height: 500px;
      width: 600px;
      max-width: 95%;
      margin-bottom: 10px;
    }
    .buttonContainer {
      > * {
        margin-right: 40px;
      }
      // padding: 1px 0px 1px 0px;
    }
  }

}