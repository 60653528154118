@import './colors';

$fontFamily: "Inter";
$fontColor:$v2-gray-900;

$fontWeights: (
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
);

@mixin fontStyling(
  $fontSize,
  $weight,
  $lineHeight,
  $letterSpacing,
  $color: $fontColor,
  $fontStyle:normal,
) {
  $fontWeight: map-get($fontWeights, $weight);

  font-family: $fontFamily;
  font-size: $fontSize;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  color: $color;
}

@mixin display_2xl($weight: regular, $color: $fontColor) {
  @include fontStyling(72px, $weight, 90px, -1.44px, $color);
}

@mixin display_xl($weight: regular, $color: $fontColor) {
  @include fontStyling(60px, $weight, 72px, -1.2px, $color);
}

@mixin display_lg($weight: regular, $color:$fontColor) {
  @include fontStyling(48px, $weight, 60px, -0.96px, $color);
}

@mixin display_md($weight: regular, $color:$fontColor) {
  @include fontStyling(36px,$weight, 44px, -0.72px, $color);
}

@mixin display_sm($weight: regular, $color: $fontColor) {
  @include fontStyling(30px, $weight, 38px, normal, $color);
}

@mixin display_xs($weight: regular, $color:$fontColor) {
  @include fontStyling(24px, $weight, 32px, normal, $color);
}

@mixin text_xl($weight: regular, $color:$fontColor) {
  @include fontStyling(20px, $weight, 30px, normal, $color);
}

@mixin text_lg($weight: regular, $color:$fontColor) {
  @include fontStyling(18px, $weight, 28px, normal, $color);
}

@mixin text_md($weight: regular, $color: $fontColor) {
  @include fontStyling(16px, $weight, 24px, normal, $color);
}

@mixin text_sm($weight: regular, $color: $fontColor) {
  @include fontStyling(14px, $weight, 20px, normal, $color);
}

@mixin text_xs($weight: regular, $color:$fontColor) {
  @include fontStyling(12px, $weight, 18px, normal, $color);
}
