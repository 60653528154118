@import "../../styles/colors.scss", "../../styles/themes.scss";

input,
button,
[role="listbox"] {
  border: none;
  font-family: "Nunito", "Avenir-Medium";
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  max-height: 15rem !important;

  input[disabled="true"] {
    text-transform: uppercase;
  }
}

.enhanced-checkbox-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.check {
  flex-direction: row;
  display: flex;

  span {
    color: #636363;
    font-size: 0.9em;
    font-weight: bold;
    margin-left: 5px;
  }
}

i.icon {
  cursor: pointer;
}

i.dropdown.icon::before {
  color: black;
  height: 11px;
  width: 22px;
}

.has-error {
  outline: 1px solid $fliptRed !important;
}

.flipt-input-error {
  display: flex;
  flex-direction: row;
  color: $fliptRed;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}

.pa-stylized {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flipt-pa-label {
  font-size: small;
  text-align: left;
  font-weight: bold;
  color: #666a67;
  padding-bottom: 3px;
}

// DROPDOWN

.selection.dropdown {
  z-index: 1;
  text-transform: capitalize !important;
}

.selection.dropdown .item {
  text-transform: capitalize !important;
}

.selection.dropdown.emails {
  text-transform: none !important;
}

.selection.dropdown.emails .item {
  text-transform: none !important;
}

div.dropdown[role="listbox"] {
  width: auto;
  position: relative;

  >div.menu.transition {
    max-height: 15rem !important;
    width: auto;
    overflow-y: auto !important;
  }
}

div.dropdown[role="listbox"] {
  color: $grey-800;
  font-size: 12px;

  div[role="option"] {
    font-size: 12px;

    >i {
      display: inline;
    }

    >span {
      display: inline-block;
    }
  }
}

div.ag-react-container {
  div[role="listbox"] {
    min-height: auto !important;
    height: 150px;
  }
}

//INPUT FIELD
div.stylized {
  // align-items: baseline;
  display: flex;

  .left {
    background-color: transparent;
  }

  >input.state {
    width: 60%;
  }

  div[role="listbox"] {
    >div[role="button"]>i[aria-hidden="true"] {
      display: none;
    }
  }

  >div {
    font-size: 0.7em;
    padding-right: 5px;
    padding-left: 10px;
    width: 40%;
  }

  .flipt-label {
    padding: 10px;
  }
  .flipt-label-full {
    padding: 10px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
  }

 


  &.currency {
    position: relative;

    >span:before {
      content: "$";
      display: inline-block;
      font-size: 0.7em;
      margin: 0 0 0 3px;
      position: absolute;
      top: 11px;
    }
  }

  >input,
  >.field {
    width: 60%;

    div[data-testid="datepicker-input"] {
      width: 100%;
    }

    label {
      display: none;
    }
  }
}

input {

  &[type="text"],
  &[type="password"] {
    border-radius: 0;
    outline: 1px solid $gallery;
  }

  &[disabled] {
    background-color: $grey-100;
    color: $grey-800;
    cursor: not-allowed;
  }

  &[validation="email_address"],
  &[type="email"] {
    text-transform: lowercase !important;
  }
}

//BUTTON
input[type="button"] {
  cursor: pointer;

  &.text {
    background-color: transparent;
    border: 0;
    color: $fliptRed;
    margin: 0;
    padding: 0;
  }

  &.primary {
    background-color: var(--theme-primary, $fliptRed);
    color: $white;
    font-size: 14px;
    min-width: 100px;
    padding: 10px 5px;

    &.primary:hover {
      background-color: $doveGray;
      color: $grey-400;
      transition: all 0.5s ease-in-out;
    }

    &[disabled] {
      background-color: $grey-200;
      color: $grey-600;
      cursor: not-allowed;
      font-weight: 300;
    }
  }

  &.invisible {
    background-color: $white;
    border: none;
    outline: none;
  }

  &.secondary {
    background-color: $grey-800;
    color: $white;
    font-size: 14px;
    min-width: 100px;
    padding: 10px 5px;
  }

  &.tertiary {
    color: $frenchGray;
    font-size: 13px;
  }
}

//DATEPICKER
.clndr-calendars-wrapper {

  >button,
  i {
    background-color: $white;
  }
}

//SEPARATOR
.fliptSeparator {
  grid-column-start: 1;
  grid-column-end: 5;
}

//SEARCH
div.results.transition.visible {
  max-height: 15rem !important;
  overflow: scroll;
}

// Hierarchy Dropdown
.dropdown-wrap {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .inputs {
    // outline: 1px solid red;
    display: flex;
    flex-flow: column;
    // width: 10px;
    // max-width: 25%;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;

    span {
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 5px;
    }

    input {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
    }

    .dropdown {
      height: 100%;
      z-index: 1;
    }
  }
}

.dropdown.item.selection.simple.ui:hover {
  z-index: 10;
}

.react-datepicker__month {
  height: auto !important;
}