@import '../../../../styles/colors.scss';

.rewards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  margin-top: 10px;
  text-align: left; 
  .checkbox {
    padding: 0 10px;
  }
  .header {
    display: flex;
  }
  .stylized {
    .flipt-label {
      font-size: 10px;
      width: 100% !important
    }
  }
  .fields-label {
    font-weight: bold;
    color: $grey-800;
  }
}

.filterOptions {
  background-color: #F0F0F0;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  align-items: center;
  margin: 0px 0px 15px 0px !important;
  text-align: left;

  > * {
    margin-right: 30px;
  }

  div.stylized {
    display: block;
  }
  div.ui.dropdown {
    border: 1px solid $grey-600;
  }
  input {
    border: 1px solid $grey-600;
    border-radius: .28571429rem;
    width: 90%;
  }

  #CAGFilters {
    margin-bottom: 0;
  }

  .flipt-label {
    width: 100%;
    color: #636363;
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 5px;
    padding-left: 0px;
  }
  input.primary.searchButton {
    width: 40%;
    margin-left: 40px;
    margin-bottom: 10px;
  }
}