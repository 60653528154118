@import "../../../styles/colors.scss";

#bpgConfiguration {
  padding: 20px;
  
  .breadCrumbContainer {
    display: flex;
    flex-direction: row;

    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;

      span {
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }
}
