@import "../../../../styles/colors.scss";

.compounds {
  padding: 20px;
  text-align: left;

  .checkbox {
    padding: 0 10px;
  }

  .header {
    display: flex;
  }

  .section-compounds {
    display: flex;

    .flipt-label {
      font-size: 0.7em !important;
    }

    .compounds-dropdowns {
      display: flex;
      flex-direction: row;
      margin-right: 1.5em;
      flex-grow: 0;
      margin-bottom: 15px;
      width: 275px;

      span {
        color: #636363;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
      }

      .field {
        height: 100%;
      }

      .flipt-label {
        width: 100%;
      }
    }

    .fields-label {
      font-weight: bold;
      color: $grey-800;
    }
  }

  .grid-header {
    font-size: 16px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .grid-container {
    height: 250px;
    border: 1px solid #c0c3c8;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

    .ag-theme-alpine .ag-root-wrapper {
      overflow: initial;
    }

    >.addRowButtonContainer {
      margin-top: 15px;
      max-width: 90%;

      >* {
        margin-left: 50%;
      }
    }
  }
}