@import '../../../styles/colors.scss';

.prescriber-lookup {
    padding: 20px;
    text-align: left;
    margin-top: 10px;

    >.content {
        // overflow: auto;
        // margin: 10px 10px 50px 10px;
        // padding-right: 10px;
        border-top: 2px solid $grey-200;
        margin-top: 10px;
        overflow: auto;
        height: 700px;
        max-width: 95%;
        margin-bottom: 30px;

        .action {
            >* {
                background-color: $gallery;
                margin-right: 1px;
                padding: 5px 10px;
            }
        }
    }

    .prescriber-section {
        display: flex;
    }

    .header {
        text-align: left;
    }

    .subhead {
        padding: 10px;
        background: #ececed;
        margin-bottom: 20px;
    }

    .errMessage {
        color: red;
        font-size: 10px;
        font-weight: bold;
    }

    .prescriber-input {
        margin-right: 20px;
        flex-grow: 1;
        max-width: 25%;

        span {
            display: inline-block;
            margin-bottom: 15px;
            font-size: 11px;
        }

        input {
            border: 1px solid rgba(34, 36, 38, .15);
            width: 100%;
        }
    }

    .prescriber-btn {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .clearButton {
        margin-left: 8px;
    }
}

.textCenter {
    text-align: center;
}

.contentModal {
    max-width: 50%;
    align-items: center;
    margin: 0 auto;
}