@import '../../../../styles/colors.scss';

#Communications {
    height: 100%;
    width: 100%;

    .content {
        height: 300px;
        width: 100%;
        padding: 12px 12px 0px 12px;
    }

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        align-items: baseline;
        caret-color: transparent;
    }

    .header-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
    }

    .msg-header {
        font-size: 16px;
        font-weight: bold;
        padding-left: 12px;
        caret-color: transparent;
    }

    .devider {
        background: #EAECF0;
        height: 1px;
        align-self: center;
        width: 95%;
        display: flex;
        margin: 20px 10px 10px 20px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-between;
        caret-color: transparent;
    }

    .xml-content {
        padding: 12px;
        height: 250px;
        overflow-y: scroll;
    }
}