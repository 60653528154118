#timerConfig {
    padding: 20px;
    text-align: left;

    .header {
        font-size: 18px;
        font-weight: 600;
    }

    .icon-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: -25px;
    }

    .calendar-header {
        font-size: 14px;
        font-weight: 600;
    }


    .border-box {
        background: #FFFFFF;
        /* Gray/200 */
        border: 1px solid #EAECF0;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
    }
    .inner-fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        margin: 10px;
        padding: 24px;
        gap: 20px;
        input {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: start;
            margin-right: 24px;
            height: auto;
            outline: 1px solid #F0F0F0 !important;
            border: 1px solid #d0d5dd !important;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
            color: #666A67 !important;
        }
    }

    .margin-bottom {
        margin-bottom: 40px;
    }

    .buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;


        .primary {
            margin-right: 1rem;
        }
    }

    .outer-grid-container {
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .grid-container {
        margin-bottom: 30px;
        width: 95%;
        min-height: 250px;
        height: 250px;
        text-align: left;
    }
    .questionnaire-cag {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 1em;
        padding: 20px;
      }

}