@import "../../../../../../styles/colors.scss";

#createUser {
  height: 100vh;
  flex-direction: row;
  padding: 20px;
  text-align: left;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  hr {
    margin-bottom: 50px;
  }

  .section {
    margin-bottom: 40px;
  }

  h2 {
    align-items: baseline;
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .fields-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    width: 90%;

    >.fields {
      margin-right: 10px;
      width: 30%;

      .field {
        display: inline-block;
        max-width: 300px;
        min-width: 125px;
      }

      >div {
        background-color: $whiteSmoke;
        margin-bottom: 10px;
        max-height: 35px;


        &:nth-child(2n) {
          background-color: $grey-300;
        }

        .ui.selection.dropdown {
          border: 1px solid $grey-200;
          color: #000000;
          min-width: 300px;
        }

      }

      .ui.dropdown>.text {
        color: $black;

      }
    }
  }

  input {

    &[name="email_address"],
    &[type="email"] {
      text-transform: lowercase !important;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    z-index: 100;

    >input {
      display: block;
      margin-right: 10px;
      margin: 10px;
      width: 10%;
      padding: 10px 20px;
    }
  }

  .enclosing-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  h2 {
    flex-basis: 7%;
  }

  .create-user-section {
    height: inherit;
    flex-basis: 30%;
    padding: 1rem;
    box-shadow: 2px 2px 2px 2px lightgrey;
  }

  .lower-section {
    display: flex;
    flex-direction: row;
    flex-basis: 60%;
    margin-top: 1rem;
  }

  .hierarchy-section {
    width: 40%;
    height: 48%;
    margin-bottom: 20px;
  }

  .hierarchy-tree {
    width: 100%;
  }

  .roles-section,
  .teams-section {
    width: 25%;
  }

  .roles-table,
  .teams-table {
    overflow-y: scroll;
    height: 250px;
    margin-bottom: 20px;
  }

  .teams-section {
    margin-right: 1rem;
  }

  .top-fields,
  .bottom-fields {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .fields {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 2rem;
  }

  .border {
    box-shadow: 2px 2px 2px 2px lightgrey;
    height: 91%;
  }

  .field-name {
    align-items: baseline;
    color: $grey-800;
    display: inline-block;
    font-size: 22px;
    padding: 10px 0px;
    font-weight: bolder;
  }

  .fields-label {
    margin-bottom: 1rem;
    font-weight: bold;
    color: $grey-800;
  }

  .fields-input {
    border: 1px solid $grey-400;
  }

  .hierarchy-slider-div {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 15px;

    input, label {
      display: block;
      font-size: 12px;
    }
    
    /* The switch - the box around the slider */
    .switch {
      position: relative;
      display: inline-block;
      width: 46px;
      height: 20px;
      margin-left: 10px;
    }
    
    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      background-color: #2196F3;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
  }
  
  .hierarchy-select-all-button {
    margin-top: 22px;
    margin-bottom: 20px;
    height: 30px;
    width: 80px;
    font-size: 10px;
    margin-right: 5px; 
    margin-left: 5px;
  }

  .hierarchy-checkbox {
    margin-top: 29px;
    margin-bottom: 20px;
  }

}

