@import "../../../../styles/colors.scss";

#createBPGConfiguration {
  padding: 20px;

  .breadCrumbContainer {
    display: flex;
    flex-direction: row;

    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;

      span {
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }

  .sections {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 20px;
    
    .inputs-container {
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
      border: 1px solid #c0c3c8;
      border-radius: 8px;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      width: 100%;
      gap: 16px;

      >.inputs {
        display: flex;
        flex-flow: row;
        flex-grow: 1;
        gap: 20px;

        input {
          font-weight: normal;
        }
        
        .dropdown-wrap span {
          align-self: flex-start;
        }

        .field {
          width: 100%;
        }

        .create-bpg-config-datepicker {
          width: 100%;
        }

      }
    }

    .conditions-section {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .bpg-configuration-conditions {
        overflow: auto;
        height: 350px;
        margin-top: 5px;
      }

      .buttons-container {
        display: flex;
        flex-direction: row;
        gap: 8px; 
      }
    }    
  }
}
