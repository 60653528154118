@import '../../../styles/colors.scss';

#FormularyExportConfiguration {
    padding: 20px;
    text-align: left;
    overflow: auto;
    min-height: 80vh;

    .link-text {
        cursor: pointer;
    }

    >.content {
        margin: 0px 10px 10px 10px;
        overflow: auto;
        padding-right: 10px;

        >.section {
            height: 500px;
        }


    }


}