#drug-management-popup {
  box-shadow: none !important;
}
.menu-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: 1rem 0;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    .menu-item:hover {
      cursor: pointer;
      background: rgb(240, 240, 240);
    }
    .menu-item.active {
      background-color: #dc3545;
      border-color: #dc3545;
      color: white;
    }
    .menu-item {
      display: block;
      padding: 0.5rem 1rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      border: 0;
    }
  }
  
  .close-button {
    display: flex;
    padding: 10px;
    justify-content: center;
  }
  .drug-lookup-inputs-container {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    border: 1px solid #c0c3c8;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    > .drug-lookup-inputs {
      display: flex;
      flex-flow: column;
      margin-right: 1.5em;
      flex-grow: 1;
      margin-bottom: 15px;
      span {
        color: #636363;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 5px;
      }
      input {
        // outline: 1px solid purple;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
      }
      .drug-lookup-search {
        // outline: 1px solid black;
        width: 100%;
        height: 100%;
      }
    }
  }
  .section-content {
    height: 100%;
  }
  .grid-container {
    height: 100%;
  }
  .edit-button {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin: 10px;
    color: red;
    font-size: .9em;
    font-weight: bold;
    align-items: center;
}
.header-container {
  display: flex;
}
.modalHeader {
  display: flex;
  justify-content: center;
}
.modalDescriptionContainer {
  display: flex;
  justify-content: center;
}
.modalChange {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em; 
}
.modalCenterChange {
  font-size: 2rem;
  align-items: center;
  display: flex;
  padding: 1em;
}
.content {
  .popup {
    .actions {
      margin: 10px;
    }
  }
}
.actions {
  text-align: center !important;
  .linkButton{
    background: none!important;
    border: none;
    padding: 0!important;
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }
}
.modalDescriptionColumn{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.flexCenter{
  text-align: center;
}
.bold{
  font-weight: bold;
}
.closeButton{
  text-align: right;
  cursor: pointer;
}
.impactedObjectContainer{
  height: 100%;
}
.labelCenter{
  display: flex;
  justify-content: center;
  margin: 10px;
}
#close-button{
  text-align: center;
  .closeButton{
    margin: 10px;
    background-color: red;
    color: #fff;
  }
}
.noActionNeededStatusClass{
  color: #666a67
}
.noActionNeededStatusClass:before{
    font-family: Icons;
    content: "\f111";
    margin: 0 5px 0 0;
}
.needReviewStatusClass, .draftStatusClass{
  color: #f89406
}
.needReviewStatusClass:before, .draftStatusClass:before{
    font-family: Icons;
    content: "\f017";
    margin: 0 5px 0 0;
}
.completedStatusClass, .publishedStatusClass, .approvedStatusClass{
  color: #04cf00
}
.highStatusClass{
  color: #db0c0c
}
.completedStatusClass:before, .publishedStatusClass:before{
    font-family: Icons;
    content: "\f111";
    margin: 0 5px 0 0;
}
.approvedStatusClass:before{
  font-family: Icons;
  content: "\f058";
  margin: 0 5px 0 0;
}
#impactedObjects{
  .accordion{
    width: 100%;
  }
  .content.active {
    height: 100%;
  }
}
#impactedObjectInput{
  .disabled{
    text-decoration: none
  }
}
#planDesignManagement {
  .header-actions {
    display: flex;
    padding: 5px 0px;
    .header-bulkactions {
      .closeButton{
        background-color: red;
        color: #fff;
        z-index: 10;
      }
      display: flex;
      padding: 0px;
      .stylized {
        padding: 0 8px 0 0;
        width: 250px;
        .flipt-label {
          padding: 0;
          visibility: hidden;
          display: none;
        }
        .dropdown {
          flex-grow: 1;
        }
      }
    }
  }
  .content.maxHeight {
    height: 570px
  }
}
.header.red {
  color: red;
}
div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}
