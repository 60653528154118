@import '../../styles/themes.scss','../../styles/themes.scss';

div#footer {
  font-family: 'Nunito', 'Avenir-Book';
  font-weight: 600;
  background-color: var(--theme-primary);
  height: 35px;
  padding: 12px;
  position: fixed;
  bottom: 0;
  color: var(--theme-background);
  letter-spacing: 0.3px;
  text-align: center;
  width: 100%;
  font-size: 12px;
  z-index: 100;
}
