@import '../../../../styles/colors.scss';

#addNewAccount {
  display: flex;
  flex-direction: column;

  padding: 20px;
  text-align: left;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .new-account {
      display: flex;
      align-items: center;
      color: $fliptRed;
      font-size: 70%;
    }

    .left {
      background-color: transparent !important;
    }
  }

  .footer {
    display: flex;
    justify-content: center;

    .searchButton {
      margin: 1em;
    }
  }
}
