@import "../../../../../styles/colors.scss";

#createQuantityLimit {
  padding: 20px;
  text-align: left;
  .breadCrumbContainer {
    display: flex;
    flex-direction: row;
    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;
      span{
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }
  > .content {
    margin: 10px;
    padding-right: 10px;

    .button-header-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
      margin-top: 20px;
    }
  
    .checkbox-label {
        color: $v2-gray-900;
        font-weight: bold;
        font-size: 18px;
        padding: 0em 3em 0em 1.2em;
    }
    .export-button {
        background-color: #039855;
        border-radius: 6px;
        color: white;
    }

    .section {
      .ql-inputs-container {
        margin-bottom: 20px;

        .ql-intputs-wrap {
          display: flex;
          flex-wrap: wrap;
          > .ql-inputs {
            display: flex;
            flex-flow: column;
            margin-right: 1.5em;
            flex-grow: 1;
            margin-bottom: 15px;
            span {
              color: #636363;
              font-size: 0.9em;
              font-weight: bold;
              margin-bottom: 5px;
            }
            input {
              border: 1px solid rgba(34, 36, 38, 0.15);
              border-radius: 0.28571429rem;
            }
            .create-ql-start-date {
              width: 100%;
              height: 100%;
            }
            .field {
              height: 100%;
            }
          }
        }
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      }
      .grid-container {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        margin-top: 30px;

        > .add-ql-condition-level {
          margin-top: 15px;
          text-align: right;
        }

        > .ql-condition-levels {
          // outline: 1px solid black;
          overflow: auto;
          max-width: 100%;
          margin-top: 15px;
          padding: 0px 10px 10px 10px;
          .ql-condition-levels-accordion {
            width: 100%;
            .ql-condition-level {
              .ql-condition-level-delete {
                padding: 0px 0px 10px 50px;
              }
              .ql-conditions {
                // outline: 1px solid red;
                overflow: auto;
                height: 350px;
                margin-top: 5px;
                padding: 0px 0px 10px 0px;
              }
              .ql-condition-level-button-container {
                > .ql-condition-level-add-cond-button {
                  // outline: 1px solid blue;
                  margin-left: 50%;
                }
              }
              .ql-condition-level-criteria {
                // outline: 1px solid red;
                // overflow: auto;
                height: 350px;
                margin-top: 15px;
                padding: 0px 0px 10px 0px;
                // > .ql-criteria-level-add-cond-button {
                //   // outline: 1px solid blue;
                //   margin-top: 15px;
                //   margin-left: 50%;
                // }
              }
              .ql-condition-level-drugs {
                // outline: 1px solid red;
                overflow: auto;
                height: 400px;
                margin-top: 50px;
                padding: 0px 10px 10px 0px;
              }
            }
          }
        }
      }
    }
    .buttonContainer {
      > * {
        margin-right: 40px;
      }
      padding: 30px 0px 40px 0px;
    }
  }
}

.saveTierModal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    > *:first-child {
      margin-right: 10px;
    }
  }
}

.copy-object {
  position: absolute;
  top: 22%;
  background-color: white;
  max-width: 60% !important;
  right: 12%;
  box-shadow: 0px 3px 20px !important;
  max-height: 60vh !important;

  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid #c0c3c8;

  > .q-inputs {
    display: flex;
    flex-flow: column;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;
    span {
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 15px;
      margin-right: 10px;
    }
    input {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      margin-right: 15px;
    }
    .create-q-start-date {
      width: 100%;
      height: 100%;
    }
    .field {
      height: 100%;
    }
  }
  padding: 15px;
  border: 1px solid #c0c3c8;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

  .buttonContainer {
    > * {
      margin-right: 40px;
    }
  }
}
