@import "../../../../styles/colors.scss";
@import "../../../../globalStyles/index.scss";
@import "../../../../globalStyles/abstracts/colors";

#approvalQueue {
  padding: 50px;
  text-align: left;

  .header {
    text-align: left;
  }

  > a {
    margin-right: 50px;
    margin-bottom: 50px;
  }

  // .content {
  //   margin: 24px 0px;
  //   padding-right: 10px;

  //   .grid-Column {
  //     .actions {
  //       text-align: left !important;
  //       a.link {
  //         @include text_sm($color:$v2-gray-500);
  //       }
  //     }
  //   }
  // }

  .content {
    display: flex;
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 650px;
    max-width: 99%;
    max-height: 650px;

    .status_value{
      display:ruby;
      height: 26px;
      width: 100%;
      background-color: $v2-success-25;
      border-radius: 18px;
      padding:4px 36px 4px 18px;
      &.published{
        background-color: $v2-success-400;
        p{
          text-align: center;
          @include text_xs(medium, #000000);
          text-transform: capitalize;
        }
      }
      &.approved{
        background-color: #6F42C1;
        p{
          text-align: center;
          @include text_xs(medium, #000000);
          text-transform: capitalize;
        }
      }
      &.publishInProgress{
        background-color: #FD7E14;
        p{
          text-align: center;
          @include text_xs(medium, #000000);
          text-transform: capitalize;
        }
      }
      &.publishMarkedForRetry{
        background-color: $v2-warning-300;
        p{
          text-align: center;
          @include text_xs(medium,$v2-warning-500);
          text-transform: capitalize;
        }
      }
      &.forReview{
        background-color: #007BFF;
        p{
          text-align: center;
          @include text_xs(medium, #000000);
          text-transform: capitalize;
        }
      }
      &.publishFailed{
        background-color: $v2-error-300;
        p{
          text-align: center;
          @include text_xs(medium,$v2-error-900);
          text-transform: capitalize;
        }
      }
      &.publishReady{
        background-color: hsl(312, 73%, 46%);
        p{
          text-align: center;
          @include text_xs(medium, #000000);
          text-transform: capitalize;
        }
      }
      &.draft{
        background-color: #D3D3D3;
        p{
          text-align: center;
          @include text_xs(medium, #000000);
          text-transform: capitalize;
        }
      }
      &.rejected{
        background-color: $v2-success-25;
        p{
          text-align: center;
          @include text_xs(medium,$v2-warning-900);
          text-transform: capitalize;
        }
      }
      &.testApproved{
        p{
          text-align: center;
          @include text_xs(medium, #333333);
          text-transform: capitalize;
        }
      }
      &.testReady{
        p{
          text-align: center;
          @include text_xs(medium, #333333);
          text-transform: capitalize;
        }
      }
  
      img{
        height:14px;
        width:14px;
        margin-right: 4px;
        margin-top: 1px;
      }
    }
    
    .actions {
      width: 160px;
      > * {
      display: inline-flexbox;
      margin-right: 10px;
      }
      .btnRenderer{
        padding: 1px;
      }
    }

    // .grid-Column {
    //   .actions {
    //     width: 160px;
  
    //     > * {
    //       display: inline-flexbox;
    //       margin-right: 10px;
    //     }
        // .btnRenderer{
        //   padding: 1px;
        // }
    //   }
      
    // }

    .rejectButton {
      color: $white;
      background-color: $fliptRed;
    }
  
    .approveButton {
      color: $white;
      background-color: #6F42C1;
    }
  
    .publishButton {
      color: $white;
      background-color: #28A745;
    }
  }
}
