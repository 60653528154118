.modal-pw {
    &-content {
        display: flex;
        flex-direction: column;
    }

    &-header {
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }

    &-input {
        margin-bottom: .625rem;

        &-error {
            border: 1.5px solid #FB0505;
        }

        &-success {
            border: 1.5px solid #48B84D;

        }
    }
    &-update {
        animation: fadeIn 1s ease-in-out forwards;
        padding: 1rem 0;
        width: 100%;
        text-align: center;
        font-weight: 600;
        border: 1px solid #4183c4;
        background-color: #c8e1fa;
    }
    &-error {
        &-message {
            color: #FB0505;
            font-size: .9rem;
            animation: fadeIn 1s ease-in-out forwards;
            padding: .2rem .5rem .5rem .5rem;
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .25rem;
        box-sizing: border-box;

        &-selector-checkbox {
            // margin-top: .0225rem;
            background-color: #FB0505;
            color: #FFFFFF;

            &:checked {
                background-color: #FB0505;
                color: #FFFFFF;
            }
        }

        &-section {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;

            &-buttons {
                &-cancel {
                    cursor: pointer;
                    background-color: inherit;
                    color: #FB0505;
                    margin-right: 1rem;
                    font-weight: 600;
                    padding: .7rem 1rem;
                    max-width: 4.6875rem;

                    &:hover {
                        background-color: #FB0505;
                        color: #FFF;
                        transition: all 0.5s ease-in-out;
                    }
                }

                &-confirm {
                    cursor: pointer;
                    background-color: #4183c4;
                    ;
                    color: #FFF;
                    font-weight: 600;
                    padding: .7rem 1rem;
                    max-width: 4.6875rem;
                    transition: color 0.5s, background-color 0.5s, text-decoration 1s;
                    &-disabled {
                        cursor: not-allowed;
                        background-color: #4183c4;
                        color: #FFF;
                        font-weight: 600;
                        padding: .7rem 1rem;
                        max-width: 4.6875rem;
                    }
                    &:hover {
                        max-width: 4.6875rem;
                        background-color: inherit;
                        // border: #4183c4 0.0212rem solid;
                        text-decoration: underline;
                        color: #4183c4;

                        // transition: all 0.5s ease-in-out;
                        transition-delay: 0.2s, 0.2s, 1s;
                    }
                }
            }
        }

    }
}

.fade-in {
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
