@import '../../../../styles/colors.scss';

#Phases {
    text-align: left;

  

      .section-header{
        font-weight: bold;
      }

      .display-info-container{
        display: flex;
        flex-direction: column;
      }

      .fields-container {
        border:  1px solid $grey-400 ;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 20px;
  
        > div {
          min-width: 22%;
        }
      }

      .fields{
          margin-left: 40px;
          margin-top: 30px;
          width: 22%;
      }
}