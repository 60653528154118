@import "../../../styles/colors.scss";

.upload-container {
	>.uploadInput {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 35px;

		.input-container {
			display: flex;
			flex-direction: column;

			.featured-icon {
				position: relative;
				left: 45%;
				width: 40px;
				height: 40px;
				background: #f2f4f7;
				border: 6px solid #f9fafb;
				border-radius: 28px;

				>i {
					position: relative;
					top: 20%;
					left: 10%;
				}
			}

			input {
				display: none;
			}

			label {
				cursor: pointer;

				.actionText {
					color: #6941c6;
				}
			}

			.helperText {
				font-family: "Nunito";
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 20px;
				color: #636363;
				margin-top: 10px;
			}
		}
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px 24px;
	background: #ffffff;
	border: 1px dashed #d0d5dd;
	border-radius: 8px;
	width: 106%;
	height: 115px;
}
