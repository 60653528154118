@import '../../../../styles/colors.scss';

#claimsProcessingAndReprocessingReview {
  padding: 20px;
  text-align: left;

  >.mainHeader {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .grid-container {
    height: 300px;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .accordian-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(234, 236, 240, 1);
    transition: all 0.5s linear;

    >div {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }

    .acc-inner-container {
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      >span {
        font-size: 14px;
        color: rgba(102, 112, 133, 1);
      }

      >p {
        font-size: 14px;
        font-weight: bold;
        color: rgba(29, 41, 57, 1);
        margin-top: 5px;
      }
    }

    >img {
      margin-right: 8px;
    }
  }

  .acc-header {
    font-size: 18px;
    font-weight: bold;
    margin-left: 8px;
  }

  .transition-div {
    transition: all 0.5s linear;
    .header {
      font-size: 18px;
      font-weight: bold;
    }
  }

}