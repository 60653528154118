@import "../../../styles/colors.scss";

#priceCheck {
  padding: 20px;
  text-align: left;

  .price-inputs {
    display: flex;
    flex-flow: column;
    width: 22%;
    max-width: 25%;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;

    span {
      color: #636363;
      font-size: 0.7em;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  input {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
  }

  .details-section {
    display: flex;
    margin-bottom: 15px;
    background: #f0f0f0;

    input {
      background: #f0f0f0;
      border: 1px solid #000;
      outline: none;
    }

    .price-inputs {
      background: #f0f0f0;
    }
  }

  .subtitle {
    font-size: 0.9em;
    font-family: "Nunito";
    font-weight: 700;
    margin-bottom: 20px;
    display: inline-block;
    color: #848885;
  }

  .price-intputs-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .spacing {
    padding: 20px;
  }

  .border-line {
    border: 1px solid #c0c3c8;
  }

  .drug-list-wrap.spacing {
    padding: 0px;
  }

  .drug-list-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;

    .spacing {
      padding: 10px;
    }

    .drug-details {
      width: 28%;
    }

    .pharmacy-list {
      width: 70%;
      overflow: auto;
      padding: 0 5px;
    }
  }

  section {
    margin-bottom: 25px;
  }

  .drug-input {
    width: 100%;
    margin-bottom: 20px;

    span {
      color: #636363;
      font-size: 0.7em;
      font-weight: bold;
      margin-bottom: 5px;
      display: block;
    }

    .ui {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }

  .sort-wrap {
    span {
      display: inline-block;
      font-weight: 700;
      font-size: 12px;
      color: #848885;
    }

    .sort-list {
      border: none;
    }
  }

  .title-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 5px;

    ::after {
      content: "";
      position: absolute;
      top: 100%;
      height: 1px;
      width: 103%;
      left: -10px;
      background: #666a67;
      display: none;
    }
  }

  .pharmacy-list {
    .subtitle {
      margin-bottom: 0px;
      display: flex;
      align-items: center;
    }
  }

  .drugSearchBtn {
    font-size: 11px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .popup-wrap {
    position: fixed;
    z-index: 3;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }

  .popup-block {
    width: 750px;
    padding: 50px 20px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    h1 {
      font-size: 15px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 40px;
      color: #000;
    }

    span {
      font-size: 14px;
      display: block;
      margin-bottom: 50px;
    }

    .popup-caption {
      color: #a7a9a7;
      position: relative;
      display: inline-block;
      margin-bottom: 0px;
      padding-bottom: 25px;
      font-size: 12px;
      border-bottom: 1px solid #a7a9a7;
    }

    .popup-subheading {
      padding-top: 25px;
    }

    .changes-btn {
      display: block;
      background: transparent;
      margin: 20px auto 20px;
      border: none;
      outline: none;
    }

    .confirmButton {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .price-block {
    width: 600px;
    position: relative;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 10px;
      font-size: 22px;
      color: #666a67;
    }

    .price-table-wrap {
      width: 263px;
      margin: auto;
      padding-top: 0px;

      span {
        margin-bottom: 20px;
      }

      .sub-content {
        margin-left: 20px;
      }

      .price-content {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        .toggle-icon {
          color: rgba(0, 0, 0, 0.87);
          font-size: 10px;
          left: -10px;
          position: relative;
          margin-left: -16px;
        }

        .price-title {
          color: rgba(0, 0, 0, 0.87);
          font-size: 11px;
          margin-bottom: 0px;
        }

        .price-text {
          color: #000;
          font-weight: 700;
          font-size: 12px;
          margin-bottom: 0px;
        }

        .info-icon {
          display: inline-block;
          padding-left: 5px;
        }
      }
    }
  }

  .table {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

    thead {
      tr {
        position: relative;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

        th {
          background: transparent;
          border: none;
          color: rgb(102, 106, 103);
          font-size: 12px;
          color: #a8a8ac;
        }
      }
    }

    tbody {
      tr {
        border: none;

        &:nth-child(even) {
          background: $grey-200;
        }

        &:nth-child(odd) {
          background: $white;
        }

        td {
          border: none;
        }

        td:first-child {
          color: #fb0505;
          font-weight: 700;
          width: 35%;
        }

        td:last-child {
          font-weight: 700;
          color: #000;
        }
      }
    }
  }

  .ui.table {
    margin-top: 0px !important;
  }

  .shadow {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  }

  i.icon.info.circle:before {
    color: #bcbabe;
  }

  .info.circle {
    display: inline-block;
    padding-left: 15px;
  }

  .bg-color {
    background: #f0f0f0;
  }

  .pharmacy-table {
    height: 403px;
    overflow-y: auto;
  }

  .pointer-events {
    pointer-events: none;
  }

  .price-inputs.dob .close {
    display: none;
  }

  h2.modal_header {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 30px;
  }

  .selection.dropdown {
    text-transform: capitalize !important;
  }

  .ui.search>.results {
    font-size: 12px;
    font-weight: bold;
  }

  .ui.table tbody>tr>td {
    font-size: .7em;
  }
}