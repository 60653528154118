@import '../../../../styles/colors.scss';

#denialModal {
    height: 100%;
    width: 100%;

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        align-items: baseline;
        caret-color: transparent;
    }

    .header-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
    }

    .rfi-header-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
    }

    .field-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 280px;
        padding: 0px 20px 20px 20px;
    }

    .field-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .field-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .doc-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }

    .doc-list-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        padding: 0px 20px;

        :first-child {
            margin-top: 0px;
        }
    }

    .message-area {
        width: 100%;
        height: 60px;
    }

    .doc-checkbox-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }

    .doc-read-container {
        display: flex;
        flex-direction: row;
        padding: 20px;
    }

    .read-text {
        font-weight: 500;
        font-size: 14px;
        color: #344054;
        margin-left: 8px;
    }

    .doc-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 20px 15px 20px;
        background: #F2F4F7;
        border-radius: 8px;
        margin-left: 5px;
        color: #101828;
        font-weight: 500;
        font-size: 14px;
    }

    .input-class-med {
        width: 250px;
    }

    .rfi-button-container {
        display: flex;
        margin-left: 20px;
        justify-content: center;

        .cancel {
            margin-left: 20px;
        }
    }

    .devider {
        background: #EAECF0;
        height: 1px;
        margin: 14px;
    }

    .rfi-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .rfi-field-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 160px;
        width: 100%;
    }

    .communication-method {
        display: flex;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .queue-message-container {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
    }
}
