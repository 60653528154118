@import '../themes/theme';
@import '../utilities/mixins';
// @import '../themes/theme';
// @import '../themes/theme';


.logo-login {
   
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
    margin: 0px;
    width: 100px;
    height: 80px;
}

.logo-header {
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
    margin-top: 45px;
    margin-left: 20px;
    width: 70px;
    height: 50px;
}

.logo-container {
    display:flex;
    padding: 10px;
  }

:root .flipt{
    @include spread-map($flipt-colors);
   
}


// :root .flipt {
// 	@include spread-map($flipt-colors);
	
// 	.logo-login {
// 		background-image:url('../../styles/flipt/logo-login.png');
// 		background-repeat: no-repeat;
// 		background-size: contain;
// 		padding: 10px;
// 		margin: 0px;
// 		width: 100px;
// 		height: 80px;
// 	}

// 	.logo-header {
// 		background-image:url('../../styles/flipt/logo-header.png');
// 		background-repeat: no-repeat;
// 		background-size: contain;
// 		padding: 10px;
// 		margin-top: 45px;
// 		margin-left: 20px;
// 		width: 70px;
// 		height: 50px;
// 	}
// }