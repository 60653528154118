@import '../../../../styles/colors.scss';

#questionnaireModal {
    margin: 8px;

    .header {
        color: #101828;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }

    .header-text {
        color: #98A2B3;
        font-size: 16px;
        font-weight: 500;
    }

    .devider {
        background: #EAECF0;
        height: 1px;
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .denial-icon-container {
        margin-left: 5px;
    }

    .message-area {
        width: 100%;
        height: 60px;
    }

    .input-class {
        width: 250px;
    }

    .input-class-med {
        width: 150px;
    }

    .input-class-min {
        width: 100px;
    }

    .questionnaire-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 400px;
    }

    .radio-container {
        display: flex;
        flex-direction: row;
        caret-color: transparent;
    }

    .radio-container-left {
        display: flex;
        flex-direction: row;
        margin-left: 40px;
        caret-color: transparent;
    }

    .field-container {
        display: flex;
        justify-content: space-around;
    }

    .answer-container {
        margin-bottom: 20px;
        margin-top: 10px;
        width: 100%;
    }

    .error-message {
        color: red;
        font-size: 14px;
        margin-top: 16px;
    }

    .margin-bottom-container {
        margin-bottom: 20px;
    }

    .checkbox-container {
        display: flex;
        caret-color: transparent;
    }

    .checkbox-container-left {
        display: flex;
        margin-left: 30px;
        caret-color: transparent;
    }

    .checkbox-label {
        color: #101828;
        font-size: 14px;
        font-weight: 600;
        margin-left: 5px;
    }
}