@import '../../../../../styles/colors.scss';

#claimTransactionPricing {
  padding: 20px;
  text-align: left;

  > .header {
    display: flex;
    align-items: baseline;

    > h1 {
      border-right: 1px solid $grey-600;
      margin-right: 20px;
      padding-right: 20px;
    }

    > div {
      display: inline-block;
      padding: 0;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  > .content {
    margin: 10px;
    overflow: hidden;

    .section {
      h2 { 
        background-color: $grey-800;
        color: $white;
        display: inline-block;
        font-size: 16px;
        padding: 5px 20px;
      }

      border-bottom: 2px solid $grey-600;
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      &.grid {
        justify-content: start;
        margin-bottom: 10px;

        .fields {
          &:first-child {
            width: 10%;

            > * {
              padding: 8px;
            }
          }

          .title {
            padding: 8px;
            margin-bottom: 0;
          }

          .spacer {
            background-color: $white;
          }

          > * {
            margin-right: 10px;
          }
          
          width: 20%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
        
      }


      // .fields {
      //   width: 33%;

      //   > div {
      //     padding-left: 10px;
      //     background-color: $grey-400;

      //     &:nth-child(2n) {
      //       background-color: $grey-200;
      //      }
      //   }
      // }
    }

    .client-fields {
      > div {
        margin-bottom: 10px;
    }
  }

    .field-label {
      height: 33px;
      font-size: 0.9em;
      width: 20%;
      padding-top: 10px;
    }
  }
}

.section {
  // border-bottom: 2px solid $grey-600;
  margin-top: 20px;
  padding-bottom: 10px;

  .container-fields {
    display: flex;
    flex-direction: column;

    &.grid {
      justify-content: start;
      margin-bottom: 10px;

      .fields {
        .title {
          padding: 8px;
          margin-bottom: 1px;
          background-color: #D4D4D5;
          width: 20%;
          font-size: 16px;
          font-weight: 600;
        }

        .spacer {
          background-color: $white;
          width: 20%;
        }

        > * {
          margin-right: 10px;
        }
        
        // width: 20%;
        display: flex;
        // justify-content: space-between;
        flex-direction: row;
      }

      .fields {
        // width: 33%;

        .field-label {
          padding-left: 10px;
          background-color: $grey-400;
          margin-top: 1px;
        }

        .fields-empty {
          background-color: $white;
          width: 20%;
        }

        > input {
          background-color: #F9F9F9;
          color: #000000;
          cursor: not-allowed;
          font-weight: 300;
          width: 20%;
        }

        .field-label-light {
          background-color: $grey-200;
        }
      }
    }
  }

}