@import '../../../../styles/colors.scss';

#PACaseTab {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 20px;

    // .header {
    //     font-weight: bold;
    //     font-size: 20px;
    //     line-height: 20px;
    //     color: #000000;
    // }

    // .outer-container {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     margin-top: 20px;
    // }

    // .diagnosis-container {
    //     display: flex;
    //     flex-direction: row;
    //     margin-top: 20px;
    // }

    // .member-inner-field-container {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    //     height: 180px;
    // }

    // .inner-field-container {
    //     display: flex;
    //     flex: 1;
    //     flex-direction: column;
    //     justify-content: start;
    //     gap: 20px;
    //     margin-right: 24px;
    //     height: auto;
    // }



    // .button-container {
    //     margin-top: 20px;
    // }

    // .button-text-container {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     align-items: center;
    // }

    .diagnosis-description {
        flex: 2 0 0;
    }

    // .margin-on-left {
    //     margin-left: 80px;
    // }

    .not-found-container {
        display: flex;
        align-self: flex-start;
        width: 180px;
        height: 40px;
        background: #F2F4F7;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .not-found-text {
        color: #E63324;
        font-weight: 500;
        font-size: 14px;
    }

    // .note-container {
    //     margin-top: 20px;
    //     display: flex;
    //     justify-content: flex-start;
    //     flex-direction: column;
    // }

}
