@import "../../../../../styles/colors.scss";

#assignPharmaciesContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  height: 500px;
}

#createEdits {
  padding: 20px;
  text-align: left;

  >.header {
    .headerContiner {
      display: flex;
      flex-direction: row;
      width: 100%;

      .headerText {
        padding: 0 0 0px 12px;
        width: 50%;
      }

      .headerAction {
        width: 50%;
        flex-direction: row-reverse;
        display: flex;

        >.addBtn {
          margin-left: 70px;
          background: #fff;
          color: red;
        }
      }
    }
  }

  >.content {
    margin: 10px;
    padding-right: 10px;

    .section {
      .tier-inputs-container {
        margin-top: 12px;
        margin-bottom: 20px;
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

        .tier-row {
          display: flex;

          .preferred-checkbox-container {
            display: flex;
            align-items: center;
            width: 25%;
            color: #636363;
            font-size: 0.9em;
            font-weight: bold;
          }

          .checkbox-label {
            margin-left: 8px;
          }

          .no-grow {
            flex-grow: 0 !important;
          }

          .tier-inputs,
          .pa-stylized {
            display: flex;
            flex-flow: column;
            margin-right: 1.5em;
            flex-grow: 1;
            margin-bottom: 15px;

            span {
              color: #636363;
              font-size: 0.9em;
              font-weight: bold;
              margin-bottom: 5px;
            }

            input {
              border: 1px solid rgba(34, 36, 38, 0.15);
              border-radius: 0.28571429rem;
            }

            .create-tier-start-date {
              width: 100%;
              height: 100%;
            }

            .field {
              height: 100%;
            }
          }
        }
      }

      #assignPharmacyContent {
        padding: 10px;
      }

      .grid-header {
        margin: 12px;
        font-size: 14px;
        font-weight: bold;
      }

      .margin-bottom {
        margin-bottom: 100px;
      }

      .header {
        .headerContiner {
          display: flex;
          flex-direction: row;
          width: 100%;

          .headerText {
            padding: 0 0 0px 12px;
            width: 50%;
          }

          .headerAction {
            width: 50%;
            flex-direction: row-reverse;
            display: flex;

            >.addBtn {
              margin-left: 70px;
              background: #fff;
              color: red;
            }
          }
        }
      }

      .grid-container {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        height: 350px;

        >.addRowButtonContainer {
          padding: 9px 0;
          margin: 0 auto;
          background: #f0f0f0;
          max-width: 100%;
          text-align: center;

          >* {
            margin-left: 0%;
            background: #f0f0f0;
            color: #fb0505;
          }
        }

        .pa-conditions {
          >.ag-theme-alpine {
            .ag-root-wrapper-body.ag-layout-normal {
              min-height: 133px;
            }
          }
        }
      }
    }

    .section-drugs {
      min-height: 600px;

      .section-contents-drugs {
        .filtersContainer {
          >* {
            margin-right: 10px;
          }
        }

        .filtersGrid {
          overflow: auto;
          max-height: 300px;
        }
      }
    }
  }

  .buttonContainer {
    margin-top: 50px;
    text-align: center;

    >* {
      margin-right: 40px;
    }

    padding: 30px 0px 40px 0px;
  }

  .assignPharmacy {
    .pa-condition-levels-accordion {
      width: 100%;

      .pa-conditions {
        height: 250px;

        >.ag-theme-alpine {
          > {
            height: 50vh;
          }
        }
      }
    }

    .addRowButtonContainer {
      padding: 9px 0;
      margin: 0 auto;
      background: #f0f0f0;
      max-width: 100%;
      text-align: center;

      >* {
        margin-left: 0%;
        background: #f0f0f0;
        color: #fb0505;
      }
    }

    .buttonContainer {
      margin-top: 50px;
      text-align: left;

      >* {
        margin-right: 40px;
      }

      padding: 30px 0px 40px 0px;
    }
  }
}

.saveTierModal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    >*:first-child {
      margin-right: 10px;
    }
  }
}