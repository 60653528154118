.family-details .fields-container .fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  > * {
    width: 30%;
  }

  > input[type="button"] {
    width: 10%!important;
  }
}