@import  '../../styles/colors.scss';

.error-container {
    display: flex;
    background-color: $errorWhite;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    .error-box {
        text-align: center;
        .error-image {
            height: 500px;
            width: 500px;
            padding-bottom: 20px;
        }
        .error-text {
            font-size: 24px;
            line-height: 32px;
            color: $black;
            font-family: $v2-primary-font-family;
            padding-top: 10px;
            padding-bottom: 20px;
        }
        .error-subheader {
            font-size: 16px;
            line-height: 24px;
            color: $black;
            font-family: $v2-primary-font-family;
            padding-bottom: 20px;
        }
        .support-email {
            font-size: 16px;
            line-height: 24px;
            color: $black;
            font-family: $v2-primary-font-family;
            padding-bottom: 20px;
            &:hover {
                color: $fliptRed;
                text-decoration: underline;
            }
        }
    }
}