@import "../../../../styles/colors.scss";

#batch-manual-claims {
  >.batch-input-header {
    text-align: center;
    margin: 1em 0em;
    display: block;
  }

  .csv-grid {
    height: 300px;
  }

  >.csv-buttons-container {
    display: flex;
    justify-content: center;

    .buttons {
      border-radius: 3px;
      height: 50%;
      margin: 0.5em 0em 0.5em 1.5em;
      ;
    }
  }

  >.status-string {
    display: flex;
    justify-content: center;
  }
}
