@import "../../../../../styles/colors.scss";

#createStepTherapy {
  padding: 20px;
  text-align: left;

  .breadCrumbContainer {
    display: flex;
    flex-direction: row;

    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;

      span {
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }

  >.content {
    margin: 10px;
    padding-right: 10px;

    .section {
      .step-therapy-inputs-container {
        margin-bottom: 20px;

        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

        >.step-therapy-inputs {
          display: flex;
          flex-flow: column;
          margin-right: 1.5em;
          flex-grow: 1;
          margin-bottom: 15px;

          span {
            color: #636363;
            font-size: 0.9em;
            font-weight: bold;
            margin-bottom: 5px;
          }

          input {
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
          }

          .create-step-therapy-start-date {
            width: 100%;
            height: 100%;
          }

          .field {
            height: 100%;
          }
        }
      }

      .grid-container {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        height: 350px;

        .ag-theme-alpine .ag-root-wrapper {
          overflow: initial;
        }

        >.addRowButtonContainer {
          margin-top: 15px;
          max-width: 90%;

          >* {
            margin-left: 50%;
          }
        }
      }

      .buttonContainer {
        >* {
          margin-right: 40px;
        }

        padding: 60px 0px 40px 0px;
      }

      .grid-container-target-drugs {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        height: 400px;
        margin-bottom: 15px;
        // padding: 0px 10px 0px 0px;
      }

      .grid-container-step-therapy-levels {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        margin-top: 30px;

        >.add-step-therapy-level {
          margin-top: 15px;
          text-align: right;
        }

        >.step-therapy-levels {
          // outline: 1px solid black;
          overflow: auto;
          max-width: 100%;
          margin-top: 15px;
          padding: 0px 10px 10px 10px;

          .step-therapy-level-accordion {
            width: 100%;

            .step-therapy-level {
              .step-therapy-level-delete {
                padding: 0px 0px 10px 50px;
              }

              .step-therapy-level-conditions {
                // outline: 1px solid red;
                overflow: auto;
                height: 350px;
                margin-top: 5px;
                padding: 0px 0px 10px 0px;
              }

              .step-therapy-level-button-container {
                >.step-therapy-level-add-cond-button {
                  // outline: 1px solid blue;
                  margin-left: 50%;
                }
              }

              .step-therapy-condition-level-qualifier {
                // outline: 1px solid red;
                // overflow: auto;
                display: flex;
                flex-direction: column;
                margin-top: 15px;
                padding: 0px 0px 10px 0px;
                width: 100%;

                >.step-therapy-sub-level-criteria-container {
                  overflow: auto;
                  height: 350px;

                  padding: 10px 0px;
                }

                >.step-therapy-qualifier-level-add-cond-button {
                  // outline: 1px solid blue;
                  display: flex;
                  justify-content: center;
                  // margin-top: 15px;
                  // margin-left: 50%;
                }
              }

              .step-therapy-level-criteria {
                margin-top: 15px;

                .step-therapy-level-lookback-criteria {
                  display: flex;
                  flex-wrap: wrap;

                  >.step-therapy-level-criteria-inputs {
                    display: flex;
                    flex-flow: column;
                    margin-right: 1.5em;
                    flex-grow: 1;
                    margin-bottom: 15px;

                    span {
                      color: #636363;
                      font-size: 0.9em;
                      font-weight: bold;
                      margin-bottom: 5px;
                    }

                    input {
                      border: 1px solid rgba(34, 36, 38, 0.15);
                      border-radius: 0.28571429rem;
                    }

                    .step-therapy-level-criteria-inputs-dropdown {
                      // outline: 1px solid black;
                      height: 80%;
                    }

                    .field {
                      height: 100%;
                    }
                  }
                }

                .asterik::before {
                  content: "*";
                  color: red;
                }

                .step-therapy-level-criteria-drug-list {
                  display: flex;
                  flex-wrap: wrap;

                  >.step-therapy-level-criteria-drug-list-inputs {
                    display: flex;
                    flex-flow: column;
                    margin-right: 1.5em;
                    flex-grow: 1;
                    margin-bottom: 15px;

                    span {
                      color: #636363;
                      font-size: 0.9em;
                      font-weight: bold;
                      margin-bottom: 5px;
                    }

                    .step-therapy-level-criteria-drug-list-inputs-dropdown {
                      // outline: 1px solid black;
                      height: 100%;
                      width: 40%;
                    }

                    .field {
                      height: 100%;
                    }
                  }
                }
              }

              .step-therapy-level-drugs {
                // outline: 1px solid red;
                overflow: auto;
                height: 350px;
                margin-top: 50px;
                padding: 0px 10px 10px 0px;
              }
            }
          }
        }
      }
    }
  }
}

.step-therapy-split {
  display: flex;
  flex-direction: row;
}


.copy-object {
  position: absolute;
  top: 22%;
  background-color: white;
  max-width: 60% !important;
  right: 12%;
  box-shadow: 0px 3px 20px !important;
  max-height: 60vh !important;

  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid #c0c3c8;

  >.step-therapy-inputs {
    display: flex;
    flex-flow: column;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;

    span {
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 15px;
      margin-right: 10px;
    }

    input {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      margin-right: 15px;
    }

    .create-step-therapy-start-date {
      width: 100%;
      height: 100%;
    }

    .field {
      height: 100%;
    }
  }

  padding: 15px;
  border: 1px solid #c0c3c8;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

  .buttonContainer {
    >* {
      margin-right: 40px;
    }
  }
}
