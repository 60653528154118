.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 12px;

    .heading {
      @include display_xs(medium,$v2-gray-900);
    }

    .header_menu {
      display: flex;
      justify-content: space-between;
      .searchContainer {
        height: 40px;
        // width: auto;
        width: 52px;
        margin: 0px 12px;
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid $v2-gray-300;
        background: $white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
      .filterContainer {
        height: 40px;
        margin: 0px 12px;
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid $v2-gray-300;
        background: $white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        p {
          @include text_sm(medium,$v2-gray-700);          
        }
      }

      // need to modified based on common buttons styling

      .buttonContainer {
        margin-top: -7px;
      }
      .button {
        height: 40px;
        border-radius: 8px;
        border: 1px solid $v2-blue-700;
        background: $v2-gray-700;;
        @include text_sm(medium,$color: $white);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  } 