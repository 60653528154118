@import '../../../../styles/colors.scss';
@import '../../../../styles/menuCard.scss';

#companyManagement {
  padding: 20px 20px 75px 20px ;
  text-align: left;

  > .header {
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 20px 0 20px 0;

    > .search {
      flex-grow: 4;
      height:40px;
      text-align: right;
    }
  }


  .buttonContainer {
    max-width: 95%;
  }

  .content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 450px;
    max-width: 95%;
    max-height: 550px;
  }
}
