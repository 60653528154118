#flipt-tooltip-input {
    &.tooltip-input-wrapper {
        width: calc(25% - 20px);
        max-width: calc(25% - 20px);

        &-row {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 20vw;
            height: 45px;
        }
    }

    .tooltip-input {
        border-radius: 5px;
        border-color: #b3b3b3;
        border-style: solid;
        border-width: .05px;
        width: 100%;

        &.dropdown {
            padding:10px;
            height: 56px;
        }

        &.chuncky {
            height: 56px;
        }

        &.padded {
            padding: 10px;
        }
    }

    .tooltip-info-container {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 700;
        font-size: 14px;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .tooltip-icon {
        color: grey;
    }
}