
@import "../../../../styles/colors.scss";
#CreateQuestionnaire {
    text-align: left;
    margin-bottom: 50px;
    padding: 20px;

    .submenu {
        align-items: baseline;
        display: flex;
        // margin top and bottom

        margin: 20px 0 5px 0;

        > h1 {
        border-right: 1px solid $grey-800;
        margin-right: 20px;
        padding-right: 20px;
        }

        > div {
        display: inline-block;
        padding: 0;

        a {
            border-right: 1px solid $fliptRed;
            display: inline;
            font-size: 13px;
            margin-right: 10px;
            padding-right: 10px;

            &.active {
            color: $fliptRed;
            }
        }
        }
    }
    > .header {
        caret-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        color: #333;
    }

    .content {
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
        margin: 10px;
        padding: 20px;

        .fields-main-container {
            display: flex;
            flex-direction: column;
        }

        .fields-inner-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 16px;
        }

        .buttons-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 40px;

            .cancelButton {
                margin-left: 20px;
                font-size: 14px;
            }
        }
    }
}