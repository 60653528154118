@import "../../../styles/colors.scss";

#userQueue {
  padding: 50px;
  text-align: left;

  .header {
    align-items: baseline;
    display: flex;
    text-align: left;

    >h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }

    >div {
      padding: 0;
      display: block;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  .content {
    display: block;
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 450px;
    max-width: 100%;
    max-height: 550px;
  }

  .rejectButton {
    color: $white;
    background-color: $fliptRed;
  }

  .approveButton {
    color: $white;
    background-color: green;
  }

  .publishReadyButton {
    color: $white;
    background-color: $trueBlue;
  }
}