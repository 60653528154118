@import '../../../../styles/colors.scss';

#contractPrice {
    padding: 20px;
    text-align: left;
    overflow: auto;

    >.content {
        border-top: 2px solid $grey-200;
        margin-top: 10px;
        overflow: auto;
        height: 300px;
        max-width: 95%;
        margin-bottom: 40px;

        .action {
            >* {
                background-color: $gallery;
                margin-right: 1px;
                padding: 5px 10px;
            }
        }
    }

    .clearButton {
        font-size: 14px;
        min-width: 100px;
    }

    .button-container {
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .primary {
            margin-right: 10px;
        }

    }

    .actions {
        text-align: left !important;
    }

    .header {
        display: flex;
        // padding: 30px;
    }

    .horizontal-fields {
        width: 100%;
        display: flex;
        gap: 20px;
        margin-top: 20px;

        >input {
            margin-right: 20px;
        }

        >div {
            margin-right: 20px;

            >div.menu {
                overflow-y: scroll;
            }
        }
    }
}
