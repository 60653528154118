@import '../../../styles/colors.scss';

#questionnaireBuilder {
    display: flex;
    flex-direction: column;

    padding: 20px;
    text-align: left;

    >.header {
        caret-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        color: #333;

        .create-pa-case {
            display: flex;
            align-items: center;
            color: $fliptRed;
            font-size: 70%;
        }

        .create-pa-case:hover {
            cursor: pointer;
        }
    }

    .content {
        height: 360px;
    }
}