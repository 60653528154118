@import "../../../styles/colors.scss";

div.manual-claim-adjustment-container {
  flex-direction: row;
  padding: 20px;
  text-align: left;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  hr {
    margin-bottom: 50px;
  }
  .section {
    margin-bottom: 40px;
    margin-top:40px;
  }

  .section-header {
    align-items: baseline;
    color: $white;
      font-size: 18px;
      padding: 10px 20px;
      background-color: $grey-600;
      display: inline-block;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .fields {
      width: 30%;

      &.no-bg {
        > * {
          background-color: $white !important;
        }

        }
      
       

      > .no-bg {
        background-color: $white !important;
      }

      .field {
        padding:0px;
        padding-left:180px !important;
        max-height:35px;
        
      }

      > div {
        padding-left: 10px;
        background-color: $grey-400;
        margin-bottom: 10px; 

        &:nth-child(2n) {
          background-color: $grey-200;
        }
      
        
        > span {
          display: inline-block;
          width: 30%;
          padding: 10px;
          font-size: .70em;
          text-transform:uppercase;
        }

        input {
          width: 70%;
          
          
        }

      }
    }
  }
  
}