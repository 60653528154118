@import '../../../styles/colors.scss';
@import '../../../styles/themes.scss';

#appHeaderHomeInner {
  align-items: center;
  font-size: calc(10px + 2vmin);
  justify-content: space-between;
  font-family: $v2-primary-font-family;
  color: $v2-white;
  display: flex;
  transition: margin-left 0.3s ease;
  padding: 36px 5% 56px;
  flex-direction: column;
  width: 100%;
  background-color: var(--Primary-Red, #e63324); /* Fallback background color */
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left;
  height: 240px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;


  &::after {
    height: fit-content;
    flex-direction: column;
    width: 100%;
    filter: brightness(30%);
    z-index: 0;
  }

//   .overlay {
//     height: fit-content;
//     flex-direction: column;
//     width: 100%;
//     background-color: var(--Primary-Red, #e63324); /* Fallback background color */
//     background-image: url('../../../../public/i/red-header-bkgnd.jpg');
//     background-repeat: no-repeat;
//     background-size: 100% auto;
//     background-position: left;
//     filter: brightness(80%);
//     width: 100% ;
//     height: 100%;
//     z-index: 0;
//  }

  .main-title {
    font-size: 52px !important;
    line-height: 60px;
    letter-spacing: -2%;
    color: $v2-gray-50;
    height: auto;
    text-align: center;
    vertical-align: top;
    font-weight: 700;
    margin-top: 12px;
    z-index: 1;
  }

  .header-text {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    z-index: 1;


    .sub-heading-2 {
      font-weight: 700;
      line-height: 24px;
      font-size: 16px;
      height: auto;
      vertical-align: top;
      text-align: center;
      color: $v2-gray-25;
      filter: brightness(100%);
      z-index: 1;
    }

    .sub-heading-3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0%;
      height: auto;
      color: $v2-gray-50;
      text-align: center;
      vertical-align: top;
      margin-top: 24px;
      width: 900px;
      z-index: 1;
      
    }
  }
}


