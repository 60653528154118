@import '../../../../styles/colors.scss';

#accountDetails {
  background-color: $gallery;
  padding: 25px;
  margin-bottom: 35px;

  .form-renderer.content {
    overflow: auto;
  }

  header {
    font-size: 1.2em;
    padding-bottom: 20px;
    padding-top: 5px;
    text-align: left;

    span {
      color: $grey-800;
      margin-right: 20px;
      font-weight: bold;
    }

    span:first-child {
      color: $fliptRed;
    }

    span:last-child {
      font-weight: normal;
    }
  }
  .ui.tabular.menu {
    overflow: auto;

    .item {
      margin-right: 0.5em;
    }
  }

  .footer {
    display: flex;
    justify-content: center;

    .searchButton {
      margin: 1em;
    }
  }

  .section {
    overflow: auto;
  }
}
