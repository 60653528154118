@import '../../../../../../styles/colors.scss';

#addressSection {
  border-bottom: $fliptRed 1px solid;
  margin-bottom: 1em;

  .address-list {
    color: #666a67;
    font-size: 90%;
    padding: 1em 0em;
  }

  .fields {
    padding: 1em 1em 1em 0em;
  }

  input[type="text"] {
    margin: 0em 1em 1em 0em;
  }

  .address-buttons {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1em;
    padding-right: 1em;
  }
}

#phoneNumberSection {
  border-bottom: $fliptRed 1px solid;
  margin-bottom: 1em;

  .phone-number-list {
    color: #666a67;
    font-size: 90%;
    padding: 1em 0em;
  }

  .fields {
    padding: 1em 1em 1em 0em;
  }

  input[type="text"] {
    margin: 0em 1em 1em 0em;
  }

  .header-section {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .phone.fields {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .sub-header {
    color: #666a67;
    font-size: 80%;
    padding-left: 1em;
  }
}

#emailSection {
  border-bottom: $fliptRed 1px solid;
  margin-bottom: 1em;

  .email-list {
    color: #666a67;
    font-size: 90%;
    padding: 1em 0em;
  }

  .fields {
    padding: 1em 1em 1em 0em;
  }

  .email.fields {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

#vipSection {
  .email-list {
    color: #666a67;
    font-size: 90%;
    padding: 1em 0em;
  }

  .fields {
    padding: 1em 1em 1em 0em;
  }

  .email.fields {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.button-container {
  display: flex;
  font-size: 80%;

  .separator {
    margin: 0em 1em 0em 1em;
  }

  .button {
    color: $fliptRed;
    cursor: pointer;
  }
}