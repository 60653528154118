@import '../../../../styles/colors.scss';

.formulary_copay {
  margin-bottom: 15px;


  .section-tiers {
    overflow: auto;
    margin-top: 15px;
    padding: 0px 1px 10px 1px;

    .section-tiers-accordion {
      width: 100%;

      .section-tier-level {
        .content {
          max-width: 100% !important;
          padding: .5em 0em 0em;
        }

        .section {
          .copay-dropdowns-container {
            margin-bottom: 20px;

            display: flex;
            flex-wrap: wrap;
            padding: 15px;
            border: 1px solid #c0c3c8;
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

            >.deductible-dropdown {
              .flipt-label {
                width: 100%;
              }
            }

            >.copay-dropdowns {
              display: flex;
              flex-direction: column;
              margin-right: 1.5em;
              flex-grow: 1;
              margin-bottom: 15px;

              span {
                color: #636363;
                font-size: 0.9em;
                font-weight: bold;
                margin-bottom: 5px;
              }

              input {
                border: 1px solid rgba(34, 36, 38, 0.15);
                border-radius: 0.28571429rem;
              }

              .field {
                height: 100%;
              }

              .flipt-label {
                width: 100%;
              }
            }
          }

          .grid-container {
            >.addRowButtonContainer {
              display: flex;
              justify-content: center;

              button {
                margin-left: 0;
              }

              margin-top: 15px;
              max-width: 90%;

              >* {
                margin-left: 50%;
              }
            }
          }
        }

      }
    }
  }
}