@import '../../../styles/colors.scss';

#DrugPriceLookup {
  padding: 20px;
  text-align: left;

  .header {
    display: flex;
  }

  >.content {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;

    h2 {
      color: $white;
      font-size: 18px;
      padding: 10px 20px;
      background-color: $grey-800;
      display: inline-block;
    }

    .section {
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .grid-container {
      min-height: 600px;
      height: 600px;
      text-align: left;
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .fields {
        width: 33%;

        &.button-container {
          align-items: center;
          display: flex;
          margin: 10px 0 0 0;
        }

        .stylized {
          display: block;

          .flipt-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 3px;
          }

          .dropdown {
            display: block;
          }

          .ui.search {
            width: 100%;

            .ui.icon {
              width: 100%
            }
          }

        }

        input[name="drug_name"] {
          margin-top: 5px;
        }

        >div {
          padding-left: 10px;
          background-color: $white;
          margin-bottom: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;

      >* {
        margin-right: 10px;
        width: 20%;
      }
    }
  }
}