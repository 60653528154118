@import "../../../../styles/colors.scss";

#BenefitLimitation {
  padding: 20px;
  text-align: left;

  .checkbox {
    padding: 0 10px;
  }

  .header {
    display: flex;
  }

  .network-warning-message-container {
    display: flex;
    height: 30px;
    justify-content: left;
    align-items: center;
    margin-left: 10px;
    font-size: 14px;
    color: red;
  }

  .addRowButtonContainer {
    margin-top: 15px;
    max-width: 90%;

    >* {
      margin-left: 50%;
    }
  }

  >.grid-container {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;
    height: 450px;
    max-width: 100%;
    max-height: 550px;

    h2 {
      color: $white;
      font-size: 18px;
      padding: 10px 20px;
      background-color: $grey-800;
      display: inline-block;
    }

    .section {
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .fields {
        width: 33%;

        >div {
          padding-left: 10px;
          background-color: $grey-400;
          margin-bottom: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;

      >* {
        margin-right: 10px;
        width: 20%;
      }
    }
  }

  >.plan-sponsor-cap-section {
    .checkbox {
      margin-bottom: 25px;
    }

    >.plan-sponsor-cap-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    >.grid-container {
      overflow: auto;
      margin: 10px;
      padding-right: 10px;
      height: 450px;
      max-width: 100%;
      max-height: 550px;

      h2 {
        color: $white;
        font-size: 18px;
        padding: 10px 20px;
        background-color: $grey-800;
        display: inline-block;
      }

      .section {
        margin-top: 20px;
        padding-bottom: 10px;
      }

      .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;

        .fields {
          width: 33%;

          >div {
            padding-left: 10px;
            background-color: $grey-400;
            margin-bottom: 10px;

            &:nth-child(2n) {
              background-color: $grey-200;
            }
          }
        }
      }

      .buttons-container {
        display: flex;
        justify-content: center;

        >* {
          margin-right: 10px;
          width: 20%;
        }
      }
    }
  }
}
