@import '../../../../../styles/colors.scss';

#nxTransactions {
    padding: 20px;
    text-align: left;

    >.header {
        align-items: baseline;
        display: flex;

        >h1 {
            border-right: 1px solid $grey-800;
            margin-right: 20px;
            padding-right: 20px;
        }

        >div {
            display: inline-block;
            padding: 0;

            a {
                border-right: 1px solid $fliptRed;
                display: inline;
                font-size: 13px;
                margin-right: 10px;
                padding-right: 10px;

                &.active {
                    color: $fliptRed;
                }
            }
        }
    }

    .inner-header {
        font-size: 18px;
        font-weight: bold;
        margin-top: 12px;
    }

    .grid-header {
        font-size: 14px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .grid-container {
        height: 150px;
    }

    .last {
        margin-bottom: 40px;
    }
}