@import "../../../../../styles/colors.scss";
@import "../../../../../globalStyles/index.scss";

#pharmacyNetworkLookupData {
  padding: 20px 20px 20px 30px;
  text-align: left;

  >.content {
    margin: 10px;
    padding-right: 10px;

    .ag-root-wrapper-body.ag-layout-normal {
      height: 500px;
    }
  }
}