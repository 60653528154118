#claim-override-section {
  > .content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 450px;
    max-width: 85%;
    max-height: 550px;
    .actions {
      width: 160px;
      > * {
      display: inline-block;
      margin-right: 10px;
      }
      }
  }
} 