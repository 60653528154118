@import "../../../../styles/colors.scss";
@import "../../../../globalStyles/index.scss";

.networkTierCreationManagement {
  padding: 20px;
  text-align: left;


  .content {
    margin: 24px 0px;
    
    padding-right: 10px;

    .grid-Column {
      .actions {
        text-align: left !important;
        a.link {
          @include text_sm($color:$v2-gray-500);
        }
      }
    }
  }
}
