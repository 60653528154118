@import "../../../../styles/colors.scss";


#clientImplementationManagement {
  padding: 20px 20px 20px 30px;
  text-align: left;

  .implementation-header {
    padding: 20px 5px 5px 30px;
    height: 10%;
    width: 95%;
    display: inline-block;

    > h1 {
      border-right: 1px solid $grey-800;
      font-size: x-large;
    }
  }

  .implementation-details {
    padding: 5px 5px 5px 5px;
    height: 5%;
    width: 95%;
    display: inline-block;

    .hierarchy-class {

      display: flex;
      flex-wrap: wrap;
      width: 20%;
      padding: 15px;
      flex-grow: 1;
      display: inline-block;

      span {
          color: #636363;
          font-size: 0.9em;
          font-weight: bold;

      }
    }
  }

  // .section {
    
  //   .donut-chart-container{
  //     padding: 20px 20px 20px 30px;
  //     height: 400px;
  //     max-width: 100%;
  //     max-height: 100%;

  //   }

  //   .tier-inputs-container {
  //     margin-bottom: 20px;

  //     display: flex;
  //     flex-wrap: wrap;
  //     padding: 15px;
  //     border: 1px solid #c0c3c8;
  //     box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

  //     >.tier-inputs {
  //       display: flex;
  //       flex-flow: column;
  //       margin-right: 1.5em;
  //       flex-grow: 1;
  //       margin-bottom: 15px;

  //       span {
  //         color: #636363;
  //         font-size: 0.9em;
  //         font-weight: bold;
  //         margin-bottom: 5px;
  //       }

  //       input {
  //         border: 1px solid rgba(34, 36, 38, 0.15);
  //         border-radius: 0.28571429rem;
  //       }

  //       .create-tier-start-date {
  //         width: 100%;
  //         height: 100%;
  //         text-decoration: none;
  //       }

  //       .field {
  //         height: 100%;
  //       }
  //     }
  //   }
  // }
  .header {
    align-items: baseline;
    display: flex;
  
    > h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }
    
  
    /* Added styles for button */
    > button {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;
      font-size: 13px;  /* Adjust to match font size of header */
      margin-left: auto;  /* Aligns button to the right */
      padding: 5px 10px;
    }
  
    > div {
      display: inline-block;
      padding: 0;
  
      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;
  
        &.active {
          color: $fliptRed;
        }
      }
    }
  }
  

  .buttonContainer {
    max-width: 95%;
  }

  .content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    padding-left: 10px;
    height: 500px;
    max-width: 100%;
    max-height: 100%;

    .ag-row .ag-cell {
      display: flex;
      justify-content: center; /* align horizontal */
      align-items: center;
    }
  }
}