@import "../../../../styles/colors.scss";

#um-file-import {
  padding: 20px;
  text-align: left;

  .header {
    align-items: baseline;
    display: flex;

    >h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }

    >div {
      display: inline-block;
      padding: 0;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  >.content {
    margin: 10px;
    padding-right: 10px;

    .section {
      .outTableContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);

        >.tableContainer {
          height: 100%;
          width: 100%;

          .mapper-container {
            width: 100%;
            display: flex;
            flex-direction: column;

            input {
              width: 100%;
            }

            .mapHeaders {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              margin-bottom: 10px;
              padding: 10px;
              border-radius: 5px;
            }

            .divider-tab {
              background: $v2-gray-200;
              height: 1px;
              align-self: stretch;
              margin-top: 10px;
              margin-bottom: 10px;
            }

            .conditions-option-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 8px;

              .conditions-options-label {
                font-family: $v2-primary-font-family;
              }
            }
          }

          .gridOfContainer {
            height: 500px;
            width: 100%;
          }
        }
      }

      .uploadInputContainer {
        >.uploadInput {
          padding: 20px;

          border: 1px dashed $black;
          margin: 10 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          margin-bottom: 20px;
          background-color: #f0f0f0;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
      }

      .formulary-inputs-container {
        // outline: 1px solid red;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);


        >.formulary-inputs {
          // outline: 1px solid red;
          display: flex;
          flex-flow: column;
          // width: 10px;
          // max-width: 25%;
          margin-right: 1.5em;
          flex-grow: 1;
          margin-bottom: 15px;

          span {
            color: #636363;
            font-size: 0.9em;
            font-weight: bold;
            margin-bottom: 5px;
          }

          input {
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
          }

          .create-formulary-tier-list {
            height: 100%;
            z-index: 2;
          }

          .create-formulary-start-date {
            width: 100%;
            height: 100%;
          }

          .field {
            height: 100%;
          }
        }
      }

      .generateFormularyButtonContainer {
        input {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 0.28571429rem;
          font-size: 14px;
          padding-right: 20px;
          padding-left: 20px;
        }
      }

      .section-contents {
        // outline: 1px solid green;
        overflow: auto;
        // min-height: 250px;
        // height: 350px;
        // max-height: 350px;
        // max-width: 95%;
        margin-top: 15px;
        padding: 0px 1px 1px 1px;

        .section-tier-levels-accordion {
          // outline: 1px solid black;
          width: 100%;

          .section-tier-level {

            // outline: 1px solid black;
            .section-tier-conditions {
              // outline: 1px solid blue;
              overflow: auto;
              // min-height: 250px;
              height: 350px;
              // max-height: 30px;
              // max-width: 95%;
              margin-top: 5px;
              // padding: 0px 0px 10px 0px;
            }
          }
        }
      }

      .break {
        flex-basis: 100%;
        height: 0;
      }

      .buttonContainer {
        justify-content: center;
        width: 100%;
        display: flex;
        gap: 40px;
      }
    }
  }
}
