@import "../../../../../styles/colors.scss";

#ProductionClaimSearch {
  text-align: left;
  .build-criteria {
    .build-criteria-upload {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .checkbox-label {
            color: $v2-gray-900;
            font-weight: bold;
            font-size: 18px;
        }
        .radio-text {
            margin-top: 12px;
            margin-left: 8px;
     
        }
        .radio-container {
            display: flex;
            flex-direction: row;
            width: 50%;
            justify-content: space-between;
            margin-top: 12px;
        }
        .file-upload-container {
            display: flex;
            flex-direction: row;
            padding: 12px;
            border: 1px solid #D0D5DD;
            border-radius: 12px;
            width: 60%;
            margin-bottom: 12px;
            cursor: pointer;
            .inner-file-upload-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 5px;
                .inner-text {
                    font-size: 16px;
                    color: #6941C6;
                }
                .inner-sub-text {
                    font-size: 14px;
                    margin-top: 5px;
                }
            }        
        }
        .file-upload-container-overlay {
            background-color: #EAECF0;
            .inner-file-upload-container {
                .inner-text {
                    color: #B4A0E2;
                }
                .inner-sub-text {
                    color: $doveGray;
                }
            }
        }
    }
    .build-criteria-grid {
        display: flex;
        flex-direction: column;
    }
    .production-claims-grid {
        display: flex;
        flex-direction: column;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  .claim-grid-height {
    height: 450px;
}


}