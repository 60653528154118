$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 0%);

// gray variants
$v2-gray-25: hsl(240, 20%, 99%); //#fcfcfd
$v2-gray-50: hsl(210, 20%, 98%); //#f9fafb
$v2-gray-100: hsl(216, 24%, 96%);//#f2f4f7
$v2-gray-200: hsl(220, 17%, 93%);//#eaecf0
$v2-gray-300: hsl(217, 16%, 84%);//#d0d5dd
$v2-gray-400: hsl(218, 15%, 65%);//#98a2b3
$v2-gray-500: hsl(221, 13%, 46%);//#667085
$v2-gray-600: hsl(216, 18%, 34%);//#475467  
$v2-gray-700: hsl(218, 24%, 27%);//#344054
$v2-gray-800: hsl(214, 33%, 17%);//#1d2939
$v2-gray-900: hsl(220, 43%, 11%);//#101828

// error variants

$v2-error-25: hsl(12, 100%, 99%);//#fffbf2
$v2-error-50: hsl(5, 86%, 97%);//#fef3f2
$v2-error-100: hsl(4, 93%, 94%);//#fee4e2
$v2-error-200: hsl(3, 96%, 89%);//#fecdca
$v2-error-300: hsl(4, 96%, 80%);//#fda29b
$v2-error-400: hsl(4, 92%, 69%);//#f97066
$v2-error-500: hsl(4, 86%, 58%);//#f04438
$v2-error-600: hsl(4, 74%, 49%);//#d92d20
$v2-error-700: hsl(4, 76%, 40%);//#b42318
$v2-error-800: hsl(4, 72%, 33%);//#912018
$v2-error-900: hsl(8, 65%, 29%);//#7a271a

// warning variants

$v2-warning-25: hsl(42, 100%, 98%);//#fffcf5
$v2-warning-50: hsl(45, 100%, 96%);//#fffaeb
$v2-warning-100: hsl(45, 96%, 89%);//#fef0c7
$v2-warning-200: hsl(44, 98%, 77%);//#fedf89
$v2-warning-300: hsl(42, 99%, 65%);//#fec84b
$v2-warning-400: hsl(39, 98%, 56%);//#fdb022
$v2-warning-500: hsl(34, 94%, 50%);//#f79009
$v2-warning-600: hsl(28, 97%, 44%);//#dc6803
$v2-warning-700: hsl(22, 92%, 37%);//#b54708
$v2-warning-800: hsl(19, 84%, 31%);//#93370d
$v2-warning-900: hsl(18, 79%, 27%);//#7a2e0e

//success varaints

$v2-success-25: hsl(142, 80%, 98%);//#f6fef9
$v2-success-50: hsl(145, 81%, 96%);//#ecfdf3
$v2-success-100: hsl(140, 80%, 90%);//#d1fadf
$v2-success-200: hsl(144, 78%, 80%);//#a6f4c5
$v2-success-300: hsl(148, 74%, 67%);//#6ce9a6
$v2-success-400: hsl(150, 66%, 52%);//#32d583
$v2-success-500: hsl(152, 82%, 39%);//#12b76a
$v2-success-600: hsl(153, 96%, 30%);//#039855
$v2-success-700: hsl(155, 97%, 24%);//#027a48
$v2-success-800: hsl(155, 90%, 20%);//#05603a
$v2-success-900: hsl(156, 88%, 16%);//#054f31

//row blue varaints

$v2-blue-25: hsl(222, 100%, 98%);//#f5f8ff
$v2-blue-50: hsl(221, 100%, 97%);//#eff4ff
$v2-blue-100: hsl(220, 100%, 91%);//#d1e0ff
$v2-blue-200: hsl(220, 100%, 85%);//#b2ccff
$v2-blue-300: hsl(220, 100%, 76%);//#84adff
$v2-blue-400: hsl(220, 100%, 66%);//#528bff
$v2-blue-500: hsl(220, 100%, 58%);//#2970ff
$v2-blue-600: hsl(220, 87%, 51%);//#155eef
$v2-blue-700: hsl(220, 100%, 46%);//#004eeb
$v2-blue-800: hsl(220, 100%, 38%);//#0040c1
$v2-blue-900: hsl(220, 100%, 31%);//#00359e


$doveGray:#636363;
$frenchGray: #C0C3C8;
$gallery: #F0F0F0;
$grey-100: #F9F9F9;
$grey-200: #ECECED;
$grey-300: #DADADB;
$grey-400: #D4D4D5;
$grey-500: #C1C1C3;
$grey-600: #9d9da1;
$grey-800: #666A67;
$silverChalice: #A8A8A8;
$white: #FFFFFF;
$whiteSmoke: #f2f2f2;
$trueBlue: #0000ff;
$smokeBlue: #3662A2;


$fliptPrimaryColor:#FB0505;