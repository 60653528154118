@import '../../../../styles/colors.scss';

#Attachments {
    height: 100%;
    width: 100%;
    text-align: left;

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        align-items: baseline;
        caret-color: transparent;
    }

    .header-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
    }

    .main-container {
        display: flex;
        flex-direction: row;
    }

    .forms-letters {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .notes-container {
        display: flex;
        flex-direction: row;
    }

    .selected-background {
        background-color: $grey-200;
    }

    .selected-icon-background {
        background-color: white;
        border-radius: 50px;
        padding: 8px;
        height: 40px;
        width: 40px;
        margin: 5px;
        display: grid;
        margin-top: 6px;
    }

    .width-less {
        width: 35%;
    }

    .full-width {
        width: 100%;
    }

    .pdf-viewer {
        width: 65%;
        border: 25px solid;
        border-color: $grey-300;
    }

    .primary-button {
        background-color: $fliptRed;
        color: $white;
        height: 30px;
        font-size: 12px;
    }

    .notes-icon-container {
        background-color: $grey-200;
        border-radius: 50px;
        padding: 8px;
        height: 40px;
        width: 40px;
        display: grid;
        margin-top: 6px;
    }

    .notes-inner-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5px;
    }

    .notes-devider {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 1px;
        width: 100%;
        background-color: $grey-500;
    }

    .notes-devider-container {
        margin-left: 20px;
        width: 100%;
        margin-top: 8px;
    }

    .text-main-container {
        margin-top: 5px;
    }

    .header-container {
        text-align: left;
        font-weight: bold;
        font-size: large;
    }

    .text-container {
        text-align: left;
        font-size: small;
    }

    .date-time-container {
        text-align: left;
        font-size: x-small;
        color: $grey-800;
        margin-bottom: 5px;
    }

    .button-container {
        margin-right: 8px;
        align-items: center;
    }

    .upload-button-container {
        margin-right: 8px;
        justify-content: space-between;
        display: flex;
    }

    .download-button-container {
        margin-right: 8px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
    }

    .download-options,
    .document-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    th {
        font-weight: bold !important;
    }
}