@import '../../../styles/colors.scss';

#changePassword {
  .left {
    display: none;
  }
}

.left {
  align-items: center;
  background-color: $fliptRed;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Nunito','Avenir-Medium';
  font-weight:600;
  > * {
    margin-bottom: 10px;
  }
}

#changePasswordForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 50%;

  input {
    font-size: 1em;
    width: 100%;
  }

  input[type="button"] {
    width: 100%;

  }

  > * {
    display: block;
    margin-bottom: 15px;
  }
  
}

.right .header-text {
  font-size: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 600px) {
  #changePassword {
    .left {
      display: flex;
    }
  }
}