@import '../../../styles/colors.scss';

#impact-analysis {
  padding: 20px;
  font-family: $v2-primary-font-family;
  color: #101828;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  .impact-analysis-header {
    font-family: $v2-primary-font-family;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: $v2-gray-900;
    align-self: center;
  }

  .sub-header-container {
    display: flex;
    font-size: 18px;
    justify-content: center;
    gap: 16px;
    color: $v2-gray-900;
  }

  .form-container {
    display: flex;
    gap: 160px;
  }

  .submit-button-container {
    align-self: center;
  }

  .grid-container {
    height: 400px;
    width: 100%;

    .ag-theme-alpine {
      font-family: $v2-primary-font-family;
      font-size: 14px;
      font-weight: 400; // font-weight is not working
      color: #667085;

      .ag-root-wrapper {
        border: 1px solid #EAECF0;
        border-radius: 8px;
        box-shadow: $v2-sm-box-shadow;
      }

      .ag-row {
        height: 72px;
      }
    }
  }
}
