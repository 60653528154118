@import '../../../../styles/colors.scss';

#hierarchy-dashboard-tab {

  >.header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .content-tab {
    margin-top: 20px;
    .section-header {
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      text-transform: capitalize;
    }
    .section-sub-header {
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .fields-container { 
      margin-top: 10px;
      margin-bottom: 20px;
      .cycle-inputs {
        display: flex;
        flex-flow: column;
        margin-right: 1.5em;
        flex-grow: 1;
        margin-bottom: 15px;
        .cycle-start-date {
          width: 100%;
          height: 100%;
        }
        
      }
      .fields-client-header {
        width: 31.3%;
        margin-right: 20px;
      }
      .radio-outline {
        outline: 1px solid $frenchGray !important;
      }
      .picker{
        line-height: 12px;
        font-size: 14px;
        gap: 0px;
      }
      .fields-third-header {
        width: 65%;
        margin-right: 20px;
      }
      .fields-header {
        width: 48%;
        margin-right: 20px;
      }
      .field-input{
        width: 100%;
        outline: 1px solid $frenchGray;
      }
      .field-stylized{
        outline: 1px solid $gallery;
        padding: 1px;
      }
      input[type="radio"]{
        outline: 1px solid $frenchGray;
      }
      .field-center{
        width:28%;
       align-self: center;
      }
      .field-label-radio{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
      }
      .divide-half-radio {
        width: 50%;
        text-align: left;
      }
      .field-label{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        gap:10px;
        text-align: left;
        font-size: 14px;
        font-weight: 600;
      }
      .half-width{
        align-items: inherit;
        
      }
      .cycle_start_date {
        width: 100%;
        height: 100%;
      }
    }
   
  }
  .fields-btn-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
    // width: 100%;
    // position: relative;
}
legend {
  background-color: $grey-800;
  color: #fff;
  padding: 3px 6px;
  border:1px solid $v2-white;
}
fieldset{
  border: 1px solid $grey-800;
    margin-bottom: 5px;
    padding: 4px;
}
}
