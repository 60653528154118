@import '../../../../../styles/colors.scss';

#plan-picker-container {
	.plan-picker-small {
		min-height: 200px;
		height: 300px;
		text-align: left;
	}

	.plan-picker-medium {
		min-height: 200px;
		height: 600px;
		text-align: left;
	}

	.plan-picker-large {
		min-height: 200px;
		height: 900px;
		text-align: left;
	}

	.ag-theme-alpine {
		font-family: $v2-primary-font-family;
		font-size: 14px;
		color: #667085;

		.ag-root-wrapper {
			border: 1px solid #eaecf0;
			border-radius: 8px;
			box-shadow: $v2-sm-box-shadow;
		}

		.ag-row {
			height: 72px;
		}
	}
}
