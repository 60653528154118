@import '../../../../styles/colors.scss';


#utilizationMgmt {
    >span {
        font-size: 20px;
        font-weight: bold;
    }

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        >span {
            font-size: 16px;
            font-weight: bold;
            margin: 20px 0px 20px 0px;
        }
    }

    .field-container {
        display: flex;
        flex-direction: row;
        gap: 18px;
        margin-top: 20px;
    }


    .grid-container {
        height: 250px;

        .ag-theme-alpine {
            font-family: $v2-primary-font-family;
            font-size: 14px;
            font-weight: 400; // font-weight is not working
            color: #667085;

            .ag-root-wrapper {
                border: 1px solid #EAECF0;
                border-radius: 8px;
                box-shadow: $v2-sm-box-shadow;
            }

            .ag-row {
                height: 72px;
            }
        }
    }
}