@import "../../../../../../src/styles/colors.scss";

#approvalRule {
  height: 100vh;
  flex-direction: row;
  padding: 20px;
  text-align: left;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  hr {
    margin-bottom: 50px;
  }

  .section {
    margin-bottom: 40px;
  }

  h2 {
    align-items: baseline;
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .fields-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    width: 90%;

    >.fields {
      margin-right: 10px;
      width: 30%;

      .field {
        display: inline-block;
        max-width: 300px;
        min-width: 125px;
      }

      >div {
        background-color: $whiteSmoke;
        margin-bottom: 10px;
        max-height: 35px;


        &:nth-child(2n) {
          background-color: $grey-300;
        }

        .ui.selection.dropdown {
          border: 1px solid $grey-200;
          color: #000000;
          min-width: 300px;
        }

      }

      .ui.dropdown>.text {
        color: $black;

      }
    }
  }

  .grid-container {
    border: 1px solid #c0c3c8;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    height: 350px;

    >.addRowButtonContainer {
      border: 1px solid #c0c3c8;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 15px;
        width: fit-content;
      }
    }
  }

  .buttonContainer {
    >* {
      margin-left: 50px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }


  input {

    &[name="email_address"],
    &[type="email"] {
      text-transform: lowercase !important;
    }
  }

  .enclosing-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  h2 {
    flex-basis: 7%;
  }

  .create-rule-section {
    margin-bottom: 2rem;
    padding: 1rem;
    box-shadow: 2px 2px 2px 2px lightgrey;
  }

  .lower-section {
    display: flex;
    flex-direction: row;
    flex-basis: 60%;
    margin-top: 1rem;
  }

  .top-fields {
    display: flex;
  }

  .fields {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-right: 2rem;
  }

  .border {
    box-shadow: 2px 2px 2px 2px lightgrey;
    height: 91%;
  }

  .field-name {
    align-items: baseline;
    color: $grey-800;
    display: inline-block;
    font-size: 22px;
    padding: 10px 0px;
    font-weight: bolder;
  }

  .fields-label {
    font-weight: bold;
    color: $grey-800;
  }

  .fields-input {
    margin-bottom: 1rem;
    border: 1px solid $grey-400;
  }
}
