@import '../../../../styles/colors.scss';

#eligibilityLogs {
    text-align: left;
    padding: 20px;

    .grid-container {
        height: 65vh;
        margin-top: 20px;

        .ag-theme-alpine {
            font-family: $v2-primary-font-family;
            font-size: 14px;
            font-weight: 400; // font-weight is not working
            color: #667085;

            .ag-root-wrapper {
                border: 1px solid #EAECF0;
                border-radius: 8px;
                box-shadow: $v2-sm-box-shadow;
            }

            .ag-row {
                height: 72px;
            }
        }
    }
}