.find-case-section {
    .find-case-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-bottom: 5px;
    }

    .inner-container {
        padding: 20px;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .margin-top {
        margin-top: 16px;
    }

    .margin-right {
        margin-right: 10px;
    }

    .prescriber-buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .grid-container {
        min-height: 200px;
        height: 200px;
        text-align: left;
    }
}