@import '../../../../styles/colors.scss';

#claim-summary {
    padding: 20px;
    text-align: left;

    .header {
        display: flex;
        justify-content: flex-start;
        color: #101828;
        font-weight: bold;
        font-size: 22px;
        line-height: 38px;
    }

    .claim-summary-section {
        height: 400px;
        margin-bottom: 50px;

        .ag-theme-alpine {
            font-family: $v2-primary-font-family;
            font-size: 14px;
            font-weight: 400; // font-weight is not working
            color: #667085;

            .ag-root-wrapper {
                border: 1px solid #EAECF0;
                border-radius: 8px;
                box-shadow: $v2-sm-box-shadow;
            }

            .ag-row {
                height: 72px;
            }
        }
    }

    .claim-summary-container {
        margin: 2em 0em 2em 0em;
        display: flex;
        justify-content: center;

        .claim-summary-button {
            margin-right: 20px;
            margin-left: 20px;
        }
    }
}