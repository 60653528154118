@import "../../../../../styles/colors.scss";

#manualClaim {

    h2 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

}