#claim-config-upload-file {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .mapper-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .map-fields {
        align-self: center;
    }

    .upload-file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .upload-file__icon {
            font-size: 100px;
            color: #ccc;
        }

        .upload-file__text {
            font-size: 20px;
            color: #ccc;
        }
    }
}
