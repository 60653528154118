
@import "../../../styles/colors.scss", "../../../styles/themes.scss";

.leftNavigation {
  transition: 0.3s; 
  background-color: $v2-white;
  z-index: 1001;

  justify-content: space-between;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif
  sans-serif;
  font-weight: 500;

  font-size: 16px;
  color: var(--Gray-700, #344054);
  //line-height: 150%;
  //padding: 32px 16px 50px;

  border-radius: 6px;
  //margin-top: 4px;
  //padding: 8px 12px;

  width: 81px;
  height: 960px;
  border: 0 1px 0 0;
  border-color: #EAECF0;


  .logo {
    aspect-ratio: 1.33;
    object-fit: auto;
    object-position: center;
    width: 53px;
    align-self: center;
  }

  img {
    aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 24px;
  }

  .sidebarMenu {
    height: calc(100vh - 0px);

    justify-content: center;
    align-self: stretch;
    border-right: 1px solid #eaecf0;
    background-color: #fff;
    display: flex;
    padding-right: 29px;
    flex-direction: column;
    .ui.thin.left.sidebar,
    .ui.thin.right.sidebar {
      width: 126px;
      background: $v2-white;
      z-index: 1001;
    }

    .small-bound {
      display: flex;
      flex-direction: column;
      padding: 32px 24px 50px;
      // flex-wrap: nowrap;
      // align-items: center;

      .separate-upper-lower{
        margin-bottom: 80px;
      }
    }
    .logoContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px;
      gap: 2px;
      
      img {
        aspect-ratio: 1.33;
          object-fit: auto;
          object-position: center;
          width: 53px;
          align-self: center;
      }
      button {
        margin-bottom: 5px;
      }
    }

    .preferencesContainer {
      width: 40px;
      height: 168px;
      padding: 7px;
      border-radius: 9px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 13px;
      background: #f5f5f5;
      margin: auto;
      margin-top: 20px;
      .preferencesContainer_bottomSection {
        border-top: 1px solid #eaecf0;
        .icon {
          margin-top: 12px;
        }
      }
    }
    .pushable {
      overflow-x: visible;
    }
    .ui.sidebar {
      overflow-y: visible !important;
    }
    .pushable > .pusher {
      background: transparent;
    }
  }

  .icon-inActive {
    margin: 0 !important;
    //height: 50px;
    width: 50px;
    background: transparent;
    color: $v2-black;
    border-radius: 6px;
    align-self: center;
    padding: 13px;
    object-position: center;
  }

  .icon-inActive:hover {
    background: $fliptRed;
    color: $v2-white;
    align-self: center;
    transition: 0.3s;
    color: $v2-white;
    filter: contrast(2) brightness(8.5);
    .hoverClass {
      display: block;
      color: $v2-white;
    }
  }
  .icon-active {
    background: $fliptRed;
    color: $v2-white;
    align-self: center;
    filter: contrast(2) brightness(8.5);
  }

  .active {
    .icon-active {
      background: $fliptRed;
      color: $v2-white;
      align-self: center;
      filter: contrast(2) brightness(8.5);
    }
  }

  .icon-active:hover {
    .hoverClass {
      display: block;
      color: $v2-white;
    }
    filter: contrast(4) brightness(8.5);
  }

  .hoverClass {
    display: none;
    background: #f2f2f2;
    //color: #475467;
    color: $v2-white;
    padding: 8px;
    position: absolute;
    left: 80px;
    width: 241px;
    top: -10px;
    z-index: 1000;
    border-left: 1px solid #ccc;
  }

  .item-list {
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    //aspect-ratio: 1;
    //justify-content: center;
    padding-top: 6px;

    .icon-inActive {
      aspect-ratio: 1;
      object-position: center;
      width: 48px;
    }
  }

  .item-list-container:hover{
    color: $v2-white;
  }

  .item-list-container:hover + .hoverClass {
    display: block;
    color: $v2-white;
  }

  .hoverClass:hover {
    display: block;
    color: $v2-white;
  }

  .submenu-title {
    display: block;
    width: 100%;
    margin: 1rem;
    margin-top: 26px;
    margin-left: 5px;
    text-wrap: wrap;
    line-height: normal;
    color: var(--Primary-Red, #e63324);
    font: 18px/28px Inter, sans-serif;
    font-weight: 500;
  }

  .sidebarExtendedMenu {
    width: 321px;
    border-left: 0px solid transparent;
    .ui.thin.left.sidebar,
    .ui.thin.right.sidebar {
      width: 330px;
      background: $v2-white;
      padding-top: 12px;
      z-index: 1001;
    }

    .logoContainer {
      padding: 4px 9px;
    }
    .sidebar__Container {
    display: flex;
  img {
    margin-right: 2px;
  }
  p {
    margin: 10px;
    margin-top: 16px;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
  
    font-weight: normal;
    color: #475467;
  }

    }
    .sidebar__Container:hover {
      background: $fliptRed;
      color: $v2-white;
      align-self: center;
      border-radius: 6px;
      justify-content: left;
      color: $v2-white;
      width: 100%;
      img, p {
        color: $v2-white;
      }
    }
    .hoverClass {
      left: 280px;
      color: $v2-white;
    }

    .preferencesContainer {
      background: #f5f5f5;
      width: 310px;
      height: 132px;
      padding: 12px 0px 12px 0px;
      border-radius: 8px;
      margin: 8px;
      .icon {
        height: 24px;
        width: 24px;
        margin: 4px;
        cursor: pointer;
      }

      .preferencesContainer_topSection {
        display: flex;
        padding: 8px;
        border-bottom: 1px solid #eaecf0;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        
        .preferencesContainer_topSection__item {
          padding: 5px;
          width: 40%;
          margin-left: -13px;
        }
        .preferencesContainer_topSection__item__second {
          padding: 5px;
          border-left: 1px solid #eaecf0;
          width: 50%;
          text-align: center;
        }

        .item-container {
          display: flex;
          justify-content: center;
          p {
            margin-top: 5px;
            line-height: 20px;
          
            font-weight: normal;
            color: #475467;
          }
        }
      }

      .preferencesContainer_bottomSection {
        display: flex;
        // justify-content: space-between;
        padding: 7px;
        border: 0;
        .preferencesContainer_bottomSection__avatar {
          height: 46px;
          width: 46px;
          border-radius: 50%;
          background-color: #d9d9d9;
          margin-left: 10px;
        }

        .preferencesContainer_bottomSection__details {
          text-align: left;
          margin-top: 8px;
          margin-left: 10px;
          p {
            line-height: 20px;
          
            font-weight: normal;
            color: #475467;
          }
          span {
            color: #98a2b3;
          }
        }
        .preferencesContainer_bottomSection__logout {
          margin-left: 26px;
        }
      }
    }

    .preferencesContainer-open {
      background: #f5f5f5;
      width: 95%;
      height: 150px;
      padding: 12px 0px 12px 0px;
      border-radius: 8px;
      margin: 8px;
      .icon {
        height: 24px;
        width: 24px;
        margin: 4px;
        cursor: pointer;
      }

      .preferencesContainer_topSection {
        display: flex;
        padding: 8px;
        border-bottom: 1px solid #eaecf0;
        justify-content: space-around;

        .preferencesContainer_topSection__item {
          padding: 5px;
          width: 40%;
          margin-left: -25px;
        }
        .preferencesContainer_topSection__item__second {
          padding: 5px;
          border-left: 1px solid #eaecf0;
          width: 50%;
          text-align: center;
        }

        .item-container {
          display: flex;
          justify-content: center;
          p {
            margin-top: 5px;
            line-height: 20px;
          
            font-weight: normal;
            color: #475467;
          }
        }
      }

      .preferencesContainer_bottomSection {
        display: flex;
        // justify-content: space-between;
        padding: 7px;
        border: 0;
        .preferencesContainer_bottomSection__avatar {
          height: 46px;
          width: 46px;
          border-radius: 50%;
          background-color: #d9d9d9;
          margin-left: 10px;
        }

        .preferencesContainer_bottomSection__details {
          text-align: left;
          margin-top: 8px;
          margin-left: 10px;
          p {
            line-height: 20px;
          
            font-weight: normal;
            color: #475467;
          }
          span {
            color: #98a2b3;
          }
        }
        .preferencesContainer_bottomSection__logout {
          margin-left: 26px;
        }
      }
    }
  }

  .accordion {
    font-weight: 300;
    border-radius: 0;
    box-shadow: 2px 2px 2px 2px #fff;
    margin-left: 22px;
    text-align: left;
    height: 101vh;
    background: #fff;
    // width: 281px;
    padding: 0px 16px 0px 16px;
    line-height: normal;

    > a {
      display: block;
      padding: 8px 12px;
      border: none;
      border-left: 5px solid transparent;
      padding-left: 0.5rem !important;
      color: $v2-gray-600;
      font-size: 16px;
      margin-left: 5px;
      margin-top: 4px;

      &.active {
        color: var(--theme-primary);

        border-left: 5px solid var(--theme-primary);

        background: #f2f2f2;
      }
    }

    > a:hover {
      display: block;
      padding: 8px 12px;
      //color: var(--theme-primary);
      //border-left: 5px solid var(--theme-primary);
      background: #f2f2f2;
    }

    .title {
      padding: 8px 12px;
    
      font-weight: normal;
      font-size: 16px;

      &.active {
        background-color: var(--theme-primary);
        color: $white;
        border-top: 1px solid $white;
      }
    }

    .content {
      padding: 0 5% 0 5%;
      font-size: 14px;
    }
  }

  .toggleNav {
    transition: 0.3s;
    padding: 0 2px;
    margin-left: 50%;
    margin-top: 10px;
    position: absolute; 
    //transform: translateX(95%);
    //transform: translateY(25%);
    //background: #f2f4f7;
    width: 24px;
    height: 24px;
    // border-radius: 50%;
    // border: 1px solid $grey-300;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    // line-height: 24px;
    // border: none;

    // &:hover {
    //   background: var(--theme-primary);

    //   i {
    //     color: $white;
    //   }
    // }


    img {
      transition: 0.3s;
      transform: translate(-25px, -5px);
      width: 6px;
      height: 12px;
      top: 6px;
      left: 10px;
      border: 2px;
      border-color: #667085;
      position: relative;

    }
  }

  .toggleNav-close {
    transition: 0.3s;
    padding: 0 2px;
    margin-left: 80%;
    margin-top: 10px;
    position: absolute; 
    //transform: translateX(95%);
    //transform: translateY(25%);
    //background: #f2f4f7;
    width: 24px;
    height: 24px;
    // border-radius: 50%;
    // border: 1px solid $grey-300;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    // line-height: 24px;
    // border: none;

    // &:hover {
    //   background: var(--theme-primary);

    //   i {
    //     color: $white;
    //   }
    // }


    img {
      transition: 0.3s;
      width: 6px;
      height: 12px;
      top: 6px;
      left: 10px;
      border: 2px;
      border-color: #667085;
      position: relative;
      transform: translate(-27px, -5px);

    }
  }
}

