@import '../../../styles/colors.scss';

#PharmacyNetwork {
  padding: 20px;
  text-align: left;

  .header {
    align-items: baseline;
    display: flex;

    >h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }

    >div {
      display: inline-block;
      padding: 0;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  >.content {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;

    h2 {
      color: $white;
      font-size: 18px;
      padding: 10px 20px;
      background-color: $grey-800;
      display: inline-block;
    }

    .section {
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .fields {
        width: 33%;

        >div {
          padding-left: 10px;
          background-color: $grey-400;
          margin-bottom: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;

      >* {
        margin-right: 10px;
        width: 20%;
      }
    }
  }
}

#assignPharmacyContent {
  .results.transition {
    overflow: auto;
  }

  .ui.search>.results {
    overflow: auto;
  }
}

#pharmacy-list {
  .content.maxHeight {
    height: 570px;
    padding: 0;
  }
}