@import "../../../../styles/colors.scss";

#test-batch-details {
    padding: 20px;
    text-align: left;

    div.stylized {
        display: block;
        margin-left: 1.5em;

        >.field {
            padding-left: 0px;
        }

        >.flipt-label {
            color: $doveGray;
            font-size: 0.9em;
            font-weight: bold;
            padding-left: 0px;
            width: 100%;
        }

        >input {
            width: 100%;
            border: 0.5px solid $grey-200;
            border-radius: 3px;
        }
    }

    .header {
        display: flex;
        justify-content: flex-start;
        color: #101828;
        font-weight: bold;
        font-size: 18px;
        line-height: 38px;
    }

    .green-button {
        background-color: #039855;
        width: 80px;
        border-radius: 6px;
        color: white;
    }

    .red-button {
        background-color: $fliptRed;
        margin-left: 16px;
        width: 80px;
        border-radius: 6px;
        color: white;
    }

    .batch-container {
        margin-top: 40px;
    }

    .summary-grid-height {
        height: 150px;
    }

    .claim-grid-height {
        height: 350px;
    }

    .grid-section {
        overflow: auto;
        margin-bottom: 40px;
        margin-left: 1.5em;

        .ag-theme-alpine {
            font-family: $v2-primary-font-family;
            font-size: 14px;
            font-weight: 400; // font-weight is not working
            color: #667085;

            .ag-root-wrapper {
                border: 1px solid #EAECF0;
                border-radius: 8px;
                box-shadow: $v2-sm-box-shadow;
            }
        }
    }

    .checkbox-text {
        color: $doveGray;
        padding: 0em 3em 0em 0.5em;
    }

    .checkbox-section {
        display: flex;
        flex-direction: column;
        margin-left: 1.5em;

        >div {
            margin-top: 8px;
            align-items: center;

            >span {
                margin-left: 12px;
            }
        }
    }

    .reprocessing-details-section {
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 1em 0em 1em 0em;

        >div.stylized {
            flex-grow: 1;
        }
    }

    .button-header-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        margin-top: 20px;
    }

    .checkbox-label {
        color: $v2-gray-900;
        font-weight: bold;
        font-size: 18px;
        padding: 0em 3em 0em 1.2em;
    }
    .export-button {
        background-color: #039855;
        border-radius: 6px;
        color: white;
    }
}

#claims-processing-reprocessing-objects-modal {
    .modal-grid {
        height: 400px;
    }
    .modal-buttons {
        margin-top: 40px;
        .green-button {
            background-color: #039855;
            width: 80px;
            border-radius: 6px;
            color: white;
        }
    
        .red-button {
            background-color: $fliptRed;
            margin-left: 16px;
            width: 80px;
            border-radius: 6px;
            color: white;
        }
    }
    .checkboxes {
        .checkbox-label {
            color: $v2-gray-900;
            font-weight: bold;
            font-size: 18px;
            padding: 0em 3em 0em 1.2em;
        }
        .checkbox-section {
            display: flex;
            flex-direction: column;
            margin-left: 1.5em;
    
            >div {
                margin-top: 8px;
                align-items: center;
    
                >span {
                    margin-left: 12px;
                }
            }
        }
        margin-bottom: 10px;
    }

}