@import '../../styles/colors.scss';


#flipt-tab-bar {
  .tab-button {
    display: flex;
    background: #FFFFFF;
    font-family: $v2-primary-font-family;
    font-weight: 500;
    font-size: 14px;
    color: $v2-gray-700;
    border-radius: 0px;
    border: 1px solid $v2-gray-300;
    line-height: 20px;
    padding: 10px 16px;
  }

  .left-control {
    background-color: $v2-white;
    border: 1px solid $v2-gray-300;
    border-radius: 8px 0px 0px 8px;
    margin: 0px;
    padding: 10px 12px;

    .flipt-icon {
      margin-right: 0px;
    }

    &[disabled] {
      color: $v2-gray-200 !important;
      cursor: not-allowed;

      img {
        color: $v2-gray-200 !important;
      }
    }
  }

  .right-control {
    background-color: $v2-white;
    border: 1px solid $v2-gray-300;
    border-radius: 0px 8px 8px 0px;
    margin: 0px;
    padding: 10px 12px;

    .flipt-icon {
      margin-right: 0px;
    }

    &[disabled] {
      color: $v2-gray-200 !important;
      cursor: not-allowed;

      img {
        color: $v2-gray-200 !important;
      }
    }
  }

  .active-tab {
    background-color: $v2-gray-100;
    color: $v2-gray-800;
  }
}
