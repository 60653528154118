@import '../../../../../styles/colors.scss';

.member-card {
  display: flex;
  flex-direction: column;
  padding: 1em;
  display: flex;
  border: solid 1px #d4d4d5;
  margin-bottom: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.member-card:hover {
  background-color: #d4d4d5;
}

.modal-header {
  font-weight: bold;
  padding-bottom: 2em;
}

.main-row,
.secondary-row {
  display: flex;
}

.secondary-row {
  color: #9d9da1;
  font-size: 85%;
  width:100%;
}

.pipe {
  padding: 0em 0.75em;
}

.popup-width {
    height: 16em;
    width: 55em;
    padding-bottom: 3em;
}