@import '../../../../../styles/colors.scss';

#rolesModal {
  padding: 5px;

  > .header {
    border-bottom: 1px solid $fliptRed;
    display: flex;
    font-size: 1em;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;

    h1 {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  > .contents {
    text-align: center;

    h3 {
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }
}

#resetPasswordModal {
  .contents {
    display: flex;
    justify-content: space-between;

    div.stylized {
      width: 70%;
    }
  }  
}