.pharmacy-section-v2 {
    .pharmacy-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-bottom: 5px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        >div {

            .description-text {
                font-size: 14px;
            }

            .v2-flipt-input {
                line-height: 12px;
                font-family: 'Nunito';
                width: 280px;
            }

            .dropdown {
                line-height: 12px;
                font-family: 'Nunito';
                width: 280px;
            }
        }
    }

    .pharmacy-buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .primary {
            margin-right: 1rem;
        }
    }

    .grid-container {
        min-height: 200px;
        height: 200px;
        text-align: left;
    }

}