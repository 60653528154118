@import "../../../styles/colors.scss";

#clientImplementationManagement {
  padding: 20px 20px 20px 30px;
  text-align: left;

  .header {
    align-items: baseline;
    display: flex;
  
    > h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }
  
    /* Added styles for button */
    > button {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;
      font-size: 13px;  /* Adjust to match font size of header */
      margin-left: auto;  /* Aligns button to the right */
      padding: 5px 10px;
    }
  
    > div {
      display: inline-block;
      padding: 0;
  
      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;
  
        &.active {
          color: $fliptRed;
        }
      }
    }
  }
  

  .buttonContainer {
    max-width: 95%;
  }

  .content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    padding-left: 10px;
    height: 450px;
    max-width: 95%;
    max-height: 100%;
  }
}