@import '../../../styles/colors.scss';

#manualClaimEntry {
  padding: 20px;
  text-align: left;

  >.header {
    display: flex;
    justify-content: flex-start;

    >.batch-input-button {
      margin-left: 1em;
      border-radius: 3px;
      height: 50%;
      align-self: center;
      margin-left: auto;
    }
  }

  >.content {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;

    h2 {
      align-items: baseline;
      background-color: $grey-800;
      color: $white;
      display: inline-block;
      font-size: 18px;
      padding: 10px 20px;
    }

    .section {
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .fields {
        width: 50%;
        margin-right: 20px;

        >div {
          padding-left: 10px;
          background-color: $grey-400;
          margin-bottom: 10px;

          &:nth-child(2n) {
            background-color: $grey-200;
          }

          .primary {
            padding: 5px 10px;
            margin-left: 10px;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;

      >* {
        margin-right: 20px;
        margin-bottom: 50px;
        width: 20%;
      }
    }
  }

  .test-main-container {
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin: 10px;
    margin-bottom: 30px;

    .test-claim-header {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .search-container {
      display: flex;
      justify-content: space-around;

      .search-separator {
        width: 1px;
        background-color: rgba(0, 0, 0, 0.25);
      }

      .margin-top {
        margin-top: 20px;
      }

      .mini-header {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .margin-left {
        margin-left: 5px;
      }

      .icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .validated-text {
        margin-top: 8px;
        font-size: 13px;
        color: #21ba45;
        font-weight: 800;
      }

      .not-validated-text {
        margin-top: 8px;
        font-size: 13px;
        color: $fliptRed;
        font-weight: 800;
      }


      .step-header {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        color: $fliptRed;
      }

    }

    .section-header {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      margin-left: 10px;
    }

    .horizontal-separator {
      margin: 40px 10px 40px 10px;
      height: 1px;
      width: 98%;
      align-self: center;
      background-color: rgba(0, 0, 0, 0.25);
    }

    .shadow {
      box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);

    }

    >.content {
      margin: 10px;
      padding: 20px;
      margin-top: 40px;
      margin-bottom: 50px;



      .section-header {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }

      .less-margin-right {
        margin-right: 8px;
      }

      .fields-container {
        margin-top: 10px;
        margin-bottom: 20px;

        .fields-header {
          width: 25%;
        }

        .less-margin-right {
          margin-right: 8px;
        }

        div.phone[role='listbox'] {
          width: 200px;
        }

        .less-margin-right {
          margin-right: 8px;
        }

        .margin-right {
          margin-right: 40px;
        }
      }

    }

    >.response-content {
      overflow: auto;
      padding: 0px 0px 20px 20px;

      .section-header {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }

      .fields-container {
        align-items: flex-end;

        .fields-header {
          width: 25%;
        }

        .input-class-med {
          width: 400px;
        }

        div.phone[role='listbox'] {
          width: 200px;
        }

        .margin-right {
          margin-right: 40px;
        }

      }

    }
  }
}

.manual-claim-entry-approval-modal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  input[type='text'] {
    padding: 15px;
  }

  input[type='text'],
  >div.field {
    width: 50%;

    >div.field,
    div.ui.icon {
      width: 100%;
    }
  }

  input[type='button'] {
    width: 125px;
  }

  >* {
    align-items: center;
    margin-bottom: 10px;
  }

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    >*:first-child {
      margin-right: 10px;
    }
  }
}
