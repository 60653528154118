@import '../../../../styles/colors.scss';

#PADetails {
  padding: 20px;
  text-align: left;

   .header {
    display: flex;
  }
  > .content {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;
    height: 550px;
    max-width: 85%;
    max-height: 550px;
    .actions {
      width: 160px;
      > * {
      display: inline-block;
      margin-right: 10px;
      }
    }
  }

}