@import "../../../../styles/colors.scss";

#approvalModal {


  .paModal label{
    // margin-right: 8px;
    font-weight: bold;
    padding: 8px;
  }

  .paModal .field{
    display: flex;
  }
  
  .content {
    text-align: center;
    padding: 0 20%;

    h3 {
      font-weight: 700;

    }

    span.line {
      display: block;
  
      height: 2px;
      margin: 20px auto;
      background-color: $grey-200;
    }

    .subheading {
      font-weight: 600;

      span {
        text-decoration: underline;
      }
    }

    span {
      color: $fliptRed;
    }
  }

  .pa-modal-date-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
  }
  
  .pa-moda-date-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }
  
  .pa-moda-date-selector-text {
    width: 70px;
  }
  
  #pa-modal.date-picker {
    padding-left: 10px;
  }
  
}