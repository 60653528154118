@import '../../../../../styles/colors.scss';

#member-info-container {
  align-items: flex-start;
  background: $v2-gray-25;
  border-radius: 8px;
  border: 1px solid $v2-gray-200;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;

  .member-sub-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    gap: 24px;
    flex-shrink: 0;

    .member-name-id-container {
      caret-color: transparent;
      display: flex;
      flex-direction: column;
      gap: 22px;
      min-width: 254px;

      .member-avatar-name-container {
        align-items: center;
        display: flex;
        gap: 12px;

        .name-container {
          align-items: center;
          align-self: stretch;
          color: $v2-gray-900;
          display: flex;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }

        .avatar-background {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 500px;
          min-width: 72px;
          min-height: 72px;

          .avatar-initials {
            font-size: 32px;
            color: $v2-gray-200;
          }
        }
      }

      .id-container {
        align-items: flex-start;
        margin-right: auto;
        border-radius: 8px;
        border: 1px solid $v2-gray-300;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        display: flex;
        font-size: 16px;
        gap: 12px;
        // justify-content: space-between;
        line-height: 24px;
        padding: 12px;

        .id-text {
          color: $v2-gray-500;
        }

        .id-number {
          font-weight: 500;
          color: #101828;
          line-height: 24px;
        }

        .copy-icon {
          cursor: pointer;
        }
      }
    }

    .member-info-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      font-size: 16px;

      .field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .field-header {
        color: $v2-gray-500;
      }

      .field-text {
        color: $v2-gray-700;
      }
    }
  }
}
