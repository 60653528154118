@import "../../../styles/colors.scss";
// DROPDOWN

.v2-flipt-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 1 0 0;
  gap: 6px;

  .flipt-label {
    font-family: $v2-primary-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $v2-gray-700;
  }

  div.ui.selection.dropdown {
    border-radius: 8px;
    border: 1px solid $v2-gray-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: $grey-800;
    font-family: $v2-primary-font-family;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 14px;
    width: 100%;
           height: 100%;


    .text {
      font-weight: normal;
    }
  }
}

// .selection.dropdown .item {
//   text-transform: capitalize !important;
// }

// .selection.dropdown.emails {
//   text-transform: none !important;
// }

// .selection.dropdown.emails .item {
//   text-transform: none !important;
// }

div.dropdown {
  width: auto;
  position: relative;

  >div.menu.transition {
    max-height: 15rem !important;
    width: auto;
    overflow-y: auto !important;
  }
}

// div.dropdown[role="listbox"] {
//   color: $grey-800;
//   font-size: 12px;

//   div[role="option"] {
//     font-size: 12px;

//     >i {
//       display: inline;
//     }

//     >span {
//       display: inline-block;
//     }
//   }
// }
