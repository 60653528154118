@import "../../../../../styles/colors.scss";

#BasicInformation {
	margin: 10px;
	padding: 24px;

	background: #FFFFFF;
	/* Gray/200 */
	border: 1px solid #EAECF0;
	box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
	border-radius: 8px;
	.fields-main-container {
		display: grid;
		grid-gap: 16px;
		width: 100%;
	}

	.fields-inner-container {
		display: flex;
		flex-direction: row;
		// justify-content: space-evenly;
		width: 100%;
		align-items: flex-start;
		div {
			margin-right: 24px;
			flex-grow: 1;
			.description-input {
				height: 63px;
				padding-top: 0;
			}
			input {
				width: 100%;
			}
		}
		.container {
			display: flex;
			flex-direction: column;
			width: 100%;

			.sub-container {
				display: flex;
				flex-direction: row;
				margin-bottom: 16px;
			}
		}
	}
}
