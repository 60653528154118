#rejectModal {
    >.content {
        margin: 10px;
        overflow: auto;

        .section-header {
            font-size: 16px;
            font-weight: bold;
            text-align: left;
        }

        .less-margin-right {
            margin-right: 8px;
        }

        .fields-container {
            margin-top: 10px;
            margin-bottom: 20px;

            .fields-header {
                width: 25%;
            }

            .less-margin-right {
                margin-right: 8px;
            }

            .margin-right {
                margin-right: 40px;
            }
        }

    }
}