@import '../../../styles/colors.scss';

#Contact {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 100%;
  font-family: inherit;
  font-style: normal;
  margin: 1% 0;
  overflow-x: auto;
  margin: 12px;
  color: #424242;
  vertical-align: baseline;
  font-size: 1em !important;
  justify-content: space-between;
  box-sizing: inherit;

  
  .section {
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .section-header {
      font-weight: 700;
      font-size: 20px;
      line-height: 35px;
      flex-direction: row;
      color: #101828;
    }

      span {
        margin-top: 5px;
      }
      
      a {
          color: #4183c4;
          cursor: pointer;
      }
      
      li {
          list-style: inside;
          padding: 8px 0px;
          font-size: .95em;
          font-weight: 600;
      }

      .link-description {
        margin: 8px 0;
        font-weight: 700;
        line-height: 1.5;
        font-size: 15.5px;
      }

}