.multi-prescriber-section {
    .prescriber-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }

    .inner-container {
        padding: 20px;
        border-radius: 4px;
        border: solid 1px #bcbabe;
        margin-bottom: 20px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .prescriber-not-found-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-self: center;
    }

    .prescriber-not-found-header {
        color: #101828;
        font-weight: 500;
        font-size: 24px;
        margin-top: 20px;
    }

    .prescriber-not-found-text {
        color: #475467;
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 12px;
    }

    .prescriber-buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .primary {
            margin-right: 1rem;
        }
    }

    .add-prescriber-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        margin-bottom: 20px;
    }

    .add-prescriber {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .add-outer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 200px;
        margin-bottom: 20px;
        width: 70%;
    }

    .add-inner-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .grid-container {
        min-height: 200px;
        height: 200px;
        text-align: left;
    }
}