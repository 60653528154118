#drugUtilizationReview {
  height: 1200px;

  .breadcrumb {
    display: flex;
    padding: 10px;
  }

  .header {
    text-align: left;
    margin-left: 12px;
  }

  input[disabled] {
    background-color: #f9f9f9;
    color: #666a67;
    cursor: not-allowed;
  }

  #add-condition-button {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .addButton {
    margin-right: 12px;
  }

  .agree-checkbox {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .grid-container {
    margin: 1.5rem;
    height: 450px;
  }

  .react-sortable-tree-container {
    height: 275px;
    min-height: 275px;
    width: 100%;
    overflow: auto;
  }

  .splitPlane {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .leftPlane {
    width: 50%;

    .stylized {
      flex-direction: column;
      padding: 0 15px;

      .flipt-label {
        width: auto;
        padding-left: 0;
        font-size: 12px;
        text-align: left;
      }

      .ui.selection.dropdown {
        width: 80%;
      }

      .field {
        display: flex;
        padding-left: 0;
      }
    }

    .stylized.column {
      flex-direction: column;
      padding: 0 15px;

      .row {
        display: flex;
        flex-direction: row;

        .stylized {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;

          .flipt-label {
            font-size: 1em;
          }
        }
      }

      .flipt-label {
        width: auto;
        padding-left: 0;
      }

      .ui.selection.dropdown {
        width: 80%;
      }
    }
  }

  .rightPlane {
    width: 50%;
    height: 350px;
    overflow: hidden;

    .error {
      height: 10px;
      color: red;
      font-size: 10px;
      display: flex;
      padding: 14px 0;
    }

    .treeSearch {
      .stylized {
        flex-direction: row-reverse;
      }
    }
  }
}