#processorMgmt {
    text-align: left;
    padding: 20px;
    overflow-y: auto;

    .header {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
    }

    .field-container {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        margin-bottom: 16px;
        gap: 16px;
        justify-content: space-between;
    }

    .button-container {
        margin-top: 12px;
        gap: 20px;
        margin-bottom: 50px;
    }

    .header {
        font-size: 16px;
        font-weight: bold;
    }

    .grid-container {
        margin-top: 12px;
        height: 300px;
    }
}