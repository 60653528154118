@import '../../../styles/colors.scss';

#claim-processing-reprocessing {
    display: flex;
    flex-direction: column;

    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 38px;
        color: #101828;
        text-align: left;
        margin: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .devider {
        height: 1px;
        background-color: #EAECF0;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        align-self: center;
    }

    .chart-outer-container {
        display: flex;
        flex-direction: row;
        margin-top: 60px;
        align-items: center;
        justify-content: space-around;
        margin: 0px 12px 60px 12px;
    }

    .button-container {
        align-self: flex-end;
        margin-right: 20px;
        gap: 24px
    }

    .tab-container {
        display: flex;
        align-self: flex-start;
        margin: 12px;
    }

    .selected {
        color: $fliptRed;
    }

    .number-text {
        color: #101828;
        font-size: 22px;
        line-height: 30px;
        font-weight: bold;
    }

    .chart-inner-container {
        border: 1px solid #EAECF0;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
        width: 28%;
    }

    .date-container {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin: 20px;
    }

    .chart-header {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        color: #101828;
        text-align: left;
        margin: 12px;
    }
    .showBlock {
        display: block;
        height: 100%;
    }
    .hideBlock {
        display: none;
    }

    .grid-container {
        height: 500px;
        margin: 12px 20px 60px 20px;
        text-align: left;

        .ag-theme-alpine {
            font-family: $v2-primary-font-family;
            font-size: 14px;
            font-weight: 400; // font-weight is not working
            color: #667085;

            .ag-root-wrapper {
                border: 1px solid #EAECF0;
                border-radius: 8px;
                box-shadow: $v2-sm-box-shadow;
            }

            .ag-row {
                height: 72px;
            }
        }
    }
}