@import '../../../styles/colors.scss';

#claimsProcessingAndReprocessing {
  padding: 20px;
  text-align: left;
  .header-container {
    display: flex;
    width: 100%;
    align-items: center;

    .header {
        font-family: $v2-primary-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #101828;
    }
  }

  >.mainHeader {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    .sub-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      
    }
  }
}