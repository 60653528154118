@import '../../../styles/colors.scss';

#medD-formulary {
    display: flex;
    flex-direction: column;

    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 38px;
        color: #101828;
        margin: 12px;
        display: flex;
        justify-content: space-between;

        .link-text {
            color: $fliptRed;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .divider {
        height: 1px;
        background-color: #EAECF0;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    }

    .button-container {
        margin-right: 12px;
    }

    .number-text {
        color: #101828;
        font-size: 22px;
        line-height: 30px;
        font-weight: bold;
    }

    .grid-container {
        height: 500px;
        margin: 12px 20px 60px 20px;
        text-align: left;
    }
}