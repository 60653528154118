@import '../../styles/colors.scss', '../../styles/themes.scss';

#appHeader {
  align-items: center;
  background-color: var(--theme-primary);
  color: $white;
  display: none;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  height: 65px;
  justify-content: space-between;
  width: 100%;
}

.user-menu {
  padding-right: 20px;

  > span {
    font-family: 'Nunito','Avenir-Medium';
    font-weight:400;
    font-size: 15px;
    display: inline-block;
    color: $whiteSmoke;
    margin-right: 10px;
    opacity: 0.9;
  }
}

.logout-text {
  cursor: pointer;
}

.smaller-logout {
  padding: 3px;
  gap: 10px
}
