@import '../styles/colors.scss';

#appModal {
  display: none;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;

  &.visible {
    display: flex;
  }

  > .modal-background {
    background-color: $black;
    opacity: 0.25;
    height: inherit;
    width: inherit;
    z-index: 101;
    position: absolute;
  }
}

.ui.modal > .close {
  color: $black;
  right: 0;
  top: 0;
}