@import '../../../styles/colors.scss';
@import '../../../styles/menuCard.scss';

#cardMenuContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: inherit;
  justify-content: center;
  padding: 50px;

  > a {
    margin-right: 50px;
    margin-bottom: 50px;
  }
 
  .menu-item {
    @include menuCard;
  }
}
