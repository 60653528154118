@import "../../../../styles/colors.scss";
@import "../../../../globalStyles/index.scss";

.formularyTierCreationManagement {
  padding: 20px;
  text-align: left;

  .content {
    
    margin: 24px 0px;
    padding-right: 10px;
    
    .alignCenter{
      text-align: center;
    }

    .published_status_value{
      display: flex;
      height: 22px;
      width:89px;
      background-color: $v2-success-25;
      border-radius: 16px;
      padding:2px 8px 2px 6px;
      img{
        height:14px;
        width:14px;
        margin-right: 4px;
        margin-top: 1px;
      }
      p{
        text-align: center;
        @include text_xs(medium,$v2-success-600);
        text-transform: capitalize;
      }
    }
  }
  
}