@import "../../../../styles/colors.scss";

#um-where-used-lookup {
  text-align: left;
  font-family: $v2-primary-font-family;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .hierarchy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .hierarchy-header {
      color: $v2-black;
      font-size: 24px;
      font-weight: 500;
      line-height: 20px;
    }

    .self-align-center {
      align-self: center;
    }
  }

  .um-dropdowns-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .flex-item {
      width: 31%;
    }
  }

  .pull-plans-container {
    display: flex;
    justify-content: center;
  }

  .plans-grid-container {
    height: 600px;
    margin-bottom: 24px;

    .ag-theme-alpine {
      font-family: $v2-primary-font-family;
      font-size: 14px;
      color: #667085;

      .ag-root-wrapper {
        border: 1px solid #eaecf0;
        border-radius: 8px;
        box-shadow: $v2-sm-box-shadow;
      }

      .ag-row {
        height: 72px;
      }
    }
  }
}
