@import '../../../styles/colors.scss';

#pharmacyLookup {
  padding: 20px;
  text-align: left;
  overflow: auto;

  >.content {
    // overflow: auto;
    // margin: 10px 10px 50px 10px;
    // padding-right: 10px;
    border-top: 2px solid $grey-200;
    margin-top: 10px;
    overflow: auto;
    height: 700px;
    max-width: 95%;

    .action {
      >* {
        background-color: $gallery;
        margin-right: 1px;
        padding: 5px 10px;
      }
    }
  }

  .clearButton {
    font-size: 14px;
    min-width: 100px;
  }

  .button-container {
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .primary {
      margin-right: 10px;
    }

  }

  .header {
    display: flex;
    // padding: 30px;
  }

  .horizontal-fields {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;

    >input {
      margin-right: 20px;
    }

    >div {
      margin-right: 20px;

      >div.menu {
        overflow-y: scroll;
      }
    }
  }



  h2 {
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .section {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .fields {
      width: 33%;

      >div {
        background-color: $grey-400;
        margin-bottom: 10px;
        padding-left: 10px;

        &:nth-child(2n) {
          background-color: $grey-200;
        }
      }
    }
  }
}

.warningModal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    >*:first-child {
      margin-right: 10px;
    }
  }
}