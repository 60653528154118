@import "../../styles/colors.scss";

.action-menu {
  top: -9999px;
  left: -9999px;
  width: 170px;
  height: 200px;
  position: absolute;
}

.disabled-link {
  pointer-events: none;
  display: inline-block;

  div.menu-item {
    color: $v2-gray-500;
  }
}
