@import '../../../../styles/colors.scss';

#pa-charts {
  display: flex;
  flex-direction: column;

  .charts-container-1 {
    display: flex;
    height: 400px;

    .monthly-cases-container {
      width: 800px;
      background: $v2-white;
      border: 1px solid $v2-gray-200;
      /* Shadow/sm */
      box-shadow: $v2-sm-box-shadow;
      border-radius: 8px;
      padding-bottom: 20px;

      .monthly-cases-title-container {
        height: 66px;
        padding: 20px;

        .monthly-cases-title {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;

          color: $v2-gray-900;
        }
      }

      .monthly-cases-chart {
        height: 300px;
      }
    }
  }

  .stats-row {
    margin: 20px 0px;
    display: flex;
    height: 114px;
    justify-content: space-between;
    gap: 24px;

    .stats-container {
      box-shadow: $v2-sm-box-shadow;
      padding: 24px;
      border: 1px solid #EAECF0;
      border-radius: 8px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 8px;
      isolation: isolate;
    }

    .stats-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $v2-gray-500;
    }

    .stats {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: $v2-gray-900
    }
  }

}
