.pharmacy-section {
    .pharmacy-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-bottom: 5px;
    }

    .inner-container {
        padding: 20px;
        border-radius: 4px;
        border: solid 1px #bcbabe;
        margin-bottom: 20px;
    }

    .pa-field {
        margin-top: 20px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .fields-inner-container {
        display: flex;
        flex-direction: column;
    }

    .margin-top {
        margin-top: 8px;
    }

    .pharmacy-buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .primary {
            margin-right: 1rem;
        }
    }

    .grid-container {
        min-height: 200px;
        height: 200px;
        text-align: left;
    }

    .second-row {
        width: 60%;
    }
}