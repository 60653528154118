@import '../../../../../../styles/colors.scss';

#frf-search {
    display: flex;
    flex-direction: column;

    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 38px;
        color: #101828;
        margin: 12px;
        display: flex;
        justify-content: space-between;


    }

    .devider {
        height: 1px;
        background-color: #EAECF0;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    }

    .button-container {
        margin-right: 12px;
    }

    .field-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 12px;
    }

    .field-inner-container {
        display: flex;
        flex-direction: column;

        >div {
            margin-top: 12px;
        }
    }

    .button-container {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: space-between;
        width: 180px;
        margin-bottom: 20px;
    }

    .grid-container {
        height: 300px;
        margin: 12px 20px 60px 20px;
        text-align: left;
    }
}