@import '../../../../styles/colors.scss';

#FormularyExportBuilder {
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: left;

  .header {
    color: #101828;
    display: flex;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    justify-content: space-between;
    line-height: 38px;
    margin: 12px;

    // .link-text {
    //     color: $fliptRed;
    //     font-size: 14px;
    // }
  }

  .content {
    margin: 10px;
    padding-bottom: 400px;
    padding-right: 10px;


    >.section {
      margin-bottom: 0;
      margin-top: 0;

      &.buttons {
        display: flex;
        justify-content: center;

        .button-container {
          justify-content: space-evenly;
          width: 25%;
          align-items: center;
        }

      }

      >div {
        display: inline-flex;
        flex-direction: row;
        justify-content: start;
        margin-left: 20px;
        margin-right: 40px;

        .inner-header {
          color: #101828;
          font-size: 14px;
          font-weight: bold;
          line-height: 38px;
          padding-bottom: 10px;
        }
      }

      .dropdown-wrap {
        margin-bottom: 0;

        .inputs {
          margin-bottom: 0;
        }
      }

      >div.row {
        // padding-bottom: 20px;
        display: flex;
        justify-content: left;

        >div {
          padding-right: 20px;
        }

        &.grid-container {
          display: flex;
          flex-direction: column;
          height: 450px;
          justify-content: start;

          >div {
            padding-bottom: 15px;
          }
        }
      }

      .checkbox-outer-container {
        vertical-align: top;
      }

      .checkbox-inner-container {
        display: inline-block;
        padding-right: 50px;

        .checkbox-header {
          color: #101828;
          font-size: 14px;
          font-weight: bold;
          line-height: 38px;
          padding-bottom: 10px;
        }

        .checkbox-container {
          width: 100%;
        }

        label {
          font-weight: normal;
          padding-left: 15px;
        }
      }

    }
  }

}