@import "../../../../../styles/colors.scss";

#BenefitTestingBed {
    text-align: left;

    h2 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .test-beds {
        .create-new-test-bed {
            text-align: right;
        }
        .test-bed-grid {
            height: 300px;
            margin-top: 20px;
            margin-bottom: 50px;
        }
    }
    .modal {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        overflow-anchor: none;

        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
      
        .modal-content {
          background-color: #fefefe;
          margin: 15% auto;
          padding: 20px;
          border: 1px solid #888;
          width: 80%;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
          position: relative;
      
          @media (min-width: 768px) {
            width: 80%;
          }
        }
      
        .close {
          color: #aaa;
          position: absolute;
          right: 25px;
          top: 0;
          font-size: 28px;
          font-weight: bold;
      
          &:hover,
          &:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

}