@import '../../../styles/colors.scss';

#testClaims {
  padding: 20px;
  text-align: left;

  >.mainHeader {
    display: flex;
    flex-direction: column;

    h2 {
      color: black;
      display: inline-block;
      font-size: 20px;
      margin: 30px 0px 0px 10px;
      font-weight: bold;
    }
  }

  .imp {
    font-size: 14px;
    margin: 5px 0px 0px 12px;
  }

  .rejection-header {
    align-self: center;
    font-size: 16px;
    font-weight: 800px;
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;

    .cancelButton {
      margin-left: 20px;
      font-size: 14px;
    }
  }

  .test-main-container {
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin: 10px;
    margin-bottom: 30px;

    .test-claim-header {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .search-container {
      display: flex;
      justify-content: space-around;

      .search-separator {
        width: 1px;
        background-color: rgba(0, 0, 0, 0.25);
      }

      .margin-top {
        margin-top: 20px;
      }

      .mini-header {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .margin-left {
        margin-left: 5px;
      }

      .info-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        caret-color: transparent;
      }

      .on-hover-info {
        position: absolute;
        border: 1px solid black;
        background-color: white;
        width: 250px;
        padding: 8px;
        font-size: 14px;
        color: #666a67;
        z-index: 100000;
      }

      .on-hover-info-right {
        right: 40px;
      }

      .icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .validated-text {
        margin-top: 8px;
        font-size: 13px;
        color: #21ba45;
        font-weight: 800;
      }

      .not-validated-text {
        margin-top: 8px;
        font-size: 13px;
        color: $fliptRed;
        font-weight: 800;
      }


      .step-header {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        color: $fliptRed;
      }

    }

    .section-header {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      margin-left: 10px;
    }

    .horizontal-separator {
      margin: 40px 10px 40px 10px;
      height: 1px;
      width: 98%;
      align-self: center;
      background-color: rgba(0, 0, 0, 0.25);
    }

    .shadow {
      box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);

    }

    >.content {
      margin: 10px;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 50px;

      .section-header {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }

      .less-margin-right {
        margin-right: 8px;
      }

      .fields-container {
        margin-top: 10px;
        margin-bottom: 20px;

        .fields-header {
          width: 25%;
        }

        .less-margin-right {
          margin-right: 8px;
        }

        div.phone[role='listbox'] {
          width: 200px;
        }

        .less-margin-right {
          margin-right: 8px;
        }

        .margin-right {
          margin-right: 40px;
        }
      }

    }

    >.response-content {
      overflow: auto;
      padding: 0px 0px 20px 20px;

      .section-header {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }

      .fields-container {
        align-items: flex-end;

        .fields-header {
          width: 25%;
        }

        .input-class-med {
          width: 400px;
        }

        div.phone[role='listbox'] {
          width: 200px;
        }

        .margin-right {
          margin-right: 40px;
        }

      }

    }
  }



}