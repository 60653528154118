@import '../../../../styles/colors.scss';
.elementContainers{
  .row{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}
}

.fliptCustom {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 5;
}
#applicationOnly {
  display: flex;
  flex-direction: column;
  .flipt-label {
    width:100%;
  }
  .applicationContainer{
    display: flex;
    flex-direction: row;
    .stylized {
      width: 30%;
      padding: 15px;
    }
  }
  .grid-container {
    height: 300px;
    width: 100%;
    .ag-popup-editor {
      width: 450px;
    }
  }
  .addRowButtonContainer {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  .react-datepicker-wrapper{
    width: 100%;
    padding-left: 14px;
  }
}