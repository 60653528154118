#qualifier-lookup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .qualifier-lookup-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 20px;
    text-transform: capitalize;
    font-weight: 700;
  }

  .input-container {
    display: flex;
    gap: 24px;
  }

  .modal-text {
    margin-bottom: 12px;
  }

  .grid-container {
    height: 200px;
    text-align: left;
    margin: 20px;
    width: 100%;
  }

}