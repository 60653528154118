#create-opioid {
    padding: 20px;
    text-align: left;
    overflow-x: auto;

    .header {
        size: 18px;
        font-weight: bold;
    }

    .field-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 20px;
    }

    .section-tier-levels-accordion {
        margin-top: 12px;
        width: 100%;

        .section-tier-level {
            .section-tier-conditions {
                overflow: auto;
                height: 350px;
                margin-top: 5px;
            }
        }
    }

    .grid-container {
        margin: 20px;
    }
}