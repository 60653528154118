@import '../../../styles/colors.scss';

#planDesignManagement {
  display: flex;
  flex-direction: column;

  padding: 20px;
  text-align: left;

  >.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

    .create-plan {
      display: flex;
      align-items: center;
      color: $fliptRed;
      font-size: 70%;
    }

    .create-plan:hover {
      cursor: pointer;
    }
  }

  .field-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-left: 40px;
  }

  .button-container {
    margin-top: 13px;
  }

  .filter-Options {
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    margin: 0px 0px 15px 0px !important;
    text-align: left;
    z-index: 9;

    >* {
      margin-right: 30px;
    }

    div.stylized {
      display: block;
    }

    div.ui.dropdown {
      border: 1px solid $grey-600;
    }

    input {
      border: 1px solid $grey-600;
      border-radius: .28571429rem;
      width: 90%;
    }

    #CAGFilters {
      margin-bottom: 0;
      position: sticky;
    }

    .flipt-label {
      width: 100%;
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 5px;
      padding-left: 0px;
    }

    input.primary.searchButton {
      width: 40%;
      margin-left: 40px;
      margin-bottom: 10px;
    }

  }

  .content {
    height: 460px;
  }

  .content.copy-object {
    display: flex;
    flex-direction: column;
    width: 1000px;

    .copy-header {
      font-size: 1.5em;
      justify-content: center;
      display: flex;
      color: #636363;
      font-weight: bold;
    }

    .plan-inputs {
      display: flex;
      flex-direction: column;

      .create-plan-end-date,
      .create-plan-start-date {
        width: 100%;
      }
    }

    .input-container {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 10px;
      padding: 0 2em 2em 2em;

      span {
        padding: 8px;
        color: #636363;
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        padding: 10px;
      }
    }

    .action-container {
      display: flex;
      flex-direction: row-reverse;

      .button-container {
        padding: 0 10px;
      }
    }
  }

}


.copy-object {
  position: absolute;
  top: 22%;
  background-color: white;
  max-width: 60% !important;
  right: 11%;
  box-shadow: 0px 3px 20px !important;
  max-height: 65vh !important;

  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid #c0c3c8;
  z-index: 10;

  >.plan-inputs {
    display: flex;
    flex-flow: column;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;

    // max-height: 15em !important;
    span {
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 5px;
    }

    input {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
    }

    .create-plan-start-date {
      width: 100%;
    }

    .create-plan-end-date {
      width: 100%;
    }

    .field {
      height: 100%;
    }
  }

  #CAGFilters {
    background-color: white;
    padding: 1em 0em 1em 0em;
  }

  .buttonContainer {
    >* {
      margin-right: 40px;
    }

    // padding: 1px 0px 1px 0px;
  }
}