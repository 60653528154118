.tabsContainer {
    display: flex;
    align-items: baseline;
    .submenu {
      display: flex;
      border-radius: 8px;
      border: 1px solid  $v2-gray-300;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      .tab {
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-right: 1px solid $v2-gray-300;
        height: 40px;
        width: auto;
        .tab_inner_container {
          display: flex;
          img{
            margin-bottom:2px ;
          }
          p {
            @include text_sm(medium,$v2-gray-800)
          }
        }
        &.active {
          background: $v2-gray-100;
        }
      }
      .tab:first-child {
        border-left: none;
        border-radius: 9px 0px 0px 9px;
      }

      .tab:last-child {
        border-right: none;
      }
    }
  }