@import '../../../../styles/colors.scss';

#file-configuration {
    display: flex;
    flex-direction: column;
    .bordered-other-setting {
      border: 1px solid black;
      padding: 20px;
      text-align: left;
      display: flex;
      flex-direction: column-reverse;
      width: 600px;
      margin-left: auto;
      margin-right: 35px;
    }
    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }

    div.dropdown[role="listbox"] {
      >div.menu.transition {
         height: 110px;
      }
    }
    .container {
      // width: 90%;
      // margin-left: 3%;
      //need to chnage later just for demo only
      // width: 88%;
      // margin-left: 5%;
      // width: 100%;
          position: absolute;
          margin-top: 170px;
          width: 75%;
      }
      .subheader {
        font-size: 15px;
        font-weight: 600;
      }

    .header {
        display: flex;
        font-weight: bold;
        font-size: 20px;
        line-height: 38px;
        color: #101828;
        justify-content: space-between;
        text-align: left;
        margin: 12px;
    }
    .content-progress{
      width: 95%;
      margin-left: 3%;
    }


    .progressbar {
      counter-reset: step;
      display: flex
    }
    .progressbar li {
      list-style: none;
      display: inline-block;
      width: 30.33%;
      position: relative;
      text-align: center;
      cursor: pointer;
    }
    .progressbar li:before {
      content: '•';
      font-size: 15px;
      width: 25px;
      height: 25px;
      line-height: 20px;
      border: 1px solid #ddd;
      border-radius: 100%;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      background-color: #fff;
      border-width: medium;
      z-index: 1; // added after merge into develop
      position: relative; // added after merge into develop
    }

    .progressbar  li.completed:before {
      content: '\2713'
    }
    .progressbar li:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #ddd;
      top: 12px;
      left: -50%;
      z-index: 0; // Change -1 to 0 after merge into develop
    }
    .progressbar li:first-child:after {
      content: none;
    }
    .progressbar li.active {
      //color: green;
    }
    .progressbar li.active:after {
      color: green;
    }
    .progressbar li.active:before {
      color: green;
      background-color: #ECFDF3;
  
    }
    .progressbar li.active.completed + li:after {
      background-color: green;
    }
    .progressbar li.disabled {
      color: #ddd;
    }

    .devider {
        height: 1px;
        background-color: #EAECF0;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    }
    .cancel {
        display: flex;
        margin-bottom: 10px;
        margin-left: 25px;
        // margin-top: 20px;
        cursor: pointer;
    }
    .section-header {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        margin-top: 40px;
      }
      .section-sub-header {
        font-size: 14px;
        text-align: left;
        margin-top: 10px;
        color:#667085
      }
      .field-label-hint{
        font-size: 14px;
        text-align: left;
        margin-top: 5px;
        color:#667085;
        margin-bottom: 10px;
      }
      .field-sub-hint{
        font-size: 12px;
        text-align: left;
        // margin-top: 5px;
        color:#667085;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        gap:10px
      }
      .fields-btn-container {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        margin-bottom: 50px;
        justify-content: center;
        gap: 10px;
        width: 96%;
        position: relative;

        .btn-disabled {
          opacity: 0.5;
          cursor: not-allowed;
          min-width: 100px;
          padding: 10px 5px;
        }
    }
      div.fields-header.exit-plan-config {
        display: inline-flex;
        align-items: center;
      }

    .fields-header {
      > input {
        text-transform: none !important;
        }
      .exit-plan-button {
        display: flex;
        align-items: center;
        color: $fliptRed;
        font-weight: bold;
      }
    
        .exit-plan-button:hover {
          cursor: pointer;
        }
    }

      >.content {
       
        margin-top: 20px;
        border: 1px solid #F1F1F1;
        padding: 5px 15px;
        background: #f5f5f5;
        .uploadInputContainer {
          >.uploadInput {
            padding: 20px;
  
            border: 1px dashed $black;
            margin: 10 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;
            background-color: #f0f0f0;
          }
  
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 10px;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
        }
        //transaformation ui
        .transform {
          height: 350px;
          margin-top: 15px;
          padding: 0px 0px 10px 0px;
          text-align: left;
        }


        .section-header {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
        }
        .section-middle-header {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          margin-bottom: 20px;
        }
        .interchange-table {
          box-sizing: border-box;
          width: calc(100% - 50px);
          margin: 5px;
          border: 1px solid #D4D4D5;
          box-shadow: 2px 2px 10px #75787661;
          margin-top: 1em;
          margin-bottom: 1em;
          &-selected {
            box-sizing: border-box;
            width: calc(100% - 50px);
            margin: 5px;
            margin-top: 1em;
            margin-bottom: 1em;
            border: 2px solid #007bffa5;
            box-shadow: 0 0 3px 2px rgba(0,123,255,0.5);
          }
      }
        .fields-container {
         
          margin-top: 10px;
          margin-bottom: 15px;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // &:nth-child(even) {
          //   background-color: #F1F1F1;
          //   padding:10px
          // }
          .field-label-large{
            margin-top: 25px;
            color: #344054;
            font-size: 24px;
          }
          .fields-table-header{
            width: 100%;
            margin-right: 20px;
          }
          .fields-client-header {
            width: 31.3%;
            margin-right: 20px;
          }
          .fields-header {
            width: 48%;
            margin-right: 20px;
          //   &:nth-child(odd) {
          //     border-right:1px solid darkgray
          // }
          }
          .fields-header-background {
            width: 48%;
            background: #F5F5F5;
            padding: 25px;
          }
          .fields-sub-header {
            width: 20%;
            margin-right: 20px;
          }
          .half-width{
            align-items: inherit;
            &.bottom-margin {
              margin-bottom: 50px
            }
          }
          .zIndex{
            z-index: 9;
          }
          
          .fields-full-width {
            width: 100%;
            margin-right: 20px;
          }
          .fields-date {
            width: 50%;
            margin-right: 20px;
            display: flex;
            flex-flow: column
          }
          .field-label{
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            gap:10px;
            text-align: left;
            font-size: 14px;
            font-weight: 700;
          }

          .field-label-table {
            display: flex;
            justify-content: space-around;
            margin-bottom: 8px;
            gap:10px;
            text-align: left;
            font-size: 14px;
            font-weight: 700;
          }

          .field-label-radio{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            gap:10px;
            font-size: 14px;
          }
          .divide-half-radio {
            width: 50%;
            text-align: left;
          }
          .divide-half{
            width: 50%;
          }
          .divide-third{
            width: 75%;
          }
 
          .field-input{
            width: 100%;
   
          }
          .field-input-date{
            width: 50%;
          }
  

        }
   
       .fields-equal {
        display: flex;
        justify-content: space-between;
       }
  
      }

      .ui.table tbody > tr > td{
        font-size: .85em;
        border-left: 0px !important;
        border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        border-collapse: collapse;
        border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
    
      }

            .mapping {
              &-container {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  gap: 12em;
                }

                &-custom {
                  &-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-right: 20px;
                    border: #f1f1f1 2px solid;
                  }

                  &-section {
                    display: block;
                    // flex-direction: column;
                    text-align: left;
                    padding: .7em;

                    &:nth-child(odd) {
                      background-color: #F1F1F1;
                    }
                  }

                &-header {
                  font-weight: 700;
                  padding: .5em;
                }

                &-fields {
                  padding: .5em;
                }

                &-field {
                  font-weight: 500;
                  margin: 0 0 1em 1em;
                  transition: all .3s ease-in-out;
                  &:hover {
                    cursor: pointer;
                    // background-color: #f0f0f0;
                    text-decoration: underline;
                    font-weight: 700;
                  }
                  &-selected {
                    box-sizing: border-box;
                    font-weight: 700;
                    margin: 0 0 1em 1em;
                    transition: all .3s ease-in-out;
                    border: 1em solid #007bffa5;
                    &:hover {
                      cursor: pointer;
                      // background-color: #f0f0f0;
                      text-decoration: underline;
                      font-weight: 700;
                    }
                  }
                }

                &-preview {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                }
                &-table {
                  border: #f1f1f1 2px solid;
                  width: 100%;
                }
              }

              &-standard {
                &-container {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin-right: 20px;
                  border: #ff4400a5 2px solid;
                }
                &-field {
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    font-weight: 700;
                  }
                }
              }
            }

      .ui.table.remove-top-margin {
        margin-top: 0px !important;
      }
      .ui.table tbody > tr > td .test{
        background-color: red;
      }
      .mapping-custom-table {
        margin-top: 20px;
      }

      .unmap-container {
        position: relative;
        &:hover {
          .unmap-button {
            opacity: 1;
          }
        }
      }

      .unmap-area {
        position: absolute;
        left: -30px;
        top: 0;
        bottom: 0;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .unmap-button {
        border: none;
        background: none;
        font-size: 20px;
        text-shadow: 5px 5px 4px white;
        opacity: 0;
        transition: opacity 0.5s ease;
      }

      .unmap-icon {
        cursor: pointer;
        text-shadow: 5px 5px 4px white;
      }

}

#standard-selection-modal {
  .selection-container {
    height: 500px;
  }
}