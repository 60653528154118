@import "../../../styles/colors.scss";

.radio-container {
    display: flex;
    align-items: center;
  
    .radio-input {
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
      outline: none;
      cursor: pointer;
  
      &:checked {
        background-color: $v2-gray-700;
        border: 2px solid transparent;
      }
    }
  
    .radio-label {
      font-size: 16px;
      color: $v2-gray-700;
      margin-left: 10px;
    }
  
    .radio-label:last-child {
      margin-left: auto;
    }
  }
  