#rebateManagement {
  .container {
    width: 100%;
  }

  .dleft {
    display: flex;
    padding-left: 30px;
  }

  input {
    border-radius: 0;
    outline: 1px solid #F0F0F0;
  }
  .field-label {
    display: flex;
    justify-content: flex-end;
  }
  .header {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: auto 25px;
  }

  .checkbox-container {
    flex-direction: row;
    width: fit-content;
    display: flex;
    margin-bottom: 8px;

    >span {
      width: 100%;
      margin-left: 8px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  #navButton {
    margin-bottom: 50px;
    padding: 10px;
    display: flex;
    align-items: end;
    flex-direction: row;

    .back {
      width: 50%;
      display: flex;
      justify-content: start;
    }

    .next {
      width: 50%;
      display: flex;
      justify-content: end;
    }
  }

  .progressbar {
    counter-reset: step;
    display: flex
  }

  .progressbar li {
    list-style: none;
    display: inline-block;
    width: 30.33%;
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  .full-width {
    width: 100%;
     >.field {
      width: 100%;
     }
  }
  .half {
      width: calc(50% - 9px);
      margin: 0 20px 10px 0;
      >.pa-stylized >.field {
          width: 100%;
        }
  }
  .third {
    width: calc(33.33%);
    margin: 0 20px 10px 0;

    >.pa-stylized>.field {
      width: 100%;
    }
  }
  .ui{

    .search.selection.dropdown {
      min-height: none;
      padding: 0.6em 2.1em 0.49em 1em;
      >input.search {
          padding: 0.45em 2.1em 0.45em 1em;
        }
    }
  }

  .inner-cycle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .outer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
    &.space-between {
      justify-content: space-between;
    }  
  }

  .outer-cycle-container {
    display: flex;
    flex-direction: column;
  }

  .field-inner-container {
    display: flex;
    flex-direction: column;

    >div {
      margin-bottom: 16px;
    }
  }

  .progressbar li.active:after {
    color: green;
  }

  .progressbar li.active:before {
    color: green;
    background-color: #ECFDF3;

  }

  .progressbar li.active.completed+li:after {
    background-color: green;
  }

  .content.maxHeight {
    padding: 0px 20px 0px 20px;
    overflow: auto;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    margin: 10px;
    padding: 16px;
  }

  .input-class-med {
    width: 250px;
  }

  .card.small {
    height: 400px;
  }

  .close-button {
    margin-bottom: 50px;
    display: flex;
    padding: 10px;
    justify-content: flex-start;
    &.add>input {
      background-color: white;
      color: #333;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      border-radius: 8px;
      border: 1px solid;
    }
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .header1 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 12px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .grid-container {
    height: 200px;
    margin: 12px;
    text-align: left;
  }

  .cardContent {
    padding: 2px 16px;
    text-align: left;
  }


  //height: 1200px;
  .breadcrumb {
    display: flex;
    padding: 10px;
  }

  input[disabled] {
    background-color: #f9f9f9;
    color: #666a67;
    cursor: not-allowed;
  }

  .agree-checkbox {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .grid-container {
    height: 450px;
  }

  .react-sortable-tree-container {
    height: 275px;
    min-height: 275px;
    width: 100%;
    overflow: auto;
  }

  .splitPlane {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .leftPlane {
    width: 50%;
    height: 350px;

    .stylized {
      flex-direction: column;
      padding: 0 15px;

      .flipt-label {
        width: auto;
        padding-left: 0;
      }

      .ui.selection.dropdown {
        width: 80%;
      }
    }

    .stylized.column {
      flex-direction: column;
      padding: 0 15px;

      .row {
        display: flex;
        flex-direction: row;

        .stylized {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;

          .flipt-label {
            font-size: 1em;
          }
        }
      }

      .flipt-label {
        width: auto;
        padding-left: 0;
      }

      .ui.selection.dropdown {
        width: 80%;
      }
    }
  }

  .rightPlane {
    width: 50%;
    height: 350px;
    overflow: hidden;

    .error {
      height: 10px;
      color: red;
      font-size: 10px;
      display: flex;
      padding: 14px 0;
    }

    .treeSearch {
      .stylized {
        flex-direction: row-reverse;
      }
    }
  }
}