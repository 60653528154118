.page {
    display: flex;
    padding: 1.25rem;
    flex-direction: column;
    align-items: flex-start;

    // align-content: flex-start;
    &-header {
        color: black;
        display: block;
        font-size: 1.25rem;
        font-weight: bold;
        margin-top: 1.2rem;
    }

    &-search-container {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding: 1.25rem .15625rem;
    }

    &-label {
        font-size: small;
        text-align: left;
        font-weight: bold;
        color: #666a67;
        padding-bottom: .1875rem;
        margin-right: 1.25rem;
    }

    &-input {
        border: none;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: .75rem;
        padding: .625rem;
        max-height: 15rem;
        text-transform: none;
        border-radius: 0;
        outline: .0625rem solid #F0F0F0;
        margin-right: .625rem;
        margin-bottom: 1em;
        width: 16.5rem;
        z-index: 5;

        &::-webkit-input-placeholder {
            /* WebKit browsers */
            text-transform: capitalize;
        }

        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            text-transform: capitalize;
        }

        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            text-transform: capitalize;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            text-transform: capitalize;
        }

        &::placeholder {
            /* Recent browsers */
            text-transform: capitalize;
        }
    }

    &-select {
        cursor: pointer;
        font-family: "Nunito", "Avenir-Medium";
        color: #666a67;
        word-wrap: break-word;
        line-height: 1em;
        white-space: normal;
        outline: 0;
        transform: rotateZ(0);
        min-width: 14em;
        min-height: 2.71428571em;
        background: #fff;
        display: inline-block;
        // padding: 0.78571429em 2.1em 0.78571429em 1em;
        padding: .5em;
        color: rgba(0, 0, 0, .87);
        box-shadow: none;
        border: 1px solid rgba(34, 36, 38, .15);
        border-radius: 0.28571429rem;
        transition: box-shadow .1s ease, width .1s ease;
    }

    &-button {
        cursor: pointer;
        background-color: #FB0505;
        color: #FFFFFF;
        font-size: 0.875rem;
        height: 35px;
        min-width: 100px;
        padding: 0px 0.3125rem;

        &:hover {
            background-color: #636363;
            color: #D4D4D5;
            transition: all 0.5s ease-in-out;
        }
    }

    &-success {
        &-fadein {
            background-color: #C8F0DA;
            border-radius: .0975rem;
            border: 1px solid #4BB543;
            color: #4BB543;
            font-size: 0.875rem;
            font-weight: bold;
            margin-bottom:.5rem;
            padding: 1rem;
            text-align: center;
            width: 100%;
            animation: fadeInFromTop 1s ease-in-out;
        }
        &-fadeout {
            background-color: #C8F0DA;
            border-radius: .0975rem;
            border: 1px solid #4BB543;
            color: #4BB543;
            font-size: 0.875rem;
            font-weight: bold;
            margin-bottom:.5rem;
            padding: 1rem;
            text-align: center;
            width: 100%;
            animation: fadeOutToTop 1s ease-in-out;
        }
    }
}

@keyframes fadeInFromTop {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutToTop {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

.detail {
    &-view {
        all: initial;
        font-family: inherit;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: .75fr 1fr 1fr;
        flex-direction: row;
        gap: 0px;
    }
}

.no-results {
    min-width: 18rem;
}

.data {
    &-t-container {
        all: initial;
        font-family: inherit;
        box-sizing: border-box;
        min-width: 27rem;
        max-height: 55vh;
        overflow-y: scroll;
        overscroll-behavior-y: contain;

        &::-webkit-scrollbar-track {
            background-color: #FFFFFF;
        }
    }

    &-table {
        width: 23.125rem;
        align-self: start;
        justify-self: start;
        margin-bottom: 3.125rem;
        overflow: auto;
    }

    &-td {
        box-sizing: border-box;
        border-left: .03125rem solid black;
        border-bottom: .03125rem solid black;
        border-right: .03125rem solid black;
        padding: .125rem;
        text-align: left;
        width: 9.375rem;
        padding-left: 0.625rem;
        padding-top: 0.625rem;
        word-wrap: break-word;
    }

    &-th {
        box-sizing: border-box;
        text-align: justify;
        background-color: #FB0505;
        height: 3.125rem;
        padding-left: 0.625rem;
        padding-top: 0.625rem;
        word-wrap: break-word;

        &-username {
            text-align: center;
        }
    }

    &-tr:nth-child(even) {
        background-color: #f0f0f0;
    }

    &-tr:nth-child(odd) {
        background-color: #ffffff;
    }

    &-tr:hover {
        cursor: pointer;
        background-color: #8EE0B6;
        ;
    }

    &-tr {
        // height: 9.375rem;
    }

    &-tr-selected {
        font-weight: 700;
        background-color: #C8F0DA;
        // background-color: #8EE0B6;
    }

    &-tr-head {
        // background-color: #f0f0f0;
        background-color: #FB0505;
        color: #FFFFFF;
        border: #FB0505 .03125rem solid;
        font-weight: 600;
        // &:hover {
        // font-weight: 500;
        // }
    }

    &-container {
        width: 100%;
        // border-left: #c1c1c1 .1px solid;
        // border-right: #c1c1c1 .1px solid;
    }

    &-bar {
        display: flex;
        background-color: #FB0505;
        height: 3.125rem;
        justify-content: space-between;
        margin-bottom: 0.625rem;

        &-section {
            display: flex;
            align-items: center;
            padding-left: 0.625rem;
            color: white;
            font-weight: 600;
        }
    }

    &-header {
        display: inline-block;
        padding: 0.9375rem;
        color: white;
    }

    &-undo {
        cursor: pointer;
        display: inline-block;
        background-color: #FB0505;
        font-size: 0.875rem;
        height: 100%;
        // padding: .125rem;

        &__disabled {
            cursor: not-allowed;
            background-color: #FB0505;
            color: #D4D4D5;
            transition: all 0.5s ease-in-out;
            height: 100%;

            &:hover {
                background-color: #D4D4D5;
                ;
            }
        }

        &:hover {
            background-color: #636363;
            color: #D4D4D5;
            transition: all 0.5s ease-in-out;
        }

        &-img {
            &:hover {
                animation: rotate360 1s ease-out forwards;
            }

            &__disabled {
                &:hover {
                    animation: none;
                }
            }
        }
    }

    &-update {
        cursor: pointer;
        background-color: #FB0505;
        color: #FFFFFF;

        &:hover {
            background-color: #636363;
            color: #FFFFFF;
            transition: all 0.5s ease-in-out;
        }
    }

    &-footer {
        display: flex;
        background-color: #FB0505;
        height: 3.125rem;
        justify-content: flex-end;
        margin-top: 0.625rem;
        margin-bottom: 3.125rem;
    }


}

.edits {
    &-container {
        width: 100%;
        // border-left: #c1c1c1 .1px solid;
        // border-right: #c1c1c1 .1px solid;
        margin: 3.125rem 0.625rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: 55vh;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            background-color: #FFFFFF;
        }
    }

    &-section {
        margin-bottom: 0.625rem;
        border: #8a8a8a 1px solid;
        border-radius: 0.3125rem;
        padding: 0.625rem;
        min-width: 20.25rem;

        &-old {
            width: 100%;
            background-color: #FFCBBD;
            margin-bottom: 0.3125rem;
            padding-right: 0.625rem;
            padding-top: 0.3125rem;

            &-key {
                padding-left: 0.3125rem;
                padding-top: 0.3125rem;
                // background-color:#FF957E;
            }

            &-value {
                padding-left: 0.3125rem;
                padding-top: 0.3125rem;
                background-color: #FF957E;
            }
        }

        &-new {
            width: 100%;
            background-color: #C8F0DA;
            padding-right: 0.625rem;
            padding-top: 0.3125rem;

            &-key {
                padding-left: 0.3125rem;
                padding-top: 0.3125rem;
                // background-color:#8EE0B6;
            }

            &-value {
                padding-left: 0.3125rem;
                padding-top: 0.3125rem;
                background-color: #8EE0B6;
            }
        }
    }
}

.confirm {
    &-container {
        padding: 1rem;
    }

    &-header {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: center;
    }

    &-body {
        display: flex;
        flex-direction: column;
        max-height: 50vh;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            background-color: #FFFFFF;
        }

        &-header {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 1rem;
            text-align: center;
        }
    }

    &-actions {
        display: flex;
        justify-content: flex-end;
        margin: 1rem 0rem .5rem;

        &-cancel {
            cursor: pointer;
            background-color: inherit;
            color: #FB0505;
            margin-right: 1rem;
            font-weight: 600;
            padding: .7rem 1rem;
            max-width: 4.6875rem;

            &:hover {
                background-color: #FB0505;
                color: #FFF;
                transition: all 0.5s ease-in-out;
            }
        }

        &-confirm {
            cursor: pointer;
            background-color: #4183c4;
            ;
            color: #FFF;
            font-weight: 600;
            padding: .7rem 1rem;
            max-width: 4.6875rem;
            transition: color 0.5s, background-color 1s, text-decoration .5s;

            &:hover {
                max-width: 4.6875rem;
                background-color: inherit;
                // border: #4183c4 0.0212rem solid;
                text-decoration: underline;
                color: #4183c4;

                // transition: all 0.5s ease-in-out;
                transition-delay: 0.2s, 0.2s, 1s;
            }
        }
    }
}


@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

.reset {
    all: initial;
    font-family: inherit;
    box-sizing: border-box;
}