#tooltip-input {
    &.tooltip-input-wrapper {
        width: calc(25% - 20px);
        max-width: calc(25% - 20px);

        &-row {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 20vw;
            height: 45px;
        }
    }

    .tooltip-info-container {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 14px;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .tooltip-icon {
        color: grey;
    }

    .tooltip-list-dropdown {
        border-color: #F0F0F0;
        height: 46px;
    }

    .multiselect-tt-badge-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
        margin-top: 10px;
        max-height: 230px;
        overflow-y: auto;
    }

    .multiselect-tt-dropdown-container {
        width: 20vw;
        max-width: 100%;
    }

    @media only screen and (max-width: 1100px) {

        .tooltip-list-dropdown,
        .tooltip-input-wrapper-row {
            width: 100%;
        }

        .select-input-wrapper {
            width: 100%;
            max-width: 100%;
        }
    }
}