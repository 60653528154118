@import '../../../../styles/colors.scss';

#PlanDesignDetails {
  text-align: left;

  .section-medicare-contents {
    border-bottom: 1px solid $grey-600;

    .fields-container {
      margin-top: 10px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      font-weight: 700;

      >div {
        min-width: 0%;
      }
    }

  }

  .row-container {
    >div {
      width: 25%;
    }

    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
  }

  .create-medicare-plan {
    display: flex;
    align-items: center;
    color: $fliptRed;
    width: 100%;
    margin-top: 20px;
  }

  .create-medicare-plan:hover {
    cursor: pointer;
  }

  .grid-contents {
    height: 200px;
  }

  .section-contents {
    border-bottom: 1px solid $grey-600;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .display-info-container {
    display: flex;
    flex-direction: column;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;

    >div {
      min-width: 22%;
    }
  }

  .fields {
    margin-left: 40px;
    margin-top: 30px;
    width: 22%;
  }
}