@import '../../../../styles/colors.scss';

#createPlan {
  display: flex;
  flex-direction: column;

  padding: 20px;
  text-align: left;
  .breadCrumbContainer {
    display: flex;
    flex-direction: row;
    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;
      span{
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

    .new-plan {
      display: flex;
      align-items: center;
      color: $fliptRed;
      font-size: 70%;
    }

    .left {
      background-color: transparent !important;
    }
  }

  .footer {
    display: flex;
    justify-content: center;

    .searchButton {
      margin: 1em;
    }
  }
}
