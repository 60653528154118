@import "../../../../../styles/colors.scss";

#file-import-section {
  padding: 1em 2em;

  >.file-import-header {
    text-align: center;
    margin: 1em 0em;
    display: block;
  }

  >.file-import-container {
    text-align: left;
    display: block;
    margin-bottom: 0.5em;

    .file-input {
      padding: 1em 0;
    }
  }

  .input-viewer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;

    .file-import-grid-container {
      height: 200px;
      width: 100%;
    }

    .dropdown-selector {
      width: 100%;
      display: flex;
      flex-direction: column;

      .flipt-table {
        height: 100%;
        width: 100%;
      }
    }
  }

  div.stylized {
    text-align: left;
    display: block;

    >.flipt-label {
      color: $doveGray;
      font-size: 0.9em;
      font-weight: bold;
      padding-left: 0px;
      width: 100%;
    }

    >.field {
      padding-left: 0px;
    }
  }

  input[type="button"].search-button {
    border-radius: 3px;
    height: 50%;
  }

  >.file-import-dates {
    display: flex;
    justify-content: space-evenly;

    >.search-button {
      margin: 2.5em 0em 0em 1.5em;
    }

  }
}