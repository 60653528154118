@import '../../../styles/colors.scss';

#accountSetup {
  display: flex;
  flex-direction: column;

  padding: 20px;
  text-align: left;

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-account {
      display: flex;
      align-items: center;
      color: $fliptRed;
      font-size: 70%;
    }

    .add-account:hover {
      cursor: pointer;
    }
  }
}
