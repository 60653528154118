// @import "../../../../../styles/colors.scss";

#hierarchyManagement {
    .column {
      float: left;
      width: 50%;
      padding-bottom: 200px
    }
    padding: 20px;
    text-align: left;
    .buttonContainer {
      > * {
        margin-right: 40px;
      }
      padding: 30px 0px 40px 0px;
    }
    .tree {
      z-index: 5;
      height: 800px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
    }
    form {
      display: inline-block;
      .formElement {
        margin-left: 2em;
      }
    }
    .details {
      padding: 2em;
      border: 1px solid #c0c3c8;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      height: 400px;
      width: 450px;
      h1 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      h2 {
        margin-bottom: 15px;
        > span {
          font-weight: 600;
        }
      }
      .details-inner {
        position: relative;
        z-index: 10;
      }
    }
    .company-inputs-wrap {
      display: flex;
      flex-wrap: wrap;
      > .company-inputs {
        display: flex;
        flex-flow: column;
        margin-right: 1.5em;
        flex-grow: 1;
        margin-bottom: 15px;
        span {
          color: #636363;
          font-size: 0.9em;
          font-weight: bold;
          margin-bottom: 5px;
        }
        input {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 0.28571429rem;
        }
        .create-company-start-date {
          width: 100%;
          height: 100%;
        }
        .field {
          height: 100%;
        }
      }
    }
}