@import "../../../styles/colors.scss";

.v2-flipt-input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
	flex: 1 0 0;

	.description-text {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex: 1 auto;
		width: 100%;
	}

	.flipt-label {
		font-family: $v2-primary-font-family;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: $v2-gray-700;
	}

	.v2-flipt-input {
		border-radius: 8px;
		border: 1px solid $v2-gray-300;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		display: flex;
		font-family: $v2-primary-font-family;
		font-size: 16px;
		line-height: 24px;
		padding: 10px 14px;
		width: 100%;

		&[disabled] {
			background-color: $v2-gray-200;
			color: $v2-gray-500;
			cursor: not-allowed;
			font-weight: normal;
		}

		&[validation="email_address"],
		&[type="email"] {
			text-transform: lowercase !important;
		}


	}
}

// .has-error {
// 	outline: 1px solid $fliptRed !important;
// }


// //INPUT FIELD
// div.stylized {
// 	align-items: baseline;
// 	display: flex;

// 	.left {
// 		background-color: transparent;
// 	}

// 	>input.state {
// 		width: 60%;
// 	}

// 	div[role="listbox"] {
// 		>div[role="button"]>i[aria-hidden="true"] {
// 			display: none;
// 		}
// 	}

// 	>div {
// 		font-size: 0.7em;
// 		padding-right: 5px;
// 		padding-left: 10px;
// 		width: 40%;
// 	}

// 	.flipt-label {
// 		padding: 10px;
// 	}

// 	&.currency {
// 		position: relative;

// 		>span:before {
// 			content: "$";
// 			display: inline-block;
// 			font-size: 0.7em;
// 			margin: 0 0 0 3px;
// 			position: absolute;
// 			top: 11px;
// 		}
// 	}

// 	>input,
// 	>.field {
// 		width: 60%;

// 		div[data-testid="datepicker-input"] {
// 			width: 100%;
// 		}

// 		label {
// 			display: none;
// 		}
// 	}
// }
