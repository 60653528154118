@import '../../../../styles/colors.scss';
.medicare-setup{
    padding:20px;
    text-align: left;
    margin-top: 10px;
    > .content {
        // overflow: auto;
        // margin: 10px 10px 50px 10px;
        // padding-right: 10px;
        border-top: 2px solid $grey-200;
        margin-top: 10px;
        overflow: auto;
        height: 350px;
        max-width: 95%;
        .action {
          > * {
            background-color: $gallery;
            margin-right: 1px;
            padding: 5px 10px;
          }
        }
    }
    .prescriber-section{
        display: flex;
    }
    .header{
        position: relative;
        h1 {
            text-align: left;
            display: inline-block;
        }
        .link-router {
            position: absolute;
            right: 0;
            color: $fliptRed;
            span {
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }
    .subhead {
        padding: 10px;
        background: #ececed;
        margin-bottom:20px;
    }
    .errMessage{
        color: red;
        font-size: 10px;
        font-weight: bold;
    }
    .prescriber-input{
        margin-right: 20px;
        flex-grow: 1;
        max-width: 25%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        span{
            display: inline-block;
            margin-bottom: 15px;
            font-size: 11px;
        }
        input{
            border: 1px solid rgba(34,36,38,.15);
            width:100%;
        }
    }
    .prescriber-btn{
        display: flex;
        justify-content:flex-end;
        align-items:flex-end;
    }

    .buttonContainer{
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 50px;

      input[type="button"] {
        margin: 10px;
      }
    }
}
.textCenter {
    text-align: center;
}
.contentModal {
    max-width: 50%;
    align-items: center;
    margin: 0 auto;
}
.mc_condition{
    height: 350px;
}

.content_acc {
    margin-top: 10px;
    overflow: auto;
    // height: 350px;
    max-width: 95%;
}

.phaseWrapper {
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fl_right__icon {
    float: right !important;
}