@import '../../../styles/colors.scss';

#banner {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height: 134px;
    padding: 32px;
    width: 97%;
    background-color: $v2-white ;
    z-index: 100;
    font-family: "Inter","Nunito","Avenir-Medium";
    position: relative;

    > .row {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
    
    > .row > .col-md-10 > .tool-menu-tip {
        color: #E63324;
        font-size: 14px;
        font-weight: 700;
        vertical-align: top;
        text-align: left;
        height: auto;
        line-height: 20px;
        margin-bottom: 12px;
        
    }

    > .row > .col-md-10 > .tool-menu-title {
        color: #101828;
        font-size: 30px;
        font-weight: 700;
        vertical-align: top;
        text-align: left;
        height: auto;
        line-height: 38px;
        margin-top: 18px;
    }

    > .row > .col-md-2 {
        justify-self: flex-end;
    }
}
.help-tool-tip-container {
    display: flex;
    width: 154px;
    height: 34px;
    border-radius: 16px;
    border: 1px solid var(--Gray-200, #eaecf0);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    flex-direction: column;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-around;
}

    .help-tool-tip {
        font-size: 12px;
        font-weight: 500;
        color: $v2-gray-700;
    }

    .help-tool-tip-link {
        font-weight: 700;
        cursor: pointer;
        text-decoration: underline;
        color: #1D2939
    }
