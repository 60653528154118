@import '../../../../../styles/colors.scss';

#claimTransactionMedicarePartDDetails {
  padding: 20px;
  text-align: left;

  .gridcontent {
    display: block;
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 150px;
    max-width: 75%;
    max-height: 150px;
  }

  >.header {
    align-items: baseline;
    display: flex;

    >h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }

    >div {
      display: inline-block;
      padding: 0;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  .section {
    h2 {
      background-color: $grey-800;
      color: $white;
      display: inline-block;
      font-size: 16px;
      padding: 5px 20px;
    }
  }

  >.content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;

    .section {
      h2 {
        background-color: $grey-800;
        color: $white;
        display: inline-block;
        font-size: 16px;
        padding: 5px 20px;
      }

      border-bottom: 2px solid $grey-600;
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .outer-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;

      .fields-container {
        width: 100%;

        >div {
          margin-bottom: 10px;
          padding-left: 10px;
        }
      }
    }

    .fields-container-grid {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 10px;

      &.grid {
        justify-content: start;
        margin-bottom: 10px;

        .fields {
          &:first-child {
            width: 25%;

            >* {
              padding: 8px;
            }
          }

          .title {
            padding: 8px;
            margin-bottom: 0;
          }

          .spacer {
            background-color: $white;
          }

          >* {
            margin-right: 10px;
          }

          width: 16%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }

      }


      .fields {
        width: 33%;

        >div {
          padding-left: 10px;
          background-color: $grey-400;

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }
  }
}