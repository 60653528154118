#global-edits-configuration {
    padding: 10px 25px 50px 25px;

    .dashboard-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    }

    .dashboard-grid {
        height: 400px;
        text-align: left;
    }

    .dashboard-button-container {
        display: flex;
        gap: 15px;
    }

    .double-column-container {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-gap: 20px;
    }

    #emergency-override-dashboard {
        border: 1px solid rgba(234, 236, 240, 1);
        border-radius: 10px;
        padding-bottom: 65px;
        .eo-table {
            border: 1px solid rgba(29, 41, 57, 1);
            border-radius: 30px;
            margin-top: 0px !important;
        }

        .eo-table-header {
            padding: 10px;
        }

        .eo-table-header-cell {
            font-weight: 700;
            color: rgba(102, 112, 133, 1);
            padding: 10px 10px 10px 15px;
        }

        .eo-cell-wrapper {
            display: flex;
            flex-direction: row;;
            justify-content: space-between;
        }

        .eo-table-row {
            color: rgba(102, 112, 133, 1);
            &:hover {
                color: black;
            }
        }

        .eo-table-cell {
            font-family: "Nunito", "Avenir-Medium";
            font-weight: 600;
            padding: 25px 10px 25px 15px;
            
            &.clickable {
                color: rgba(29, 78, 216, 1);
                transition: all .2s ease-in-out;

                &:hover {
                    color: blue;
                    cursor: pointer;
                }
            }

            &.wrap {
                max-width: 100px;
            }
        }
    }

    .header-button-container {
        display: flex;
        margin: 15px 0px -15px -15px;
    }

    .page-container {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 25px;
    }

    .page-h1 {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 24px;
        margin: 20px 0 20px 0;
    }

    .page-h2 {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 18px;
        margin: 10px 0 20px 0;
    }

    .page-h3 {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 16px;
        margin: 10px 0 20px 0;
    }

    .input-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .tooltip-input {
        border-radius: 5px;
        border-color: #F0F0F0;
        border-style: solid;
        border-width: .05px;
        width: 100%;

        &.dropdown {
            padding:10px;
            height: 56px;
        }

        &.chuncky {
            height: 56px;
        }

        &.padded {
            padding: 10px;
        }
    }

    .tooltip-info-container {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 14px;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .tooltip-input-wrapper {
        width: calc(25% - 20px);
        max-width: calc(25% - 20px);
        &-row {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 20vw;
            height: 45px;
        }
    }

    .tooltip-icon {
        color: grey;
    }

    .radio-selection-wrapper {
        // width: calc(25% - 20px);
        // max-width: calc(25% - 20px);
    }

    .radio-header-container {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .radio-header-label {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 14px;
    }

    .radio-header-label-large {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 16px;
    }

    .radio-button-container {
        display: flex;
        gap: 20px;
        flex-direction: row;
    }

    .radio-button-container-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .radio-selection {
        display: flex;
        align-items: end;
        gap: 2px;
    }

    .radio-selection-label {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 500;
        font-size: 14px;
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
    }

    .button-primary {
        border: 1px solid rgba(29, 41, 57, 1);
        color: white;
        background-color: rgba(29, 41, 57, 1);
        transition: all .2s ease-in-out;

        &:hover {
            border: 1px solid rgba(29, 41, 57, 1);
            color: rgba(29, 41, 57, 1);
            background-color: white;
        }
    }

    .button-primary-inverted {
        border: 1px solid rgba(29, 41, 57, 1);
        color: rgba(29, 41, 57, 1);
        background-color: white;
        box-shadow: none;
        transition: all .2s ease-in-out;
        &:hover {
            color: white;
            background-color: rgba(29, 41, 57, 1);
        }
        &.disabled {
            border: 1px solid rgba(29, 41, 57, .5);
            color: rgba(29, 41, 57, 1);
            background-color: white;
            text-decoration: none;
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    .button-secondary {
        color: rgba(29, 41, 57, 1);
        background-color: white;
        box-shadow: none;
        border: 1px solid white;
        transition: all .2s ease-in-out;

        &:hover {
            border: 1px solid rgba(29, 41, 57, 1);
        }
    }

    .button-icon {
        color: inherit;
        background-color: inherit;
    }

    .button-icon-secondary {
        color: inherit;
        background-color: inherit;
    }

    .select-text-box-wrapper {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        flex-direction: column;
    }

    .select-checkbox-text,
    .select-checkbox-label {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 500;
        font-size: 14px;
        text-align: left;
    }

    .select-text-area-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .select-text-area {
        border-color: rgba(29, 41, 57, .5);
        border-radius: 5px;
        min-height: 150px;
        width: 450px;
    }

    .select-text-area-label {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 500;
        font-size: 14px;
        text-align: left;
    }

    .select-multi-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 40px;
        align-items: center;
        min-height: 59px;
    }


    .select-multi-container {
        display: flex;
        gap: 20px;
        width: calc(75% - 20px);
    }

    .select-input-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: calc(25% - 20px);
        max-width: calc(25% - 20px);
    }

    .select-input-label {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 14px;
        text-align: left;
    }

    .stacked-container {
        border-radius: 15px;
        box-shadow: 0px 2px 4px rgba(0, 0, 12, 0.1);
        margin-bottom: 20px;

        >div:first-child {
            border-top: 1px solid rgba(29, 41, 57, .05);
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }

        >div:last-child {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }

    .stacked-draggable-icon {
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
    }

    .stacked-row {
            display: grid;
            grid-template-columns: 5% 5% 5% 79.7% 5%;
            gap: 1px;
            background-color: rgba(234, 236, 240, 1);
            border: 1px solid rgba(234, 236, 240, 1);
            &.animate-up {
                animation: moveUp 0.25s ease-in-out;
            }

            &.animate-down {
                animation: moveDown 0.25s ease-in-out;
            }

            &.top-border-radius {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &.bottom-border-radius {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &:active {
                cursor: grabbing;
            }

        >section {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5) {
                padding: 15px 10px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &:nth-child(1) {
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
            }
            &:nth-child(5) {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
            }
        }

        >section:nth-child(4) {
            display: flex;
            padding: 15px 10px;
            background-color: white;
            align-items: center;
            min-height: 50px;
            font-weight: 600;
        }

        >section {
            >.stacked-input {
                text-align: center;
                border: 1px solid rgba(29, 41, 57, .1);
                border-radius: 5px;
                width: 100%;
                &:focus {
                    outline: none;
                    border: 1px solid inherit;
                }
            }

            >input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            >input[type="number"] {
                -moz-appearance: textfield;
            }
        }

        >section:nth-child(4) {
            flex: 0 0 72%;
            justify-content: start;
        }
    }

    #CustomCostShare {
        .grid-container {
            height: 400px;
            overflow-y: auto;
            padding: 10px 15px;
        }
        .grid-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
        }
    }

    .stacked-label {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 16px;
        text-align: left;
        margin-bottom: 5px;
    }

    .stacked-button {
        margin: 0px;
        padding: 0px;
        background-color: white;
        cursor: pointer;
        &:hover {
            color: red;
        }
    }

    .multiselect-tt-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .multiselect-tt-dropdown-container {
        width: 20vw;
        max-width: 100%;
    }

    .multiselect-tt-dropdown {
        border-color: #F0F0F0;
        height: 46px;
    }

    .multiselect-tt-badge-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
        margin-top: 10px;
        max-height: 230px;
        overflow-y: auto;
    }

    .network-pricing-configuration-container {
        height: 400px;
    }

    .program-list-container {
        width: 20vw;
        max-width: 100%;
    }

    .program-list-badge-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
        margin-top: 10px;
        max-height: 230px;
        overflow-y: auto;
    }

    .bypass-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 75vw;
    }

    .program-list-dropdown,
    .bypass-code-dropdown {
        border-color: #F0F0F0;
        height: 46px;
    }

    .bypass-input {
        border-radius: 5px;
        border-color: #F0F0F0;
        border-style: solid;
        border-width: .05px;
        height: 46px;
        width: 100%;
    }

    @media only screen and (max-width: 1100px) {
        .bypass-container,
        .program-list-dropdown,
        .select-text-area,
        .select-multi-container,
        .tooltip-input-wrapper-row {
            width: 100%;
        }

        .select-input-wrapper {
            width: 100%;
            max-width: 100%;
        }

    }

    @keyframes moveUp {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
        100% {
          transform: translateY(0);
        }
      }

    @keyframes moveDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(10px);
        }
        100% {
            transform: translateY(0);
        }
    }
}