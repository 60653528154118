@import "../../../../styles/colors.scss";

#createTier {
  padding: 20px 20px 20px 30px;
  text-align: left;

  >.content {
    margin: 10px;
    padding-right: 10px;

    .section {
      .pa-condition-levels-accordion {
        width: 100%
      }

      .tier-inputs-container {
        margin-bottom: 20px;

        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

        >.tier-inputs {
          display: flex;
          flex-flow: column;
          margin-right: 1.5em;
          flex-grow: 1;
          margin-bottom: 15px;

          span {
            color: #636363;
            font-size: 0.9em;
            font-weight: bold;
            margin-bottom: 5px;
          }

          input {
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
          }

          .create-tier-start-date {
            width: 100%;
            height: 100%;
            text-decoration: none;
          }

          .field {
            height: 100%;
          }
        }
      }

      .grid-container {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        height: auto;
        overflow: auto;
        margin-bottom: 40px;

        >.addRowButtonContainer {
          margin-top: 15px;
          max-width: 90%;

          >* {
            margin-left: 50%;
          }
        }

        .ag-theme-alpine {
          height: 300px;
        }

        .tabular {
          .item {
            //width: 50%;
            width: 14em;
            background: #DADADB;
          }

          .active.item {
            background: none;
          }
        }
      }

      .buttonContainer {
        >* {
          margin-right: 40px;
        }

        padding: 30px 0px 40px 0px;
      }
    }

    .section-drugs {
      min-height: 600px;

      .section-contents-drugs {
        .filtersContainer {
          >* {
            margin-right: 10px;
          }
        }

        .filtersGrid {
          overflow: auto;
          max-height: 300px;
        }
      }

      .buttonContainer {
        >* {
          margin-right: 40px;
        }

        padding: 15px;
      }
    }
  }
}

.saveTierModal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    >*:first-child {
      margin-right: 10px;
    }
  }
}