@import '../../../../styles/colors.scss';

#planDetails {
  background-color: $gallery;
  padding: 25px;
  margin-bottom: 35px;

  .form-renderer.content .fields-container {

    // GRID CONTAINER
    .content {
      grid-column: span 4 !important;
      margin: 10px;
      overflow: auto;
      padding-right: 10px;
      height: 450px;
      max-width: 84%;
      max-height: 550px;
    }

    .stylized {
      .flipt-label {
        font-size: 14px;
      }

      input {
        font-size: 14px;
        font-weight: 700;
        border: 1px solid #DADADB;
        outline: none;
      }

      textarea,
      div.ui.dropdown {
        border: solid 1px #DADADB;
      }
    }
  }

  header {
    font-size: 1.2em;
    padding-bottom: 20px;
    padding-top: 5px;
    text-align: left;

    span {
      color: $grey-800;
      margin-right: 20px;
      font-weight: bold;
    }

    span:first-child {
      color: $fliptRed;
    }

    span:last-child {
      font-weight: normal;
    }
  }

  .ui.tabular.menu {
    overflow-x: auto;
    overflow-y: hidden;

    .item {
      margin-right: 0.5em;
      width: 280px;
      height: 50px;
      font-size: 20px;
      justify-content: center;
    }
  }


  .footer {
    display: flex;
    justify-content: center;

    .searchButton {
      margin: 1em;
    }

    .nextButton {
      margin: 1em;
      background-color: #666A67;
    }
  }

  .section {
    overflow: auto;
  }

  .action {
    width: 280px;

    .ellipsis-width {
      position: absolute;
      background-color: white;
      border: 1px solid #DADADB;
      border-radius: 5px;
      width: 280px;
      padding: 10px;
      justify-content: center;
      font-size: .92857143rem;
      margin-right: 20px;
      font-weight: bold;
      box-shadow: 0 1px 2px 0 black;
      color: rgba(0, 0, 0, .87);
      height: 200px;
      overflow-y: scroll;
      top: 24%;
      z-index: 20;

      .item {
        z-index: 25;
        vertical-align: middle;
        line-height: 1;
        position: relative;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: 0 0;
        padding: 0.92857143em 1.14285714em;
        text-transform: none;
        color: rgba(0, 0, 0, .87);
        font-weight: 400;
        -webkit-transition: background .1s ease, color .1s ease, -webkit-box-shadow .1s ease;
        transition: background .1s ease, color .1s ease, -webkit-box-shadow .1s ease;
        transition: background .1s ease, box-shadow .1s ease, color .1s ease;
        transition: background .1s ease, box-shadow .1s ease, color .1s ease, -webkit-box-shadow .1s ease;
        border-bottom: 1px solid #666A67;
        transition-duration: 0.4s;
        cursor: pointer;
      }

      .item span:hover {
        color: #FB0505;
        font-weight: 600;
      }
    }
  }
}