@import "../../../../styles/colors.scss";

.stepTherapyCreationManagement {
  padding: 20px;
  text-align: left;

  .content {
    margin: 24px 0px;
    // overflow: auto;
    padding-right: 10px;
  }
}
