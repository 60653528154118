@import '../../../../styles/colors.scss';

#integration-management-dashboard {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px;

    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }

    .date-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;
        align-items: flex-end;
        width: 60%;
    }

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 38px;
        color: #101828;
        text-align: left;
        margin: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .devider {
        height: 1px;
        background-color: #EAECF0;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        align-self: center;
    }

    .chart-outer-container {
        display: flex;
        flex-direction: row;
        margin-top: 60px;
        align-items: center;
        justify-content: space-around;
        margin: 0px 12px 60px 12px;
    }

    .button-container {
        margin-right: 12px;
    }

    .number-text {
        color: #101828;
        font-size: 22px;
        line-height: 30px;
        font-weight: bold;
    }

    .chart-inner-container {
        border: 1px solid #EAECF0;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;
        width: 28%;
    }


    .chart-header {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        color: #101828;
        text-align: left;
        margin: 12px;
    }

    .grid-container {
        height: 500px;
        margin: 12px 20px 60px 20px;
        text-align: left;

        .ag-theme-alpine {
            font-family: $v2-primary-font-family;
            font-size: 14px;
            font-weight: 400; // font-weight is not working
            color: #667085;

            .ag-root-wrapper {
                border: 1px solid #EAECF0;
                border-radius: 8px;
                box-shadow: $v2-sm-box-shadow;
            }

            .ag-row {
                height: 72px;
            }
        }
    }
}