@import "../../../../../../styles/colors.scss";

#batch-input-section {
  text-align: left;

  >.batch-input-header {
    text-align: center;
    margin: 1em 0em;
    display: block;
  }

  >.batch-input-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    flex-direction: column;
  }

  div.stylized {
    display: block;
    margin-left: 1.5em;

    >.flipt-label {
      color: $doveGray;
      font-size: 0.9em;
      font-weight: bold;
      padding-left: 0px;
      width: 100%;
    }

    >input {
      width: 100%;
      border: 0.5px solid $grey-200;
      border-radius: 3px;
    }
  }
}