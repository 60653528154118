@import "../../../../styles/colors.scss";

#batch-details {
    padding: 20px;
    text-align: left;

    .progressbar {
        counter-reset: step;
        display: flex
    }

    .progressbar li {
        list-style: none;
        display: inline-block;
        width: 30.33%;
        position: relative;
        text-align: center;
        cursor: pointer;
    }

    .progressbar li:before {
        content: '•';
        font-size: 15px;
        width: 25px;
        height: 25px;
        line-height: 20px;
        border: 1px solid #ddd;
        border-radius: 100%;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        background-color: #fff;
        border-width: medium;
        z-index: 1; // added after merge into develop
        position: relative; // added after merge into develop
    }

    .progressbar li.completed:before {
        content: '\2713'
    }

    .progressbar li:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #ddd;
        top: 12px;
        left: -50%;
        z-index: 0; // Change -1 to 0 after merge into develop
    }

    .progressbar li:first-child:after {
        content: none;
    }

    .progressbar li.active {
        //color: green;
    }

    .progressbar li.active:after {
        color: green;
    }

    .progressbar li.active:before {
        color: green;
        background-color: #ECFDF3;

    }

    .progressbar li.active.completed+li:after {
        background-color: green;
    }

    div.stylized {
        display: block;
        margin-left: 1.5em;

        >.field {
            padding-left: 0px;
        }

        >.flipt-label {
            color: $doveGray;
            font-size: 0.9em;
            font-weight: bold;
            padding-left: 0px;
            width: 100%;
        }

        >input {
            width: 100%;
            border: 0.5px solid $grey-200;
            border-radius: 3px;
        }
    }

    .header {
        display: flex;
        justify-content: flex-start;
        color: #101828;
        font-weight: bold;
        font-size: 18px;
        line-height: 38px;

        >.batch-input-button {
            margin-left: 1em;
            border-radius: 3px;
            height: 50%;
            align-self: center;
        }
    }

    .green-button {
        background-color: #039855;
        width: 80px;
        border-radius: 6px;
        color: white;
    }

    .red-button {
        background-color: $fliptRed;
        margin-left: 16px;
        width: 80px;
        border-radius: 6px;
        color: white;
    }

    .batch-container {
        margin-top: 40px;
    }

    .batch-header {
        font-size: 14px;
    }

    .service-container {
        display: flex;
        flex-direction: row;

        >div {
            width: 50%;
        }
    }

    .reject-text {
        color: $fliptRed;
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 12px;
    }

    .summary-grid-height {
        height: 250px;
    }

    .claim-grid-height {
        height: 450px;
    }

    .grid-section {
        overflow: auto;
        margin-bottom: 12px;
        margin-left: 1.5em;

        .ag-theme-alpine {
            font-family: $v2-primary-font-family;
            font-size: 14px;
            font-weight: 400; // font-weight is not working
            color: #667085;

            .ag-root-wrapper {
                border: 1px solid #EAECF0;
                border-radius: 8px;
                box-shadow: $v2-sm-box-shadow;
            }

            .ag-row {
                height: 72px;
            }
        }
    }

    .radio-container {
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: space-between;
        margin-top: 12px;
        margin-left: 1.5em;
    }

    .inner-batch-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .radio-text {
        margin-top: 12px;
        margin-left: 8px;
        margin-left: 1.5em;
    }

    .overlay {
        display: flex;
        flex-direction: row;
        padding: 12px;
        border: 1px solid #D0D5DD;
        margin-top: 12px;
        border-radius: 12px;
        width: 60%;
        margin-bottom: 12px;
        background-color: #EAECF0;
        margin-left: 1.5em;

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 5px;

            >div {
                font-size: 16px;
                color: #B4A0E2;
            }

            >span {
                color: $doveGray;
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }

    .checkbox-text {
        color: $doveGray;
        padding: 0em 0.5em 0em 0.5em;
    }

    .file-upload-container {
        display: flex;
        flex-direction: row;
        padding: 12px;
        border: 1px solid #D0D5DD;
        margin-top: 12px;
        border-radius: 12px;
        width: 60%;
        margin-bottom: 12px;
        margin-left: 1.5em;

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 5px;

            >div {
                font-size: 16px;
                color: #6941C6;
            }

            >span {
                font-size: 14px;
                margin-top: 5px;
            }
        }

    }

    .reprocessing-details-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 1em 0em 1em 0em;

        >div.stylized {
            flex-grow: 1;
        }
    }

    .button-header-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        margin-top: 20px;
    }

    .text-button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reprocessing_notes {
        flex-grow: 2;
        padding-right: 1.5em;
        width: 80%;
        height: 150px;
    }

    .action-needed-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid $gallery;
        border-radius: 3px;
        box-shadow: 2px 2px 2px 2px $gallery;
        padding: 1em 0em 1em 1.5em;

        >div>input[type="checkbox"] {
            padding-top: 5px;
            align-self: center;
        }
    }

    .transition-div {
        transition: all 0.5s linear;
    }

    .accordian-container {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        margin-left: 1.5em;
        justify-content: space-between;
        height: 50px;
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(234, 236, 240, 1);
        transition: all 0.5s linear;

        >div {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
        }

        .acc-inner-container {
            display: flex;
            flex-direction: column;
            margin-left: 8px;

            >span {
                font-size: 14px;
                color: rgba(102, 112, 133, 1);
            }

            >p {
                font-size: 14px;
                font-weight: bold;
                color: rgba(29, 41, 57, 1);
                margin-top: 5px;
            }
        }

        >img {
            margin-right: 8px;
        }
    }

    .acc-header {
        font-size: 18px;
        font-weight: bold;
        margin-left: 8px;
    }

    .border-right {
        width: 1px;
        background-color: $v2-gray-300;
        margin-left: 8px;
        height: 80%;
    }

    .restack-icon {
        display: inline-block;
        position: relative;
        width: fit-content;

        .icon {
            cursor: pointer;
        }

        .restack-label {
            display: none;
            text-align: center;
            padding: 1px 2px;
            border-radius: 5px;
            position: absolute;
            width: 300px;
            padding: 4px 4px;
            border: 1px solid dimgrey;
            background-color: dimgrey;
            color: white;
            bottom: 110%;

            &::before {
                position: absolute;
                content: '';
                height: 0;
                width: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid dimgrey;
                transform: rotate(180deg);
                bottom: -8px;
            }
        }

        &:hover {
            .restack-label {
                display: block;
            }
        }
    }

    .row-container {
        display: flex;
        align-items: center;
        caret-color: transparent;
    }

    .checkbox-label {
        color: $v2-gray-900;
        font-weight: bold;
        font-size: 18px;
        padding: 0em 3em 0em 1.2em;
    }

    .queue-claims-container {
        margin-bottom: 2em;
        display: flex;
        justify-content: space-between;

        .queue-claim-button {
            margin-right: 20px;
            margin-left: 20px;
        }
    }

}

.progressbar-container {
    margin-top: 20px;
}

.acc-class {
    margin-top: 60px;
    margin-bottom: 12px;
}

.last-acc {
    margin-bottom: 100px;
}

.devider {
    background: #EAECF0;
    height: 1px;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.button-container {
    margin-top: 20px;
    justify-content: center;
    margin-bottom: 20px;
}

.bulk-reprocessing-restacking-modal {
    top: 10%;
}