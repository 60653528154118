#queueConfig {
    padding: 20px;
    text-align: left;
}

.header {
    font-size: 18px;
    font-weight: 600;
}

.inner-container {
    padding: 20px;
    border-radius: 4px;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.checkbox-text {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    caret-color: transparent;
}

.queue-header {
    font-weight: 600;
    font-size: 16px;
}

.checkbox-container {
    display: flex;
    width: 25%;
}

.fields-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .queue-edit-button {
        margin-left: 20px;
        font-size: 14px;
    }
}