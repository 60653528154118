@import "../../styles/colors.scss";

.ag-popup {
  height: 0 !important;
}

.ag-theme-alpine {
  width: 100%;
  height: 100%;

  >.ag-header-cell-text {
    text-transform: capitalize;
  }

  .ag-root-wrapper {
    overflow: visible;
  }
}

.nestedEditor {
  .editorContainer {
    padding: 2px 5px 5px 5px;
  }
}

.list-outer {
  display: flex;
  flex-direction: column;
}

.list-inner {
  font-size: 14px;
}

.grid-actions {
  text-align: left;
}

.ag-cell-wrap-text {
  word-break: keep-all !important;
}

.gridRowTextCell {
  display: block;
  padding: 0;
  height: 20px;
}

.ag-cell {
  display: flex;
  align-items: center;
}