
@import '../../../../../styles/colors.scss';


#test-batch-claim-form-component {
    text-align: left;
	// height: 200px;
    h2 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    >span {
        font-size: 16px;
        font-weight: 600;
        color: $v2-gray-700;
    }

    .radio-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 18px;

        >div {
            font-size: 14px;
            margin-right: 30px;
        }
    }

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
    }


    .field-outer-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 24px;
        .section-row {
            align-self: stretch;
            max-width: 22.9%;  
            .v2-flipt-dropdown div.ui.selection.dropdown {
                min-width: 40%;
            }    
            margin-bottom: 24px;
        }
        margin-bottom: 24px;
    }

    .grid-container {
        height: 300px;
        margin-top: 20px;
        margin-bottom: 50px;
    }
}
