#flipt-accordion {
    &.accordion-container {
        background-color: white;
        border: 1px solid rgba(234, 236, 240, 1);
        overflow: hidden;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    }

    &.accordion-container {
        border-radius: 0;
    }

    &.accordion-container:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &.accordion-container:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &.accordion-container:only-child {
        border-radius: 8px;
    }


    .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .accordion-header-title {
        font-weight: 600;
        padding: 10px 10px 10px 24px;
    }

    .expanded {
        max-height: 500px;
    }

    .collapsed {
        max-height: 0;
    }

    .accordion-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .accordion-button-container {
        border-left: 1px solid rgba(234, 236, 240, 1);
        padding: 16px 24px 16px 24px;
    }

    .accordion-button {
        background-color: inherit;
        outline: none;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: border-color .3s ease-in, border-width .3s ease-in;

        &:hover {
            border-radius: 8px;
            border: 1px solid #667085;
        }
    }

    .accordion-icon {
        height: 24px;
        width: 24px;
        background-color: inherit;
    }

    .accordion-content {
        transition: max-height 0.7s ease-in-out, opacity 0.7s ease-in-out;
        overflow: hidden;
        border-top: 1px solid rgba(234, 236, 240, 1);
    }
}