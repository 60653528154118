@import '../../../../../styles/colors.scss';

#viewFormularyDrugs {
  padding: 20px;
  text-align: left;

  > .content {
    border-top: 2px solid $grey-200;
    margin-top: 10px;
    overflow: auto;
    height: 700px;
    max-width: 95%;

    .action {
      > * {
        background-color: $gallery;
        margin-right: 1px;
        padding: 5px 10px;
      }
    }
  }

  .header {
    display: flex;
    // padding: 30px;
  }

  h2 {
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .section {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .fields {
      width: 33%;

      > div {
        background-color: $grey-400;
        margin-bottom: 10px;
        padding-left: 10px;

        &:nth-child(2n) {
          background-color: $grey-200;
        }
      }
    }
  }
}
