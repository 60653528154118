@import '../../../../styles/colors.scss';

#DuplicateModal {
    font-size: 18px;
    line-height: 16px;
    color: #000000;

    .button-container {
        display: flex;
        justify-content: flex-end;
    }
}