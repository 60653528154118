@import '../../../../styles/colors.scss';

#applicationOnly {
  display: flex;
  flex-direction: column;

  .flipt-label {
    width: 100%;
  }

  .applicationContainer {
    display: flex;
    flex-direction: row;

    .stylized {
      width: 30%;
      padding: 15px;
    }
  }
  .mailOrderContainer{
    padding: 20px 0;
    .headingContainer{
      padding: 10px 0;
    }
  }

  .app-grid-container {
    height: 300px;
    width: 100%;

    .ag-popup-editor {
      width: 300px;
    }
  }
}