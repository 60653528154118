.member-section {
    .member-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-bottom: 5px;
    }

    .inner-container {
        padding: 20px;
        border-radius: 4px;
        border: solid 1px #bcbabe;
        margin-bottom: 20px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .not-found-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }

    .not-found-header {
        font-weight: 500;
        font-size: 24px;
        color: #101828;
    }

    .not-found-text {
        font-weight: 400;
        font-size: 20px;
        color: #101828;
    }

    .member-buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .primary {
            margin-right: 1rem;
        }
    }

    .grid-container {
        min-height: 200px;
        height: 200px;
        text-align: left;
    }
}