@import '../../../../../styles/colors.scss';

#pa-case-information {
  .case-information-status {
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 10px;
  }

  .case-action-buttons {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  .lock-msg {
    color: red;
    font-size: 14px;
  }
}
