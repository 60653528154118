@import '../../../../styles/colors.scss';

#PlanDetails {
  padding: 20px;
  text-align: left;

  >.content {
    overflow: auto !important;
    margin: 10px 10px 50px 10px;
    padding-right: 10px;

    .header {
      display: flex;
      padding: 30px;
    }


    h2 {
      color: $white;
      font-size: 18px;
      padding: 10px 20px;
      background-color: $grey-800;
      display: inline-block;
    }

    .section {
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .buttons-container {
      display: flex;
      justify-content: center;

      >* {
        margin-right: 10px;
        width: 20%;
      }
    }
  }

  .plan-main-container {
    display: flex;
    flex-direction: column;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  .fields {
    width: 33%;

    >div {
      background-color: $grey-400;
      margin-bottom: 10px;
      padding-left: 10px;

      &:nth-child(2n) {
        background-color: $grey-200;
      }
    }
  }

  .devider {
    background-color: gray;
    width: 100%;
    height: 1px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .phase-header {
    font-size: 16px;
    font-weight: bold;
  }

  .phase-box {
    display: flex;
    flex-direction: row;
    padding: 12px;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  }
}