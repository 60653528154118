@import "../../../../../styles/colors.scss";

#create-drug-list {
  padding: 20px;
  text-align: left;

  .breadCrumbContainer {
    display: flex;
    flex-direction: row;

    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;

      span {
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  >.content {
    margin: 10px;
    padding-right: 10px;

    .section {
      .tier-inputs-container {
        margin-bottom: 20px;

        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

        >.tier-inputs {
          display: flex;
          flex-flow: column;
          margin-right: 1.5em;
          flex-grow: 1;
          margin-bottom: 15px;

          span {
            color: #636363;
            font-size: 0.9em;
            font-weight: bold;
            margin-bottom: 5px;
          }

          input {
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
          }

          .create-tier-start-date {
            width: 100%;
            height: 100%;
          }

          .field {
            height: 100%;
          }
        }
      }

      .button-header-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        margin-top: 20px;
      }

      .checkbox-label {
        color: $v2-gray-900;
        font-weight: bold;
        font-size: 18px;
        padding: 0em 3em 0em 1.2em;
      }

      .export-button {
        background-color: #039855;
        border-radius: 6px;
        color: white;
      }

      .grid-container {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        height: 350px;
      }

      >.addRowButtonContainer {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        margin: 16px 0px;

        >* {
          margin: 5px auto;
        }

        button {
          width: 160px;
        }

        .addRowWarning {
          color: red;
          font-size: 0.8em;
        }
      }

      .buttonContainer {
        >* {
          margin-right: 40px;
        }

        padding: 30px 0px 40px 0px;
      }
    }

    .filtersSection {
      .filterConditionsGrid {
        overflow: auto;
        max-height: 400px;

        .ag-theme-alpine {
          height: 400px;
        }
      }
    }

    .section-drugs {
      min-height: 600px;

      .section-contents-drugs {
        .filtersContainer {
          >* {
            margin-right: 10px;
            overflow: flex;
          }
        }

        .filtersGrid {
          overflow: auto;
          max-height: 300px;

          .ag-theme-alpine {
            height: 300px;
          }
        }

      }

      .buttonContainer {
        >* {
          margin-right: 40px;
        }

        padding: 15px;
      }
    }
  }
}

.saveTierModal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    >*:first-child {
      margin-right: 10px;
    }
  }
}

.banner {
  display: flex;
  flex-direction: row;
}

.copy-object {
  position: absolute;
  top: 22%;
  background-color: white;
  max-width: 60% !important;
  right: 11%;
  box-shadow: 0px 3px 20px !important;
  max-height: 65vh !important;

  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  border: 1px solid #c0c3c8;

  >.tier-inputs {
    display: flex;
    flex-flow: column;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;

    // max-height: 15em !important;
    span {
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 5px;
    }

    input {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
    }

    .create-tier-start-date {
      width: 100%;
      // height: 100%;
    }

    .field {
      height: 100%;
    }
  }

  .buttonContainer {
    >* {
      margin-right: 40px;
    }

    // padding: 1px 0px 1px 0px;
  }
}
