@import "../../../../styles/colors.scss";

#roleManagement {

  > .header {
    display: flex;
    justify-content: flex-start;

    > input[type="button"] {
      display: block;
      color: $fliptRed;
      width: 130px;
    }
  }

  > .content {
    border-top: 2px solid $grey-200;
    margin-top: 10px;
    overflow: auto;
    height: 450px;
    max-width: 95%;
    // max-height: 550px;

    .actions {
      width: 160px;

      > * {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .rStatusClass {
      font-weight: 700;
      color: $fliptRed;
    }
  }
  flex-direction: row;
  padding: 20px;
  text-align: left;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  hr {
    margin-bottom: 50px;
  }
  .section {
    margin-bottom: 40px;
  }

  h2 { 
    align-items: baseline;
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .fields-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    width:90%;
    
    > .fields {
      margin-right: 10px;
      width: 30%;

      .field {
        display: inline-block;
        max-width:300px;
        min-width:125px;
      }

      > div {
        background-color: $whiteSmoke;
        margin-bottom: 10px;
        max-height:35px;


        &:nth-child(2n) {
          background-color: $grey-300;
        }

        .ui.selection.dropdown {
          border: 1px solid $grey-200;
          color:#000000;
          min-width:300px;
        }

      }
      .ui.dropdown>.text {
        color:$black;

      }  
    }
  }
  input {
    &[name="email_address"],
    &[type="email"] {
      text-transform: lowercase !important;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;

    > input {
      display: block;
      margin-right: 10px;
      margin:10px;
      width: 10%;
      padding: 10px 20px;
    }
  }
  
}