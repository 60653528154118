@import "../../../../../styles/colors.scss";

#createTeam {
  padding: 20px;
  text-align: left;

  .header {
    text-align: left;
  }

  > .content {
    margin: 10px;
    padding-right: 10px;

    .section {
      .team-inputs-container {
        margin-bottom: 20px;
        h2 {
          display: flex;
          flex: wrap;
          flex-grow: 1;
          margin-bottom: 15px;
        }
        .team-inputs-wrap {
          display: flex;
          flex-wrap: wrap;
          > .team-inputs {
            display: flex;
            flex-flow: column;
            margin-right: 1.5em;
            flex-grow: 1;
            margin-bottom: 15px;
            span {
              color: #636363;
              font-size: 0.9em;
              font-weight: bold;
              margin-bottom: 5px;
            }
            input {
              border: 1px solid rgba(34, 36, 38, 0.15);
              border-radius: 0.28571429rem;
            }
            .create-team-start-date {
              width: 100%;
              height: 100%;
            }
            .field {
              height: 100%;
            }
          }
        }
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      }
      .grid-container {
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
        margin-top: 30px;

        > .team-roles {
          // outline: 1px solid black;
          overflow: auto;
          max-width: 100%;
          margin-top: 15px;
          padding: 0px 10px 10px 10px;
          .team-roles-accordion {
            width: 100%;
            .team-role {
              input {
                margin-right: 10px;
              }
              .role-features {
                // outline: 1px solid red;
                overflow: auto;
                margin-top: 5px;
                padding: 0px 0px 10px 0px;
              }
            }
          }
        }
      }
    }
    .buttonContainer {
      > * {
        margin-right: 40px;
      }
      padding: 30px 0px 40px 0px;
    }
  }
}

.saveTierModal {
  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .buttons-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    > *:first-child {
      margin-right: 10px;
    }
  }
}

