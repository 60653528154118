@import '../../../../styles/colors.scss';

#createPACase {
    padding: 20px;
    text-align: left;

    >.header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
    }

    .tab-container {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }

    .selected-tab {
        align-items: center;
        display: flex;
        padding: 20px;
        font-weight: 700;
        height: 40px;
        border: 1px solid $grey-300;
        box-shadow: 2px 2px $grey-600;
        caret-color: transparent;
    }

    .unselected-tab {
        align-items: center;
        display: flex;
        padding: 20px;
        background-color: $grey-300;
        height: 45px;
        caret-color: transparent;
    }

    .margin-left {
        margin-left: 10px;
    }

    .buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 40px;

        .cancelButton {
            margin-left: 20px;
            font-size: 14px;
        }
    }

}