@import './colors.scss';

@mixin menuCard {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $gallery;
  height: 250px;
  width: 330px;

  > .menu-item {
    margin-bottom: 5px;
  }

  > img {
    width: 75px;
    margin-bottom: 20px;
  }

  > .title {
    font-size: 18px;
    font-family: 'Nunito','Avenir-Heavy';
    font-weight:700;
    color: #424242;
  }

  > .description {
    font-size: 12px;
    font-family: 'Nunito','Avenir-Medium';
    font-weight:600;
    padding: 10px 20px 20px 20px;
  }

  > .svg {
    height: auto;
  }

  > .svg:hover {
    cursor: pointer;
    filter: invert(.5) sepia(10) saturate(8) hue-rotate(312deg);
  }
}