#EDI834Renderer {
    padding: 20px 10px;
    .edi-title {
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .section-header {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 600;
        font-size: 12px; // do 32px
        margin: 20px 0 20px 0;
    }
    .edi-dropdown {
        // width: 100px;
        // height: 30px;
        // padding: 0px;
    }
}