@import '../../../../../styles/colors.scss';
#memberLookup {
  input[type="button"]{
    max-height: 35px ;
    align-self: flex-end;
  }
  .own-class {
    color: red;
  }
  .fields-header .stylized{
    display: flex;
    justify-content: space-between;
    align-items: left;
    flex-direction: column;
  
      .flipt-label {
        display: flex;
        align-items: center;
        width:100%;
        margin-bottom:5px;
      }
      .item {
        display: flex;
        align-items: left;
      }
      input[name="last_name"]{
          margin-left: -20%;
      }

  }
  span.noDataAvaialable{
    padding: 10px;
    background: #f9f9f9;
    font-size: 13px;
    margin-right: 10px;
  }

  .fields-container { 
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 2px;
    .fields-client-header {
       width: 31.3%;
    }
    .gap {
      margin: 0px 5px;
    }
    .field-label{
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      gap:10px;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
    }
    .field-input{
      width: 100%;
      border-radius: 5px;
    }
    .field-date-picker{
      width: 120%;
      border-radius: 5px;
    }

  }
  .clearBtn {
    background-color: $v2-gray-800;
    border-radius: 5px;
    color: $white;
    margin-top: 20px;
  }
  .searchBtn {
    display: flex;
  }
  .option {
    outline: 1px solid $gallery;
    padding: 3px 8px;
    height: 20px;
    background-color: $white;
    font-size: 12px;
    opacity: 1 !important;
    margin-top: 35px;
  }
  .horizontalLine {
    height: 1px;
    background-color: $v2-gray-200;
    align-self: center;
    width: 100%;
    margin: 0px 12px 12px 0px;
} 

}
