@import "../../../styles/colors.scss";

#formularyExportLookup {
  padding: 20px;
  text-align: left;

  .breadCrumbContainer {
    display: flex;
    flex-direction: row;

    .breadCrumbLogo {
      flex: 1;
      justify-content: right;
      display: flex;
      flex-direction: row-reverse;

      span {
        background-color: red;
        color: #fff;
        padding: 5px;
      }
    }
  }

  >.header {
    display: block;
    justify-content: space-between;
    float: right;

    .searchDrugLookup {
      display: flex;
      flex-flow: row;
      margin-right: 5px;
      margin-bottom: 5px;
      float: inline-end;

      input {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
      }
    }

  }

  .section {

    >.content {
      border-top: 2px solid $grey-200;
      margin-top: 10px;
      overflow: auto;
      height: 700px;
      max-width: 100%;
      // max-height: 550px;

      .actions {
        width: 160px;

        >* {
          display: inline-block;
          margin-right: 10px;
        }
      }

      .rStatusClass {
        font-weight: 700;
        color: $fliptRed;
      }

      .vip-highlight {
        background-color: #34ffc9;
      }
    }

    .drug-lookup-inputs-container {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      flex-flow: row wrap;
      padding: 15px;
      border: 1px solid #c0c3c8;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

      >.drug-lookup-inputs {
        span {
          color: #636363;
          font-size: 0.9em;
          font-weight: bold;
          margin-bottom: 5px;
        }

        input {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 0.28571429rem;
        }



        .drug-lookup-search {
          width: 150px;
          height: 100%;
          margin-right: 10px;
          margin-bottom: 10px;
        }

        .formulary-input {
          width: 100%;
          height: 100%;
        }

      }
    }

    .grid-container {
      border: 1px solid #c0c3c8;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      height: 350px;
    }




  }


  .menu {
    overflow-y: scroll;
  }


  >.subhead {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div,
    >input {
      display: block;
      margin-right: 10px;
      margin-bottom: 10px;

      &.searchButton {
        width: 130px;
      }
    }

    >.actions-dropdown {
      // outline: 1px solid red;
      display: flex;
      flex-flow: column;
      // width: 10px;
      // max-width: 25%;
      margin-right: 1.5em;
      flex-grow: 1;
      margin-bottom: 15px;

      input {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
      }
    }
  }

  >.content {
    border-top: 2px solid $grey-200;
    margin-top: 10px;
    overflow: auto;
    height: 700px;
    max-width: 95%;
    // max-height: 550px;

    .actions {
      width: 160px;

      >* {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .rStatusClass {
      font-weight: 700;
      color: $fliptRed;
    }

    .vip-highlight {
      background-color: #34ffc9;
    }
  }

  .overlap-value {
    z-index: 10;
  }

  .overlap-index {
    z-index: 1;
  }
}