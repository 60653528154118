@import '../../../styles/colors.scss';

#manualEligibilityEntry{
  padding: 20px;
  text-align: left;
  > .mainHeader{
    h2 {
        color: black;
        display: inline-block;
        font-size: 20px;
        margin: 30px 0px 0px 10px;
        font-weight: bold;
      }
  }

  .header {
    align-items: baseline;
    display: flex;
    margin-left: 20px;

    > h1 {
      border-right: 1px solid $grey-800;
      margin-right: 20px;
      padding-right: 20px;
    }

    > div {
      display: inline-block;
      padding: 0;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
     .cancelButton{
       margin-right: 20px;
        margin-left: 20px;
        font-size: 14px;
    }
    .primary {
      margin-right: 20px;
        margin-left: 20px;
        font-size: 14px;
    }
  }
  > .content {
    margin: 10px;
    overflow: auto;
    padding: 20px;
    border: gray;
    border: 1px solid gray;
    box-shadow: 2px 2px #888888;
    margin-top: 40px;
    margin-bottom: 50px;

    .section {
      border-bottom: 2px solid $grey-600;

      &:last-of-type {
        border-bottom: none;
      }

      .section-contents {
        margin-bottom: 15px;
        margin-top: 15px;
        max-width: 80%;
        padding: 0px 10px 10px 10px;

        div.stylized {
          background-color: $grey-200;
          margin-right: 10px;

          div[name='locations'] {
            z-index: 11;
          }

          &.address1 {
            width: 40%;
            > div {
              width: 'auto';
            }
            > input {
              width: calc(100% - 100px);
            }
          }
        }
      }

      div[name='domain_name'] {
        z-index: 100;

        div.menu {
          max-height: 175px;
          overflow-y: scroll;
        }
      }

      .section-header {
        align-items: baseline;
        display: flex;

        h2 {
          color: black;
          font-weight: bold;
          display: inline-block;
          font-size: 16px;
          padding: 0px 0px 0px 10px;
        }
        h3 {
            color: $grey-600;
            font-weight: bold;
            display: inline-block;
            font-size: 16px;
            padding: 0px 0px 0px 10px;
          }

        input[type='button'] {
          padding-left: 30px;
          padding-right: 30px;
        }

        > div,
        h2 {
          margin-right: 20px;

          > * {
            margin-right: 10px;
            
          }
        }
      }
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 20px;

      .fields-header {
        display: flex;
        > * {
          margin-right: 20px;
        }
      }

      div.phone[role='listbox'] {
        width: 200px;
      }

      .margin-right {
        margin-right: 40px;
      }

      .fields {
        width: 33%;

        &.field-row {
          display: flex;
          width: 100%;
          margin-right: 20px;

          > * {
            margin-right: 10px;
          }
        }

        &.no-bg {
          > * {
            background-color: $white !important;
          }
        }

        > .no-bg {
          background-color: $white !important;
        }

        > div {
          background-color: $grey-400;
          margin-bottom: 10px;
          padding-left: 10px;
          
          &:nth-child(2n) {
            background-color: $grey-200;

          }
        }
      }
    }

  
  }
}