@import '../../../../styles/colors.scss';

#reviewBenefitPlan {
    .content {
        margin-top: 10px;
        text-align: left;
        max-width: 100% !important;
        height: 700px !important;
        max-height: 700px !important;

        .flipt-label {
            display: inline;
        }
        .fliptSeparator {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .header {
            grid-column: span 5 !important;
            .sectionTitle {
                font-weight: 700;
                margin-bottom: 20px;
            }
            
        }

        .display-info-container{
            display: flex;
            flex-direction: column;
          }
    
          .fields-container {
            display: flex;
            flex-direction: row;
      
            > div {
              min-width: 50%;
            }
          }

        .fieldsContainer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            margin-bottom: 10px;
            justify-content: space-around;
            .field {
                margin-bottom: 10px;
                .fieldName {
                    color: #666A67;
                    letter-spacing: 0.15px;
                    font-weight: 600;
                    padding: 5px;
                }
                .fieldValue {
                    color: #000;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 5px;
                }
            }
            #PricingMixmaster {
                grid-column: span 5 !important;
            }
            #BenefitLimitation {
                grid-column: span 5 !important;
            }
            #Grandfathering {
                grid-column: span 5 !important;
            }
            #CustomMessaging {
                grid-column: span 5 !important;
            }
            #transition {
                grid-column: span 5 !important;
            }
            .subTitle {
                font-weight: 700;
                color: #666A67;
            }
            .formulary_copay {
                grid-column: span 5 !important;
            }
            .custom_copay {
                grid-column: span 5 !important;
            }
            .fliptSeparator {
                grid-column-start: 1;
                grid-column-end: 6;
            }
        }
    }
}