@import '../../../styles/colors.scss';

#cardMenuContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: inherit;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;



  > a {
    margin-right: 50px;
    margin-bottom: 50px;
  }
  
  .masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -36px; /* gutter size offset */
    width: auto;
  }
  .masonry-grid_column {
    padding-left: 24px; /* gutter size */
    background-clip: padding-box;
  }
  
  .masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 36px;
  }
}
