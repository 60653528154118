@import "../abstracts/colors";

.agGrid{
  .status_value{
    display: flex;
    height: 26px;
    width: 100%;
    background-color: $v2-success-25;
    border-radius: 18px;
    padding:4px 36px 4px 18px;

    &.published{
      background-color: #E0E0FF;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.approved{
      background-color: #E0FFFF;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.publishInProgress{
      background-color: #FFE0E0;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.publishMarkedForRetry{
      background-color: #FFFFCC;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.forReview{
      background-color: #FFFFE0;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.publishFailed{
      background-color: #FFCCCC;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.publishReady{
      background-color: #CCFFFF;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.draft{
      background-color: #EEEEEE;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.rejected{
      background-color: #FFE0E0;
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.testApproved{
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }
    &.testReady{
      p{
        text-align: center;
        @include text_xs(medium, #333333);
        text-transform: capitalize;
      }
    }

    img{
      height:14px;
      width:14px;
      margin-right: 4px;
      margin-top: 1px;
    }
    
  }
    .ag-theme-alpine {
        text-align: left;
  
        .ag-header-cell {
          padding-left: 7px;
        }
        // .ag-header-cell-resize {
        //   display: none;
        // }
        .ag-row {
          background-color: $white;
          border: 1px solid $v2-gray-200;
        }

        .ag-row-odd{
          background-color: $v2-gray-25;
        }
        .ag-header-cell{
          padding:12px 24px;

        }
        .ag-cell {
          padding: 16px 24px;
          @include text_sm(regular,$v2-gray-500);
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .ag-root-wrapper {
          border: solid 1px $v2-gray-200;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);
          border-radius: 8px;
        }
        .ag-header {
          border-bottom: 1px solid $v2-gray-200;
          background: $v2-gray-50;
  
          .ag-header-cell-text {
            // color: var(--Gray-500, var(--Gray-500, #667085)) $v2-gray-500;
            /* Text xs/Medium */

            @include text_xs(medium,$v2-gray-500);
          }
        }
       
        a.link {
          @include text_sm(medium,#1D4ED8);
        }

        .alignCenter{
          text-align: center;
        }

        .actionsColumns{
          background-color: $v2-gray-50;
        }
      }
}