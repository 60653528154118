@import '../../../../styles/colors.scss';

#closeModalInfo {

  .info-text {
    color: $v2-gray-900;
    font-family: $v2-primary-font-family;
    font-weight: 500;
    line-height: 28px;
  }
   .info-content {
    display: block;
    width: 100%;
    font-size: 1em;
    line-height: 1.4;
    padding: 0.9rem;
   }
   .sub-info-text {
    color: $v2-gray-500;
    font-family: $v2-primary-font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   }
   .agree-text {
    color: $v2-gray-900;
    font-family: $v2-primary-font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
   }
   .gapView{
    margin-top: 15px;
    display: flex;
    gap: 10px;
   }
   .discard {
    width: 100%;
    border-radius: 5px;
   }
   .heading {
    display: flex;
   }
}