@import "../../../../../styles/colors.scss";

#add-group-from-hierarchy {
  display: flex;
  flex-direction: column;

  .devider {
    height: 1px;
    background-color: #eaecf0;
    align-self: center;
    width: 100%;
    margin: 0px 12px 12px 0px;
  }

  .field-outer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 20px 20px 20px;
  }

  .field-inner-container {
    display: flex;
    flex-direction: column;

    >div {
      margin-top: 20px;
      text-align: left;
    }
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    >span {
      font-size: 16px;
      font-weight: bold;
      margin: 20px 0px 20px 12px;
    }
  }

  .inner-header {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    margin-left: 12px;
    margin-bottom: 12px;
  }

  .grid-contents {
    height: 250px;
    z-index: 50;

    &.group-coverage {
      z-index: 70;
    }

    &.plan-details {
      z-index: 60;
    }

    .ag-theme-alpine {
      font-family: $v2-primary-font-family;
      font-size: 14px;
      font-weight: 400; // font-weight is not working
      color: #667085;

      .ag-root-wrapper {
        border: 1px solid #EAECF0;
        border-radius: 8px;
        box-shadow: $v2-sm-box-shadow;
      }

      .ag-row {
        height: 72px;
      }
    }
  }

  .flipt-pa-label {
    margin-bottom: 8px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: inherit;
    padding-bottom: 0;
  }

  input[type="text"] {
    outline: 1px solid #C0C3C8;
  }

  .ui.search.selection.dropdown {
    border: none;
    border-radius: 0;
    font-family: "Nunito", "Avenir-Medium";
    font-weight: 600;
    font-size: 12px;
    outline: 1px solid #C0C3C8;

    input.search {
      outline: 0;
    }
  }
}
