@import "../../../../styles/colors.scss";

#approvalRuleCreationManagement {
  padding: 50px;
  text-align: left;

  > .header {
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 20px 0 20px 0;
    
    > .search {
      flex-grow: 4;
      height:40px;
      text-align: right;
    }

    > div {
      display: inline-block;
      padding: 0;

      a {
        border-right: 1px solid $fliptRed;
        display: inline;
        font-size: 13px;
        margin-right: 10px;
        padding-right: 10px;

        &.active {
          color: $fliptRed;
        }
      }
    }
  }

  .buttonContainer {
    max-width: 85%;
  }

  .content {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 450px;
    max-width: 85%;
    max-height: 550px; 
  }

  .actions {
    width: 160px;
    // display: flex;
    // flex-direction: column;
    > * {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
