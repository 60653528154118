@import '../../../../styles/colors.scss';

#PACaseDasboard {
	padding: 20px;
	text-align: left;
	font-family: $v2-primary-font-family;

	.pa-case-dashboard-section-2 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	.section-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		align-self: stretch;

		.section-row {
			display: flex;
			align-items: flex-start;
			gap: 24px;
			align-self: stretch;
		}
	}

	.section-header-container {
		display: flex;
		align-items: center;
		align-self: stretch;
		padding: 10px 10px 10px 0px;

		.section-header {
			color: $v2-black;
			font-size: 24px;
			font-weight: 500;
			line-height: 20px;
		}

		.verify-edit-buttons-container {
			display: inline-flex;
			margin-left: auto;
			align-items: center;
			gap: 24px;

			.validated-text {
				font-size: 14px;
				color: #21ba45;
				font-weight: 800;
			}
		}
	}

	.divider-tab {
		background: $v2-gray-200;
		height: 1px;
		align-self: stretch;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.not-found-container {
		display: flex;
		align-self: flex-start;
		width: 180px;
		height: 40px;
		background: $v2-gray-200;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
	}

	.not-found-text {
		color: #E63324;
		font-weight: 500;
		font-size: 14px;
	}

	// .main-container {
	// 	display: flex;
	// 	flex-direction: row;
	// 	justify-content: space-between;
	// }

	>.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 700;
	}

	.tab-container {
		display: flex;
		flex-direction: row;
		margin-top: 20px;
		margin-bottom: 20px;
		width: fit-content;
		filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
	}

	.first-tab {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	.last-tab {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.selected-tab {
		border: 1px solid #D0D5DD;
		padding: 10px 16px;
		background: #F9FAFB;
		color: #344054;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 20px;
		font-family: $v2-primary-font-family;
	}

	.cancel-button {
		margin-left: 20px;
	}

	.unselected-tab {
		border: 1px solid #D0D5DD;
		padding: 10px 16px;
		color: #344054;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 20px;
		font-family: $v2-primary-font-family;
	}

	.unselected-tab:hover {
		cursor: pointer;
	}

	// .margin-left {
	// 	margin-left: 5px;
	// }

	// .arrow-container {
	// 	display: flex;
	// 	flex-direction: row;
	// 	align-items: center;
	// }

	// .arrow-circle {
	// 	margin-left: 8px;
	// 	margin-top: 16px;
	// 	caret-color: transparent;
	// }

	// .pa-button-container {
	// 	display: flex;
	// 	padding: 5px;
	// 	align-items: center;
	// 	margin-top: 10px;

	// 	.primary {
	// 		margin-left: 20px;
	// 	}

	// .header {
	// 	font-weight: bold;
	// 	font-size: 18px;
	// 	line-height: 16px;
	// 	color: #000000;
	// 	margin-left: 20px;
	// }
	// }



	// .devider {
	// 	background: #EAECF0;
	// 	height: 1px;
	// 	align-self: center;
	// 	width: 100%;
	// }

	// .devider-margin {
	// 	margin-top: 30px;
	// 	margin-bottom: 30px;
	// }

}
