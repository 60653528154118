#hierarchy-pos-rebate-config {

    .pos-rebate-config {
        transition: max-height 0.7s ease-in-out, opacity 0.7s ease-in-out;
    }

    .pos-container {
        padding: 20px;
    }

    &.page-container {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 25px;
    }

    .input-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .accordion-body {
        height: 500px;
        overflow-y: auto;
        padding: 10px 15px;
    }

    .grid-container {
        height: 400px;
        overflow-y: auto;
        padding: 10px 15px;
        width: 100%;
    }

    .grid-sub-container {
        height: 250px;
        overflow-y: auto;
        max-width: 850px;
    }

    .grid-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }

    .page-h3 {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 700;
        font-size: 16px;
        margin: 20px 0 10px 0;
    }

    .page-h4 {
        display: flex;
        font-family: "Nunito", "Avenir-Medium";
        font-weight: 700;
        font-size: 14px;
        margin: 10px 0 20px 0;
    }

    .split-section {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media screen and (max-width: 1849px) {
            flex-direction: column;
        }
    }
}