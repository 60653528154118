@import "../../../styles/colors.scss";

#globalObjectConfiguration {
  text-align: left;

  >.global-hierarchy-configuration-container {
    .column {
      float: left;
      width: 50%;
      padding-bottom: 200px;

      >.global-object-configuration-inputs {
        .labeled-dropdown-input {
          text-align: left;

          .labeled-dropdown {
            text-align: left;
            align-items: left;
            display: block;
          }
        }

        .labeled-radio-inputs {
          >div {
            margin-right: 20px;
          }
        }

        div {
          padding: 8px 8px 8px 8px;
        }

        .radio-input,
        .radio-label {
          padding: 3px 0px 0px 20px;
          color: black;
        }

        .global-object-selected-objects {
          background-color: $grey-200;

          .row {
            display: flex;
          }

          .column {
            float: left;
            width: 50%;
          }

          label {
            font-size: small;
            display: block;
            flex-direction: column;
            padding: 10px;
          }

          input {
            font-size: small;
            font-weight: bold;
            color: black;
            background-color: transparent;
            outline: none;
            width: 100%;
          }

          >.row:after {
            content: "";
            display: table;
            clear: both;
          }
        }
      }
    }
  }
}