@import '../../../../styles/colors.scss';

#add-group {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 38px;
        color: #101828;
        text-align: left;
        margin: 12px;
    }

    .devider {
        height: 1px;
        background-color: #EAECF0;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    }

    .field-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 20px 20px 20px;
    }

    .group-cag-filters {
        padding-left: 20px;
        padding-right: 50px;
    }

    .field-inner-container {
        display: flex;
        flex-direction: column;

        >div {
            margin-top: 20px;
            text-align: left;
        }
    }

    .button-container {
        margin-bottom: 60px;

        .group-button {
            margin-left: 40px;
        }
    }

    .margin-bottom {
        margin-bottom: 50px;
    }

    .input-class-med {
        width: 350px;
    }

    .inner-header {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        margin-left: 12px;
    }
}