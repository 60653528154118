@import "../../../styles/colors.scss";

#fir {
    padding: 20px;
    text-align: left;

    >.header {
        display: flex;
        justify-content: flex-start;

        >input[type="button"] {
            display: block;
            color: $fliptRed;
            width: 130px;
        }
    }

    .menu {
        overflow-y: scroll;
    }

    >.subhead {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        >div,
        >input {
            display: block;
            margin-right: 10px;
            margin-bottom: 10px;

            &.searchButton {
                width: 130px;
            }
        }
    }

    >.content {
        border-top: 2px solid $grey-200;
        margin-top: 10px;
        overflow: auto;
        height: 700px;
        max-width: 95%;
        margin-bottom: 50px;
        // max-height: 550px;

        .actions {
            width: 160px;

            >* {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }

    .overlap-index {
        z-index: 1;
    }
}