@import "../../../styles/colors.scss";
// DROPDOWN

.v2-flipt-datePicker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    gap: 6px;

    .flipt-label {
        font-family: $v2-primary-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $v2-gray-700;
    }

    div.ui.input {
        >input {
            border-radius: 8px;
            border: 1px solid $v2-gray-300;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            color: $v2-gray-500;
            font-size: 16px;
            line-height: 24px;
            padding: 10px 14px;
            width: 100%;

            .text {
                font-weight: normal;
            }
        }

    }
}