@import '../../../styles/colors.scss', '../../../styles/themes.scss';

#loginPage {
  .left {
    display: none;
  }
}

.left {
  align-items: center;
  background-color: var(--theme-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Nunito','Avenir-Medium';
  font-weight:600;
  > * {
    margin-bottom: 10px;
  }
}

#loginForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 50%;

  a, input {
    font-size: 1em;
    width: 100%;
  }

  input[type="button"] {
    width: 100%;
  }

  input[name="password"] {
    text-transform: lowercase;
  }

  > * {
    display: block;
    margin-bottom: 15px;
  }
  
}

.version {
  font-size: 12px;
}

.right .header-text {
  font-size: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 600px) {
  #loginPage {
    .left {
      display: flex;
    }
  }
}