@import "../../../../../styles/colors.scss";

#manual-input-section {
  padding: 1em 2em;

  >.manual-input-header {
    text-align: center;
    margin: 1em 0em;
    display: block;
  }

  >.manual-input-container {
    text-align: center;

    textarea {
      width: 100%;
      height: 8em;
      margin: 0em auto 1em auto;
      padding: 1em;
    }
  }

  div.stylized {
    text-align: left;
    display: block;

    >.flipt-label {
      color: $doveGray;
      font-size: 0.9em;
      font-weight: bold;
      padding-left: 0px;
      width: 100%;
    }

    >.field {
      padding-left: 0px;
    }
  }

  >.manual-input-dates {
    display: flex;
    justify-content: space-evenly;

    >.search-button {
      margin: 2.5em 0em 0em 1.5em;
      border-radius: 3px;
      height: 50%;
    }
  }
}