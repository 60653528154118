@import "../../../styles/colors.scss";

#approvals-approval-modal {

  .content {
    text-align: center;

    h3 {
      font-weight: 700;

    }

    span.line {
      display: block;
      width: 80%;
      height: 2px;
      margin: 20px auto;
      background-color: $grey-200;
    }

    .subheading {
      font-weight: 600;

      span {
        text-decoration: underline;
      }
    }

    span {
      color: $fliptRed;
    }

    .flipt-text-area {
      width: 80%;
      margin: 10px auto 20px;
      padding: 10px;
    }

    .button-container {
      display: flex;
      gap: 20px;

      >button {
        background-color: $fliptRed;
        color: $white;
      }
    }


  }
}
