@import '../../../../styles/colors.scss', '../../../../styles/themes.scss';

#claim-traceability {
    overflow: auto;
    padding: 20px;
    text-align: left;

    >.header {
        display: flex;
        align-items: baseline;

        >h1 {
            padding-right: 20px;
            border-right: 1px solid $grey-600;
            margin-right: 20px;
        }

        >div {
            display: inline-block;
            padding: 0;

            a {
                display: inline;
                font-size: 13px;
                border-right: 1px solid $fliptRed;
                padding-right: 10px;
                margin-right: 10px;

                &.active {
                    color: $fliptRed;
                }
            }
        }
    }

    .table-container {
        display: flex;
        flex-direction: column;
        border: 1px solid $grey-300;
        box-shadow: 0 0 7px 2px $grey-300;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .row-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $grey-300;
        height: 50px;
        align-items: center;


        .tab-heading {
            padding: 12px;
            display: flex;
            gap: 40px;
            width: 100%;

            >span {
                font-size: 16px;
                font-weight: 600;
                width: 30%;
            }


            .result-container {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                font-weight: 600;
                border-left: 1px solid $grey-300;
                padding-left: 12px;

                .pass {
                    color: green;
                    font-size: 12px;
                }

                .fail {
                    color: red;
                    font-size: 12px;
                }
            }
        }

        .img-container {
            align-items: center;
            border-left: 1px solid $grey-300;
            display: flex;
            padding: 12px
        }
    }

    .param-row-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $grey-300;
        height: 50px;
        align-items: center;


        .tab-heading {
            padding: 12px;
            display: flex;
            gap: 40px;
            width: 100%;

            >span {
                font-size: 16px;
                font-weight: 600;
                width: 40%;
            }


            .result-container {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                font-weight: 600;
                padding-left: 12px;
            }
        }

        .line-div {
            background: rgba(93, 95, 239, 1);
            height: 25px;
            width: 3px;
            border-radius: 5px;
            margin-right: 5px;
        }

        .number-container {
            align-items: center;
            border-right: 1px solid $grey-300;
            display: flex;
            padding: 12px;

            >span {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .img-container {
            align-items: center;
            border-left: 1px solid $grey-300;
            display: flex;
            padding: 12px
        }
    }
}