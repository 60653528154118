@import '../../../../styles/colors.scss';

#searchDiagnosis {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;


    .void-header {
        font-weight: 500;
        font-size: 24px;
        color: #101828;
        align-self: center;

    }

    .input-class-med {
        width: 450px;
    }

    .diagnosis-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-left: 20px;
    }

    .grid-container {
        height: 200px;
        text-align: left;
        margin: 20px;
    }

    .content {
        width: 85%;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
        margin: 8px;
        padding: 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .or-text {
        font-size: 16px;
        font-weight: bold;
        color: #666a67;
        caret-color: transparent;
    }

    .outer-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: center;
    }

    .inner-content {
        display: flex;
        flex-direction: column;
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .input-content {
        display: flex;
        margin-top: 12px;
    }

    .fields {
        margin-top: 12px;
        margin-left: 12px;
    }

}