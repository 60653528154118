@import "../../../styles/colors.scss";

#durSummary {
  .header-actions {
    display: flex;
    padding: 5px 0px;

    .header-bulkactions {
      .closeButton {
        background-color: red;
        color: #fff;
        z-index: 10;
      }

      display: flex;
      padding: 0px;

      .stylized {
        padding: 0 8px 0 0;
        width: 250px;

        .flipt-label {
          padding: 0;
          visibility: hidden;
          display: none;
        }

        .dropdown {
          flex-grow: 1;
        }
      }
    }
  }



  .heading {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;

    .header {
      display: flex;
      width: 100%;

      .closeButton {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        
      }
      .closeButton input {
          background-color: #1D2939;
          border-radius: 8px;
          color: white;
      }
    }
  }

  .content.maxHeight {
    height: 570px
  }

  .maxHeight {
    height: 500px;
    padding: 10px;
  }
}

#pharmacy-alert-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  >div {
    margin: 12px;
  }

  >span {
    margin-top: 8px;
    font-size: 14px;
    margin-left: 8px;
  }

  .info-text {
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
  }

  .grid-container {
    height: 300px;
    width: 100%
  }

  .button-class {
    margin: 8px;
    border-radius: 8px;
    width: 90%;
    background-color: #101828;
    color: white;

    &[disabled] {
      background-color: $grey-200;
      color: $grey-600;
      cursor: not-allowed;
      font-weight: 300;
    }
  }
}