#benefit-dur {
    display: flex;
    gap: 16px;

    >div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 50%;
    }
}
