@import '../../../../styles/colors.scss';

#medD-formulary-builder {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: left;

    .header {
        display: flex;
        font-weight: bold;
        font-size: 20px;
        line-height: 38px;
        color: #101828;
        margin: 12px;
        display: flex;
        justify-content: space-between;

        // .link-text {
        //     color: $fliptRed;
        //     font-size: 14px;
        // }
    }



    .content {
        margin: 10px;
        padding-right: 10px;

        .section {

            >div {
                display: inline-flex;
                justify-content: start;
                flex-direction: row;
                margin-right: 40px;
                margin-left: 20px;

                .inner-header {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 38px;
                    color: #101828;
                    padding-bottom: 10px;
                }
            }

            >div.row {
                display: flex;
                justify-content: left;
                padding-bottom: 20px;

                >div {
                    padding-right: 20px;
                }

                &.grid-container {
                    height: 450px;
                    display: flex;
                    justify-content: start;
                    flex-direction: column;

                    >div {
                        padding-bottom: 15px;
                    }
                }
            }

            .checkbox-outer-container {
                vertical-align: top;
            }

            .checkbox-inner-container {
                padding-right: 50px;
                display: inline-block;

                .checkbox-header {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 38px;
                    color: #101828;
                    padding-bottom: 10px;
                }

                .checkbox-container {
                    width: 100%;
                }

                label {
                    font-weight: normal;
                    padding-left: 15px;
                }
            }

        }
    }

}