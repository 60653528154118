@import '../../../../styles/colors.scss';

#accountTable {
  box-sizing: border-box;
  width: calc(100% - 50px);
  margin: 25px;
  border: 1px solid $grey-400;
  box-shadow: 2px 2px 10px $grey-800;
  margin-bottom: 3em;

  .table tbody > tr > td {
    border-bottom: none;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .table tbody > tr > td:hover {
    background-color: #f69792;
  }

  .table tbody > tr:hover {
    background-color: #ffcccb;
  }

  .ui.celled.table tr td:first-child {
    padding-left: 1.5em;
  }

  .ui.table thead tr:first-child > th:first-child {
    padding-left: 1.5em;
  }

  .ui.table thead tr > th {
    color: $grey-800;
  }
}
