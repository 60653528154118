.add-queue-section {
    justify-content: center;
    display: flex;

    .add-queue-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-bottom: 5px;
    }

    .content {
        width: 55%;
        margin: 8px;
        padding: 8px;
    }

    .inner-container {
        padding: 20px;
        box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
    }

    .grid-content {
        margin-top: 20px;
        height: 180px;
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 400px
    }

    .margin-top {
        margin-top: 16px;
    }

    .buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}