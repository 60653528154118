@import "../../../../styles/colors.scss";

#formularyLookup {
  padding: 20px;
  text-align: left;
  overflow: auto;
  min-height: 80vh;
  .react-datepicker__year-wrapper {
    width:210px;
    max-width: none;
    .react-datepicker__year-text{
      padding: 5px;
    }
  }

  > .content {
    margin: 0px 10px 10px 10px;
    overflow: auto;
    padding-right: 10px;
    height: 450px;

    table thead tr:first-child > th:first-child {
      max-width: 10px;
      visibility: hidden;
    }

    .action {
      > * {
        background-color: $gallery;
        margin-right: 1px;
        padding: 5px 10px;
      }
    }
  }

  h2 {
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .section {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .fields {
      width: 33%;

      > div {
        background-color: $grey-400;
        margin-bottom: 10px;
        padding-left: 10px;

        &:nth-child(2n) {
          background-color: $grey-200;
        }
      }
    }
  }

  .header {
    display: flex;
    padding: 30px;
  }

  div.buttons-container {
    display: flex;
    justify-content: left !important;
    margin-left: 10px;
    padding: 0px;
  }

  .subhead {
    display: flex;
    align-items: center;
    margin: 0px 0px 15px 30px !important;
    text-align: left;
    flex-direction: column;
    .flex-left {
      width: 100%;

      .formulary-lookup-cag-filters {
        background-color: white;
        padding: 0;
        padding-left: 0;
        margin-left: 0;
        border-radius: 0;
        display: flex;
        margin: 0;
        grid-template-columns: 0;

        :nth-child(1n) {
          margin-right: 5px;
          margin-left: 0;
        }
        
        .flipt-label {
          color: black;
          font-size: 0.8em;
          font-weight: normal;
        }
      }

    }
    .row {
      display: flex;
      flex-direction: row;
      padding: 8px;
      > * {
        margin-right: 10px;
      }
    }
  }

  .hide {
    display: none;
    padding: 15px 15px 15px 0px;
  }

  .ndc-highlight {
    background-color: #ffdab9;
  }

  .info:hover + .hide {
    color: $fliptRed;
    display: block;
  }

  .info {
    font-weight: bold;
    color: $smokeBlue;
    padding: 15px 5px 15px 15px;
    margin-right: 1em;
  }

  #pillHolder {
    flex-direction: row;
    align-items: baseline;
    margin-left: 10px;

    > li {
      cursor: pointer;
      font-size: 0.8em;
      background-color: $grey-400;
      padding: 5px 10px;
      border-radius: 10px;
      color: $black;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .subhead .stylized {
    flex-flow: column;
    > div {
      width: 100%;
      margin-bottom: 5px;
    }
    > input {
      min-width: 100px;
    }
  }
}

#formularyModal {
  font-size: 14px;
  margin: 20px;
  padding: 10px;
  text-align: center;

  .drugname {
    font-weight: bold;
  }

  .dosage {
    color: $doveGray;
    font-weight: normal;
  }

  .dkgray {
    background-color: $grey-600;
    margin-top: 15px;
    max-width: 365px;
  }

  .ltgray {
    background-color: $grey-300;
    margin-top: 15px;
    max-width: 365px;
  }

  .supplies {
    margin-left: 16px;
    max-width: 365px;
    text-align: center;
  }

  .supply {
    border-top: 0px;
    margin-top: 15px;
    max-width: 122px;
  }

  .copays {
    margin-left: -8px;
    padding: 0px;
    text-align: center;
  }

  .copay {
    font-size: 10px;
    margin: 0px;
    max-width: 35px;
    text-align: center;
  }

  .row {
    border: 0px;
  }

  .ui.four.column.grid .drugcol {
    margin-bottom: 50px;
    min-width: 375px;
    text-align: center;
  }

  .ui.three.column.grid .supply {
    border: 1px solid $grey-200;
  }

  .formulary_properties {
    font-size: 14px;
    font-weight: normal;
    margin-top: 30px;
    text-align: left;
    width: 300px;

    h3 {
      font-weight: bold;
      margin-top: 10px;
    }
  }

  table {
    padding: 0px 25px 0px 25px;

    .table-headers {
      background-color: $frenchGray;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      margin-left: 50px;
      padding: 5px !important;
    }
  }
}

.selectionEffect .drugName {
  cursor: pointer;
}
