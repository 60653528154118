@import '../../styles/colors.scss';

.form-renderer.content {
  background-color: $white;
  overflow: visible;

  .ui.attached.tabular.menu {
    background-color: $gallery;

    & > a:not(.active) {
      background-color: $grey-300;
    }
  }

  input, textarea, div.ui.dropdown {
    border: solid 1px $doveGray;
    border-radius: 0.285714rem;
    box-sizing: border-box;
    min-height: 45px;
    padding: 10px;
    width: 100%;
  }

  div.ui.multiple.search.dropdown > input.search {
    margin: -1px;
  }

  div.ui.icon.input {
    width: 100%;
  }

  .button-container {
    padding-bottom: 10px;
    display: flex;
    justify-content: center;

    .primary {
      margin-right: 10px;
      width: 150px;
    }
  }

  .section {
    padding: 20px 15px 100px 15px;
    border: 1px solid $grey-400;
    border-top: none;
    box-shadow: 2px 2px 10px $grey-800;

    div.stylized {
      display: block;
      margin-right: 15px;

      & > div:not(div.ui.dropdown) {
        padding: 0;
      }
    }

    .section-header {
      align-items: baseline;
      display: flex;

      h2 {
        background-color: $grey-800;
        color: $white;
        display: inline-block;
        font-size: 18px;
        padding: 10px 20px;
      }

      input[type='button'] {
        padding-left: 30px;
        padding-right: 30px;
      }

      > div,
      h2 {
        margin-right: 20px;

        > * {
          margin-right: 10px;
          
        }
      }
    }
  }

  .fields-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 20px;
    margin-top: 10px;
    text-align: left;

    & > div {
      grid-column: span 1;

      & > * {
        width: 100%;
      }
    }

    & > .flipt-textarea {
      grid-column: span 2;

      textarea {
        height: 43px;
        resize: vertical;
      }
    }

    .tabContent {
      grid-column: span 4;
    }

    //SEPARATOR
    .fliptSeparator {
      grid-column-start: 1;
      grid-column-end: 5;
      
      hr {
        border: 1px solid #DADADB;
      }
    }

    .flipt-label {
      color: $doveGray;
      font-size: 0.7em;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .horizontal-fields {
      width: 100%;
      display: flex;

      .emails,
      .phone {
        display: flex;
        align-items: center;
      }

      > div {
        min-width: 33%;

        > label {
          min-width: 30%;
        }

        > div {
          width: 40%;
        }

        > input {
          flex: 1;
        }
        
      }
      
    }

    .fields-header {
      display: flex;
      > * {
        margin-right: 20px;
      }
    }

    div.phone[role='listbox'] {
      width: 200px;
    }

    .fields {
      width: 33%;

      &.field-row {
        display: flex;
        width: 100%;
        margin-right: 20px;

        > * {
          margin-right: 10px;
        }
      }

      &.no-bg {
        > * {
          background-color: $white !important;
        }
      }

      > .no-bg {
        background-color: $white !important;
      }

      > div {
        background-color: $grey-400;
        margin-bottom: 10px;
        padding-left: 10px;
        
        &:nth-child(2n) {
          background-color: $grey-200;

        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;

    > * {
      margin-right: 20px;
    }
  }
}