@import '../../../../styles/colors.scss';

#file-configuration-dashboard {
    display: flex;
    flex-direction: column;

    .breadcrum-container {
        padding: 20px;
        text-align: left;
    }
    >.main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      padding: 20px;
  
      .create-plan {
        display: flex;
        align-items: center;
        color: $fliptRed;
        // font-size: 70%;
      }
  
      .create-plan:hover {
        cursor: pointer;
      }
    }
    .devider {
      height: 1px;
      background-color: #EAECF0;
      align-self: center;
      width: 100%;
      margin: 0px 12px 12px 0px;
  }
    .content {
      height: 460px;
      padding: 20px;
      text-align: left;
    }
    .content.copy-object {
      display: flex;
      height: fit-content;
      // flex-direction: column;
      overflow-y: auto;
      width: 1000px;
       .main-content{
        width: 100%;
       }
      .copy-header {
        font-size: 1.5em;
        justify-content: center;
        display: flex;
        color: #636363;
        font-weight: bold;
        margin-bottom: 30px;
      }
      .fields-container {
        margin-top: 10px;
        margin-bottom: 15px;
        .fields-header {
          width: 50%;
          margin-right: 20px;
        }
        .field-label{
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          gap:10px;
          text-align: left;
          font-size: 14px;
          font-weight: 700;
          
        }
        .field-label-radio{
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          gap:10px;
          font-size: 14px;
        }
        .divide-half-radio {
          width: 50%;
          text-align: left;
        }
        .danger{
          color:#FB0505
        }
        .field-input{
          width: 100%;
        }
        .half-width{
          align-items: inherit;
        }
        .button-space{
          margin-right:2%;
        }
      }
    }






}