.prescriber-search-v2 {
    .prescriber-header {
        font-size: 18px;
        font-weight: bold;
        color: #666a67;
        text-align: left;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }

    .prescriber-buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .primary {
            margin-right: 1rem;
        }
    }

    .fields-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        >div {

            .description-text {
                font-size: 14px;
            }

            .v2-flipt-input {
                line-height: 12px;
                font-family: 'Nunito';
                width: 280px;
            }
        }
    }

    .grid-container {
        min-height: 200px;
        height: 200px;
        text-align: left;
    }
}