

@import '../../../../styles/colors.scss';

#add-organization{
    display: flex;
    flex-direction: column;

    .container {
          margin-top: 40px;
      }
      .subheader {
        font-size: 15px;
        font-weight: 600;
      }

    .content-progress{
      width: 95%;
      margin-left: 3%;
    }


    .progressbar {
      counter-reset: step;
      display: flex
    }
    .progressbar li {
      list-style: none;
      display: inline-block;
      width: 30.33%;
      position: relative;
      text-align: center;
      cursor: pointer;
    }
    .progressbar li:before {
      content: '•';
      font-size: 15px;
      width: 25px;
      height: 25px;
      line-height: 20px;
      border: 1px solid #ddd;
      border-radius: 100%;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      background-color: #fff;
      border-width: medium;
      z-index: 1; 
      position: relative;
    }

    .progressbar  li.completed:before {
      content: '\2713'
    }
    .progressbar li:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #ddd;
      top: 12px;
      left: -50%;
      z-index: 0; 
    }
    .progressbar li:first-child:after {
      content: none;
    }
    .progressbar li.active {
      //color: green;
    }
    .progressbar li.active:after {
      color: green;
    }
    .progressbar li.active:before {
      color: green;
      background-color: #ECFDF3;
  
    }
    .progressbar li.active.completed + li:after {
      background-color: green;
    }
    .progressbar li.disabled {
      color: #ddd;
    }

    .devider {
        height: 1px;
        background-color: #EAECF0;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    }
   
    
}