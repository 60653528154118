@import "../../../styles/colors.scss";

#flipt-file-upload {
	align-items: center;
	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	display: flex;
	flex-direction: column;
	font-family: $v2-primary-font-family;
	padding: 8px;
	gap: 16px;

	>.uploadInput {
		padding: 16px;
		border: 1px dashed #000000;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		background-color: #f0f0f0;
		gap: 8px;

		input {
			padding: 0px;
		}
	}

	.file-upload-button-container {
		display: flex;
		gap: 24px;
	}
}
