@import "../../../styles/colors.scss";

#wl-breadcrumb {
  font-family: $v2-primary-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $v2-gray-700;

  .breadcrumb {
    display: flex;
    align-items: center;
  }
}
