@import '../../styles/colors.scss';
#memberModal {
  max-height: calc(90vh - 10em);
   padding: 1rem;
   height: 100vh;
    .member-buttons-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .primary {
            margin-right: 1rem;
        }
    }
    .grid-container {
        min-height: 200px;
        height: 200px;
        padding-bottom: 20px;
        text-align: left;
    }
    .member-header{
      font-size: 14px;
      font-weight: 700;
    }
    .fields-container { 
        margin-top: 0px;
        margin-bottom: 20px;
        margin-left: 2px;
        .fields-client-header {
           width: 31.3%;
        }
        .gap {
          margin: 0px 5px;
        }
        .field-label{
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          gap:10px;
          text-align: left;
          font-size: 14px;
          font-weight: 600;
        }
        .field-input{
          width: 100%;
          border-radius: 5px;
        }
        .field-date-picker{
          width: 120%;
          border-radius: 5px;
        }
    
      }
      .filterbtn {
        background-color: $v2-gray-800;
        border-radius: 5px;
        color: $white;
        margin-top: 28px;
      }
      .searchBtn {
        display: flex;
      }
      .option {
        outline: 1px solid $gallery;
        padding: 3px 8px;
        height: 20px;
        background-color: $white;
        font-size: 12px;
        opacity: 1 !important;
        margin-top: 35px;
      }
      .horizontalLine {
        height: 1px;
        background-color: $v2-gray-200;
        align-self: center;
        width: 100%;
        margin: 0px 12px 12px 0px;
    } 
}