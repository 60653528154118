@import '../../../styles/colors.scss', '../../../styles/themes.scss';

#forgotPasswordPage {
  .left {
    display: none;
  }
}

.left {
  align-items: center;
  background-color: var(--theme-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin-bottom: 10px;
  }
}

#forgotPasswordForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 50%;

  input {
    font-size: 1em;
    width: 100%;
  }

  input[type="button"] {
    width: 100%;

  }

  > * {
    display: block;
    margin-bottom: 15px;
  }
}

.right .header-text {
  font-size: 20px;
  margin-bottom: 20px;
}

.right .content-text {
  font-size: 15px;
  margin-bottom: 20px;
}

.modal-header-text {
  font-size: 18px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 200px) {
  #forgotPasswordPage {
    .left {
      display: flex;
    }
  }
}
