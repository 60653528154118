@import "../../../styles/colors.scss";

#bulk-reprocessing-restacking {
  padding: 20px;
  text-align: left;

  div.stylized {
    display: block;
    margin-left: 1.5em;

    >.field {
      padding-left: 0px;
    }

    >.flipt-label {
      color: $doveGray;
      font-size: 0.9em;
      font-weight: bold;
      padding-left: 0px;
      width: 100%;
    }

    >input {
      width: 100%;
      border: 0.5px solid $grey-200;
      border-radius: 3px;
    }
  }

  >.header {
    display: flex;
    justify-content: flex-start;

    >.batch-input-button {
      margin-left: 1em;
      border-radius: 3px;
      height: 50%;
      align-self: center;
    }
  }

  >.search-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // margin: 1em 0em 1em 0em;
    padding: 1em 0em 1.5em 0em;
    border: 1px solid $gallery;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px $gallery;

    >.CAGFilters {
      background-color: $white;
      padding: 0em;
      margin-bottom: 0em;

      >div.stylized {
        margin-left: 1.5em;

        >.flipt-label {
          margin-bottom: 0px;
        }
      }

      :nth-child(1n) {
        margin-left: 0em;
      }
    }

    >.search-button {
      margin: 2.5em 0em 0em 1.5em;
      border-radius: 3px;
      height: 50%;
    }
  }

  >.claim-information-section {
    border-top: 2px solid $grey-200;
    overflow: auto;
    height: 450px;
  }



  >.service-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid $gallery;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px $gallery;
    padding: 1em 1em 1em 1em;

    .checkbox-container {
      display: flex;
      caret-color: transparent;
      flex-direction: row;
    }

    .checkbox-outer-container {
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      margin-top: 12px;
      display: flex;
    }

    .list-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      padding-left: 20px;
    }

    .checkbox-label {
      color: #666a67;
      font-size: 16px;
      font-weight: bold;
      margin-left: 5px;
      align-self: flex-start;
    }
  }


  >.reprocessing-details-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid $gallery;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px $gallery;
    padding: 1em 2em 1em 0em;

    >div.stylized {
      flex-grow: 1;
    }

    >div.reprocessing_notes {
      flex-grow: 2;
      padding-right: 1.5em;
    }
  }

  >.action-needed-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid $gallery;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 2px $gallery;
    padding: 1em 0em 1em 1.5em;

    >div>input[type="checkbox"] {
      padding-top: 5px;
      align-self: center;
    }

    >div>.checkbox-label {
      color: $doveGray;
      font-weight: bold;
      padding: 0em 3em 0em 0.5em;
    }
  }

  .queue-claims-container {
    margin: 2em 0em 2em 0em;
    display: flex;
    justify-content: center;

    .queue-claim-button {
      border-radius: 3px;
      align-self: center;
      font-size: 1.25em;
      padding: 10px 30px;
    }
  }

}

.button-container {
  margin-top: 20px;
  justify-content: center;
}

.bulk-reprocessing-restacking-modal {
  top: 10%;
}