@import "../../../../styles/colors.scss";

#daw_settings {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(11, 1fr);
    margin-top: 10px;
    text-align: left;
    max-width: 100% !important;
    height: 700px !important;
    max-height: 700px !important;

    .flipt-label {
      display: inline;
    }
    .fliptSeparator {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .dawLevel {
      grid-column: span 5 !important;
      .dawLevelTitle {
        font-weight: 700;
        margin-bottom: 20px;
      }
      .dropdowns {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 10px;

        .fliptSeparator {
          grid-column-start: 1;
          grid-column-end: 6;
        }
      }
    }
  }
}
