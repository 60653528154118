@import "../../../styles/colors.scss";

#button-group {
    .bg-button {
        background-color: $v2-gray-700;
        color: $white;
    }

    .dropdown.icon::before {
        color: $white;
    }

    >div {
        >div {
            background-color: $v2-gray-600;

            >div {
                color: $white;
            }
        }
    }
}