@import '../../../styles/colors.scss';

#clientConfiguration {
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: left;

  >.header {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 20px;
    margin-right: 25px;
    margin-bottom: 10px;
  }

  #TableList {
    height: 500px;
    overflow-y: scroll;

    >table#TableData {
      position: relative;

      >thead {
        position: sticky;
        top: 0
      }
    }
  }

  .header-button-container {
    display: flex;
    margin: 15px 0px -15px -15px;
  }

  .goBack {
    cursor: pointer;
    margin-top: 20px;
    width: 20%;
  }

  .button-icon-secondary {
    color: inherit;
    background-color: inherit;
  }

  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

  }

  .horizontalLine {
    height: 1px;
    background-color: $v2-gray-200;
    align-self: center;
    width: 100%;
    margin: 0px 12px 12px 0px;
  }

  .centerLine {
    height: 2px;
    background-color: #EAECF0;
    align-self: center;
    width: 100%;
    margin: 30px 0px;
  }

  .search-row {
    display: flex;
    justify-content: space-between;
    margin-right: 25px;
  }

  .search-input {
    width: 88%;
  }

  .content {
    height: 360px;
    text-align: left;
  }

  .table-cover {
    border: solid 1px !important;
    border-color: $v2-gray-300 !important;
    border-radius: 0px;
  }

  .view-more {
    color: $blue;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .ui.table {
    color: $blue;
    cursor: pointer;
  }

  .draft {
    background-color: $v2-gray-800;
    border-radius: 5px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .gap {
    margin-right: 15px;
  }

  a.link,
  a:hover.link,
  a:focus.link,
  a:active.link {
    color: $blue;
  }

  .selected {
    color: $fliptRed;
    font-weight: bold;
  }

}