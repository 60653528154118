@import "../../../styles/colors.scss";

#eligibility {
  padding: 20px;
  text-align: left;
  .content {
    margin-bottom: 80px;
  }
  .accordian-content {
    height: 220px;
  }
  .price-inputs {
    display: flex;
    flex-flow: column;
    width: 22%;
    max-width: 25%;
    margin-right: 1.5em;
    flex-grow: 1;
    margin-bottom: 15px;
    span {
      color: #636363;
      font-size: 0.7em;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  input {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
  }
  .details-section {
    display: flex;
    margin-bottom: 15px;
    background: #f0f0f0;
    input {
      background: #f0f0f0;
      border: 1px solid #000;
      outline: none;
    }
    .price-inputs {
      background: #f0f0f0;
    }
  }
  .spacing {
    padding: 20px;
  }
  .border-line {
    border: 1px solid #c0c3c8;
  }
  .drug-list-wrap.spacing {
    padding: 0px;
  }
  .drug-list-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;
    .spacing {
      padding: 10px;
    }
    .drug-details {
      width: 40%;
    }
    .pharmacy-list {
      width: 57%;
      overflow: auto;
      padding: 0 5px;
    }
  }
  section {
    margin-bottom: 25px;
  }
  .eligibility-accordion{
      margin-bottom: 40px;
      .content{
        height: 250px;
        max-height: 350px;
      }
  }
}
