@import '../../../../styles/colors.scss';
@import '../../../../styles/menuCard.scss';

#userManagement {
  padding: 20px 20px 75px 20px ;
  text-align: left;

  > .header {
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 20px 0 20px 0;

    > .search {
      flex-grow: 4;
      height:40px;
      text-align: right;
    }
  }
 
  > .buttons {
    padding-top: 10px;
  }

  > .contents {
    margin: 10px;
    overflow: auto;
    padding-right: 10px;
    height: 450px;
    max-width: 85%;
    max-height: 550px;
   }
  
  .actions {
    width: 160px;

    > * {
      display: inline-block;
      margin-right: 10px;
    }
  }
  
  //> .subhead {
  //}

 
}
