@import '../../../../../styles/colors.scss';

#testReadyPharmacyNetworks {
  .section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .flex-container {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      .flex-item {
        width: 31%;
      }
      margin-bottom: 10px;
    }
    

    
    h2 {
      color: black;
      display: inline-block;
      font-size: 20px;
      margin-top: 20px;
      font-weight: bold;
    }

  }

  .plan_title {
    margin-right: 10px;
  }

  .get-networks-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
