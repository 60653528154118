@import '../../../../styles/colors.scss';

#PatientInfo {

    .field-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 280px;
        padding: 0px 20px 20px 20px;
    }

    .field-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .field-notes-container {
        .note-container {
            padding: 20px !important;

            .pa-button-container {
                padding-top: 10px;
            }
        }

    }
}
