@import '../../../../styles/colors.scss';

.menu-card {
  align-items: flex-start;
  align-self: center;
  border-radius: 16px;
  border: 1px solid var(--Gray-200, #eaecf0);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  background-color: $v2-white !important;
  display: flex;
  width: 500px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  min-height: 275px;

  >.menu-card-item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    &.active {
      .menu-item-wrapper {
        display: block;
      }
    }

    .menu-item-wrapper {
      display: none;
    }

    .icon-container-outer {
      // justify-content: center;
      // align-items: center;
      align-self: center;
      aspect-ratio: 1;
      border: 1px solid #D0D5DD;
      background-color: #F9F5FF;
      background-size: contain;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      height: 52px;
      max-width: 100%;
      object-fit: contain;
      object-position: center;
      overflow: hidden;
      position: relative;
      width: 52px;
    }

    .icon-container-inner {
      background-color: #F4EBFF;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }

    .menu-icon {
      height: 50px;
      width: 50px;
    }

    a:hover {
      cursor: pointer;
      //filter: invert(.5) sepia(10) saturate(8) hue-rotate(312deg);
    }

    .title {
      font: 600 20px/150% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
      align-self: stretch;
      color: var(--Gray-900, #101828);
      text-align: center;
      font-style: normal;
      line-height: 150%;
      letter-spacing: -0.4px;
      margin-top: 24px;
      margin-bottom: 24px;
      white-space: wrap;
    }

    .menu-item-row {
      display: grid;
      grid-template-columns: 7fr 1fr;
      grid-gap: 1rem;
      width: 100%;
      margin-top: 16px;

      .sub-menu-item-name {
        word-wrap: break-word;
        overflow-wrap: break-word;
        overflow: hidden;
        width: 100%;
        text-align: left;
        color: var(--Gray-500, #667085);
        font: 400 16px/150% Inter, -apple-system, Roboto, Helvetica, sans-serif;
        font-style: normal;
        margin-top: auto;
        margin-bottom: auto;
        flex-grow: 1;
        white-space: wrap;
      }

      .arrow-container {
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        background-color: var(--Base-White, #FFF);
        align-self: stretch;
        display: flex;
        aspect-ratio: 1;
        padding-left: 8px;
        padding-right: 8px;
        flex-direction: column;
        width: 36px;
        height: 36px;

        .arrow {
          background-Size: contain;
          aspect-Ratio: 1;
          overflow: hidden;
          position: relative;
          display: flex;
          width: 100%;
          flex-direction: column;
        }
      }
    }
  }


  .description {
    font-size: 15px;
    font-family: 'Nunito', 'Avenir-Medium';
    font-weight: 600;
    padding: 10px 20px 20px 20px;
  }
}