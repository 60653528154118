#opioidSummary {
    text-align: left;

    .header-actions {
        display: flex;
        padding: 5px 0px;

        .header-bulkactions {
            .closeButton {
                background-color: red;
                color: #fff;
                z-index: 10;
            }

            display: flex;
            padding: 0px;

            .stylized {
                padding: 0 8px 0 0;
                width: 250px;

                .flipt-label {
                    padding: 0;
                    visibility: hidden;
                    display: none;
                }

                .dropdown {
                    flex-grow: 1;
                }
            }
        }
    }



    .heading {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 10px;

        .header {
            display: flex;
            width: 100%;

            .closeButton {
                flex: 1;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .content.maxHeight {
        height: 570px
    }

    .maxHeight {
        height: 500px;
        padding: 10px;
    }
}