.node {
   &:hover {
       cursor: pointer;
   }
}
.disabled {
    filter:alpha(opacity=50); /* IE */
    opacity: 0.5; /* Safari, Opera */
    -moz-opacity:0.50; /* FireFox */
    color: gray;
    text-decoration: line-through;
}
.disabled_input , .disabled.input{
    filter:alpha(opacity=90); /* IE */
    opacity: 0.9; /* Safari, Opera */
    -moz-opacity:0.90; /* FireFox */
    color: gray;
    text-decoration: none;

}
.react-sortable-tree-container {
    height: auto;
    width: auto;
}