@import "../../../../../styles/colors.scss";

#formularyCreation {
  padding: 20px;
  text-align: left;

  .header {
    display: flex;
  }

  >.content {
    margin: 10px;
    padding-right: 10px;

    .section {
      .formulary-inputs-container {
        // outline: 1px solid red;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border: 1px solid #c0c3c8;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

        >.formulary-inputs {
          // outline: 1px solid red;
          display: flex;
          flex-flow: column;
          // width: 10px;
          // max-width: 25%;
          margin-right: 1.5em;
          flex-grow: 1;
          margin-bottom: 15px;

          span {
            color: #636363;
            font-size: 0.9em;
            font-weight: bold;
            margin-bottom: 5px;
          }

          input {
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: 0.28571429rem;
          }

          .create-formulary-tier-list {
            height: 100%;
            z-index: 2;
          }

          .create-formulary-start-date {
            width: 100%;
            height: 100%;
          }

          .field {
            height: 100%;
          }
        }
      }

      .generateFormularyButtonContainer {
        input {
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: 0.28571429rem;
          font-size: 14px;
          padding-right: 20px;
          padding-left: 20px;
        }
      }

      .section-contents {
        // outline: 1px solid green;
        overflow: auto;
        // min-height: 250px;
        // height: 350px;
        // max-height: 350px;
        // max-width: 95%;
        margin-top: 15px;
        padding: 0px 1px 1px 1px;

        .section-tier-levels-accordion {
          // outline: 1px solid black;
          width: 100%;

          .section-tier-level {

            // outline: 1px solid black;
            .section-tier-conditions {
              // outline: 1px solid blue;
              overflow: auto;
              // min-height: 250px;
              height: 350px;
              // max-height: 30px;
              // max-width: 95%;
              margin-top: 5px;
              // padding: 0px 0px 10px 0px;
            }
          }
        }
      }

      .buttonContainer {
        >* {
          margin-right: 40px;
        }

        padding: 15px;
      }
    }
  }
}
