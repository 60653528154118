@import "../../../styles/colors.scss";

#sureScriptFormularyConfig {
  display: flex;
  flex-direction: column;

  padding: 20px;
  text-align: left;

 
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

  }
  .cag-section {
    padding: 10px;
    background-color: $gallery;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    margin: 0px 0px 15px 0px !important;
    text-align: left;
    z-index: 9;
    > * {
      margin-right: 30px;
    }

    div.stylized {
      display: block;
    }
    div.ui.dropdown {
      border: 1px solid $grey-600;
    }
    input {
      border: 1px solid $grey-600;
      border-radius: 0.28571429rem;
      width: 90%;
    }

    #CAGFilters {
      margin-bottom: 0;
      position: sticky;
    }

    .flipt-label {
      width: 100%;
      color: #636363;
      font-size: 0.9em;
      font-weight: bold;
      margin-bottom: 5px;
      padding-left: 0px;
    }
    input.primary.searchButton {
      width: 40%;
      margin-left: 40px;
      margin-bottom: 10px;
    }
  }
  > .content {
    border-top: 2px solid $grey-200;
    margin-top: 10px;
    overflow: auto;
    height: 500px;
    max-width: 95%;

    .action {
      > * {
        background-color: $gallery;
        margin-right: 1px;
        padding: 5px 10px;
      }
    }
  }

  .sectionComp {
    flex-grow: 1;
    padding: 4px;
  }

  .grid-container {
    height: auto;
  }

  .sectionComp input {
    width: 100%;
  }

  .flex-Cflow {
    flex-flow: column;
  }

  .sectionComp .dropdown {
    width: 100%;
  }

  section {
    display: flex;
  }

  .program-inputs-container {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    border: 1px solid #c0c3c8;
    // box-shadow: 0px 0px 2px 1px rgb(0 0 0 10%);
  }
  .label-T {
    padding: 10px 0px 10px 0px;
    font-size: 0.8em;
  }
  .clearButton {
    font-size: 14px;
    min-width: 100px;
  }

  .selectOption {
    background-color: #e88484;
    border-radius: 4px;
    padding: 4px;
    width: fit-content;
    height: fit-content;
    display: inline-block;
  }

  .selectOption i {
    cursor: pointer;
  }

  .rmFlex {
    display: block !important;
  }
  .optionContainer {
    padding: 8px;
  }

  .button-container {
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .primary {
      margin-right: 10px;
    }
  }

  .displayflex {
    display: flex;
  }

  h2 {
    background-color: $grey-800;
    color: $white;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
  }

  .section {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .fields-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .fields {
      width: 33%;

      > div {
        background-color: $grey-400;
        margin-bottom: 10px;
        padding-left: 10px;

        &:nth-child(2n) {
          background-color: $grey-200;
        }
      }
    }
  }
  #surescriptPopup {
    .popup {
      position: absolute;
      top: 22%;
      background-color: white;
      max-width: 60% !important;
      box-shadow: 0px 3px 20px !important;
      max-height: 65vh !important;
      margin-bottom: 20px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      padding: 25px;
      border: 1px solid #c0c3c8;
      z-index: 10;
      .popupTable {
        overflow: auto;
        height: 300px;
        width: 600px;
        max-width: 95%;
        margin-bottom: 20px;
      }
      .buttonContainer {
        height: 100px;
        width: 600px;
        max-width: 95%;
      }
    }
  }
}
