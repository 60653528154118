#idle-timeout.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1001;
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: auto;

  h4 {
    padding: 10px;
    font-size: 27px;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}