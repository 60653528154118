@import '../../styles/colors.scss';

#CAGFilters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: $gallery;
  padding: 2em 0em 2em 0em;
  margin-bottom: 1em;
  border-radius: 3px;

  :nth-child(1n) {
    margin-left: 1.5em;
  }

  div.stylized {
    display: block;
  }

  .flipt-label {
    color: $doveGray;
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 5px;
    padding-left: 0px;
  }

  .ui.selection.dropdown {
    width: 90%;
  }
  .visible.menu.transition {
    margin: -1px;
  }
}
