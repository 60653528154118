@import '../../../../styles/colors.scss';

#claimDetails {
  padding: 20px;
  text-align: left;
  .reprocessbtn{
    margin-left: 40px;
  }

  > .content {
    overflow: auto;
    margin: 10px;
    padding-right: 10px;

    h2 { 
      align-items: baseline;
      background-color: $grey-800;
      color: $white;
      display: inline-block;
      font-size: 18px;
      padding: 10px 20px;
    }

    .section {
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .fields-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .fields {
        width: 33%;

        > div {
          padding-left: 10px;
          background-color: $grey-400;
          margin-bottom: 10px; 

          &:nth-child(2n) {
            background-color: $grey-200;
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      

      > * {
        margin-right: 10px;
        width: 20%;
      }
      .calcbtn{
        width: 30%;
        margin-bottom: 20px;
      }
    }
  }
  
  .popup-wrap{
    position: fixed;
    height:100vh;
    width:100%;
    overflow: hidden;
    background: rgba(0,0,0,0.5);
    top:0px;
    left:0px;
    .popup-content{
      width:600px;
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      background: #fff;
      padding:20px;
      text-align: center;
      h1{
        font-size: 20px;
        font-weight: 700;
        margin-bottom:20px;
      }
      .data-wrap{
        width: 400px;
        margin:auto;
        .data-holder{
          display: flex;
          justify-content: space-between;
          margin-bottom:10px;
          span{
            font-size:15px;
          }
          .data-title{
            font-weight: 600;
          }
        }
        .data-btn-wrap{
          justify-content: center;
          input{
            margin-left:10px;
            margin-right:10px;
          }
        }
      }
    }
  }
}