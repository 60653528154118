@import "../../../../styles/colors.scss";

.priorAuthorizationManagement {
  padding: 20px;
  text-align: left;


  .content {   
    margin: 24px 0px;
    padding-right: 10px;
  }
}
